/* REM */
/*-------------------------------------------------------------------------------------------------------------------*/
/* GENERAL */
/*-------------------------------------------------------------------------------------------------------------------*/
html {
  font-size: 16px; }

body {
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif; }

a {
  outline: none; }
  a:hover {
    outline: none; }

a, button, input {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.visible_hidden {
  visibility: hidden; }

.cover-image {
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; }

.contain-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain; }

.visibility-hidden {
  visibility: hidden; }

.padding-30-0 {
  padding: 1.875rem 0; }

.padding-30-15 {
  padding: 1.875rem 0.9375rem; }

.padding-70-0 {
  padding: 4.375rem 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif; }

.flexbox-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 31.25rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.flexbox-container-left {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start; }

.flexbox-container-right {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end; }

#content.site-content {
  padding-bottom: 0;
  padding-top: 0; }

.justify-content {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.container {
  max-width: 107.1875rem; }

.container-small {
  max-width: 89.0625rem;
  margin: 0 auto; }

.container-big {
  max-width: 107.1875rem;
  overflow: hidden;
  margin: 0 auto; }

.container.container-full {
  width: 100%;
  max-width: 100%;
  margin: 0 auto; }

/* HEADER */
/*-------------------------------------------------------------------------------------------------------------------*/
.top-of-page {
  background: #33ccff;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 98; }
  .top-of-page .btn_orange_contact_us {
    background-color: #FF9100;
    font-size: 1.125rem;
    font-weight: 900;
    line-height: 1.375rem;
    border-radius: 1.5625rem;
    text-transform: uppercase;
    padding: 0.375rem 1.5625rem;
    color: #ffffff;
    text-decoration: none; }
    .top-of-page .btn_orange_contact_us:hover {
      background-color: #ffffff !important;
      text-decoration: none; }
  .top-of-page ul.top_nav {
    text-align: left;
    margin: 0;
    padding: 0.625rem; }
    .top-of-page ul.top_nav li {
      list-style: none;
      display: inline-block;
      vertical-align: top;
      padding: 0 2.8125rem 0 0;
      font-size: 0.875rem;
      line-height: 1.75rem;
      color: #ffffff !important; }
      .top-of-page ul.top_nav li.text-bold {
        font-weight: bold; }
      .top-of-page ul.top_nav li a {
        font-size: 0.875rem;
        line-height: 1.75rem;
        color: #ffffff !important; }
        .top-of-page ul.top_nav li a:hover {
          color: #0a0b0d !important; }

body #footer .social-network-top {
  text-align: left;
  margin: 0;
  padding: 0.625rem 0; }
  body #footer .social-network-top li {
    list-style: none;
    display: inline-block;
    vertical-align: top;
    padding: 0 1.5625rem 0 0;
    text-align: left; }
    body #footer .social-network-top li a {
      font-size: 1.75rem;
      line-height: 2.25rem;
      color: #ffffff !important; }
      body #footer .social-network-top li a:hover {
        color: #42C8F4 !important; }

header#masthead {
  margin-bottom: 0;
  background-color: #151515;
  max-height: 48rem;
  height: 100%;
  min-height: 48rem;
  overflow: visible;
  position: relative;
  z-index: 9;
  background-position: 50% 50%; }
  header#masthead::before {
    content: "";
    background: url("../img/opa-bg.png") 50% 50% repeat !important;
    background-size: cover !important;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    display: block;
    height: 100%;
    opacity: 0.60; }
  header#masthead.without-bg {
    max-height: 8.4375rem;
    min-height: 8.4375rem;
    z-index: 987; }
    header#masthead.without-bg .hero-part-text {
      display: none !important; }

.home.page-template header#masthead {
  max-height: 56.25rem;
  min-height: 56.25rem; }

.home.page-template .flexbox-container {
  height: 43.75rem; }

.error404 header#masthead {
  background-color: #0d0d0d;
  max-height: 56.25rem;
  min-height: 56.25rem; }

.error404 .flexbox-container {
  height: 43.75rem; }

.hero-part-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0.75rem 0 0 0;
  padding: 0; }
  .hero-part-btns li {
    list-style: none; }

.hero-part-text {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  width: 100%; }
  .hero-part-text h1 {
    color: #ffffff;
    font-size: 3.75rem;
    line-height: 4.375rem;
    text-transform: none;
    display: inline-block;
    vertical-align: top;
    padding: 0 1.25rem;
    margin: 0 0 0.9375rem 0;
    font-weight: 900;
    text-align: center;
    width: 100%; }
  .hero-part-text h2 {
    color: #42c8f4;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0;
    font-weight: bold; }
  .hero-part-text h3.hero_sub2 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    width: 100%;
    max-width: 43.75rem;
    margin: 0 auto; }

.btn-default {
  border-radius: 1.5625rem;
  display: inline-block;
  vertical-align: top;
  margin-right: 0.9375rem;
  padding: 0 1.5625rem;
  background: #1a1a1a;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 2.9375rem;
  min-width: 16.25rem;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  text-align: center; }
  .btn-default.btn-default-small {
    text-transform: none;
    min-width: 14.75rem;
    text-align: center; }
    .btn-default.btn-default-small:hover {
      background-color: #1a1a1a !important;
      color: #ffffff !important;
      text-decoration: none; }
  .btn-default:hover {
    background-color: #ffffff;
    color: #1a1a1a;
    text-decoration: none; }
  .btn-default.skycolor {
    background-color: #42c8f4; }
    .btn-default.skycolor:hover {
      background-color: #ffffff;
      color: #1a1a1a;
      text-decoration: none; }
    .btn-default.skycolor.blackhover:hover {
      text-decoration: none;
      background-color: #151515;
      color: #ffffff; }
  .btn-default.whitecolor {
    background-color: #ffffff;
    color: #5bcdf0; }
    .btn-default.whitecolor:hover {
      text-decoration: none;
      background-color: #151515;
      color: #ffffff; }
  .btn-default.btn-see-more {
    float: right;
    font-size: 0.75rem;
    padding: 0.375rem 1.25rem;
    line-height: 1.125rem;
    display: inline-block;
    vertical-align: top;
    text-decoration: none; }
    .btn-default.btn-see-more:hover {
      text-decoration: none;
      background-color: #42c8f4;
      color: #ffffff; }
  .btn-default.btn-loader {
    background-color: #ffffff;
    color: #2B2B2B;
    text-decoration: none;
    border: 0.125rem solid #dddddd;
    margin-bottom: 5rem; }
  .btn-default.btn-apply {
    min-width: 5rem;
    float: right;
    margin-right: 0; }

button.alm-load-more-btn.more {
  border-radius: 1.5625rem;
  display: inline-block;
  vertical-align: top;
  margin-right: 0.9375rem;
  padding: 0 1.5625rem;
  font-size: 1.125rem;
  line-height: 2.9375rem;
  min-width: 16.25rem;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  text-align: center;
  background: #ffffff !important;
  height: auto;
  color: #2B2B2B;
  text-decoration: none;
  border: 0.125rem solid #dddddd;
  margin-bottom: 5rem; }
  button.alm-load-more-btn.more::before {
    left: 0.75rem !important;
    top: 0.125rem !important; }
  button.alm-load-more-btn.more:hover {
    text-decoration: none;
    background-color: #2B2B2B !important;
    color: #ffffff; }

.navbar-brand a {
  width: 16.5625rem;
  display: block; }

.content_header_box {
  background: transparent;
  position: absolute;
  z-index: 987;
  width: 100%; }

.btn-play {
  background: url("../img/btn-play.png") no-repeat;
  width: 8.625rem;
  height: 8.625rem;
  display: block;
  text-indent: -99999px;
  overflow: hidden; }
  .btn-play:hover {
    background-position: -8.625rem 0;
    text-decoration: none; }

.menu_box_primary #menu-primary-nav {
  padding: 0;
  margin: 0; }

.menu_box_primary .dropdown-menu {
  background-color: rgba(0, 0, 0, 0.75) !important;
  position: absolute !important;
  border: 0 none; }

.menu_box_primary .navbar.navbar-expand-xl {
  display: inline-block;
  vertical-align: top;
  list-style: none;
  margin: 0;
  padding: 1.625rem 1.875rem 0.625rem; }
  .menu_box_primary .navbar.navbar-expand-xl ul li {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
    .menu_box_primary .navbar.navbar-expand-xl ul li a {
      color: #ffffff;
      padding: 0.3125rem 0.9375rem;
      font-weight: 500;
      font-size: 0.8125rem;
      text-transform: uppercase;
      line-height: 1.125rem;
      text-decoration: none; }
      .menu_box_primary .navbar.navbar-expand-xl ul li a:hover {
        outline: none;
        text-decoration: none;
        color: #42c8f4; }

.menu_box_primary .navbar-contact-us {
  display: inline-block;
  vertical-align: top;
  list-style: none; }
  .menu_box_primary .navbar-contact-us .top_nav {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    margin: 0;
    padding: 0.9375rem 1.875rem; }
    .menu_box_primary .navbar-contact-us .top_nav li {
      display: inline-block;
      vertical-align: top;
      list-style: none;
      font-style: italic;
      font-size: 0.9375rem;
      line-height: 1.375rem;
      color: #ffffff;
      font-weight: normal;
      padding: 0 1.25rem; }
      .menu_box_primary .navbar-contact-us .top_nav li i {
        padding-right: 0.625rem; }
      .menu_box_primary .navbar-contact-us .top_nav li a {
        outline: none;
        font-size: 0.9375rem;
        line-height: 1.375rem;
        color: #ffffff;
        text-decoration: none;
        font-style: italic;
        font-weight: normal;
        cursor: pointer; }
        .menu_box_primary .navbar-contact-us .top_nav li a i {
          padding-right: 0.625rem; }
        .menu_box_primary .navbar-contact-us .top_nav li a:hover {
          outline: none;
          text-decoration: none;
          color: #42c8f4; }

/* header-mobile */
/*-------------------------------------------------------------------------------------------------------------------*/
.header-mobile {
  padding: 2rem 0;
  width: 100%;
  display: none; }
  .header-mobile .navbar-toggler {
    background: #fff;
    float: right;
    margin-top: 0.5rem;
    border-radius: 100%;
    width: 3.625rem;
    height: 3.625rem; }

/* SIDENAV */
/*-------------------------------------------------------------------------------------------------------------------*/
#mySidenav {
  display: none; }

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 987654;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  -webkit-transition: .5s;
  transition: .5s;
  padding-top: 0; }
  .sidenav .navbar-brand {
    display: block;
    width: 100%;
    min-height: 7.0625rem;
    border-bottom: 0.0625rem solid #ddd; }
    .sidenav .navbar-brand a {
      padding: 2rem 1.25rem;
      display: block;
      width: 100%;
      text-decoration: none;
      overflow: hidden; }
      .sidenav .navbar-brand a:hover {
        text-decoration: none; }
  .sidenav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: none; }
    .sidenav ul li {
      list-style: none;
      margin: 0;
      text-align: center;
      padding: 0;
      border-bottom: 0.0625rem solid #dddddd; }
      .sidenav ul li a {
        font-size: 1.375rem;
        font-weight: bold;
        line-height: 2rem;
        padding: 0.625rem 1.5625rem;
        margin: 0;
        display: block;
        text-align: right;
        text-transform: uppercase;
        color: #151515;
        text-decoration: none; }
        .sidenav ul li a:hover {
          text-decoration: none;
          background-color: #42c8f4;
          color: #ffffff; }
  .sidenav .menu-menu-top-container {
    border-top: 0.125rem solid #dddddd; }
  .sidenav .footer_social_red {
    color: #636465;
    background: none;
    padding: 0;
    margin: 1.75rem; }
  .sidenav .closebtn {
    text-decoration: none;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    background: #42c8f4;
    display: block;
    position: absolute;
    margin-left: 0;
    border-radius: 100%;
    padding: 0.125rem 0.75rem;
    top: 2.6875rem;
    right: 1.1875rem;
    font-size: 1.875rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.1875rem; }
    .sidenav .closebtn:hover {
      text-decoration: none;
      color: #151515; }
  .sidenav #menu-nav-primary .menu-item-316,
  .sidenav #menu-menu-principal .menu-item-45 {
    display: block; }
  .sidenav #menu-nav-primary,
  .sidenav #menu-menu-principal {
    margin: 0;
    padding: 0; }
    .sidenav #menu-nav-primary li,
    .sidenav #menu-menu-principal li {
      list-style: none;
      margin: 0;
      padding: 0; }
      .sidenav #menu-nav-primary li a,
      .sidenav #menu-menu-principal li a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: normal;
        padding: 0.5rem 0.5rem 0.5rem 2rem;
        color: #636465;
        display: block; }
        .sidenav #menu-nav-primary li a:hover,
        .sidenav #menu-menu-principal li a:hover {
          text-decoration: none;
          color: #ffdb25; }

/* VIDEO */
/*-------------------------------------------------------------------------------------------------------------------*/
.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: auto;
  background-color: #000;
  min-height: 900px; }
  .fullscreen-bg::before {
    content: "";
    background: url(../img/pattern.png) rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0; }

.fullscreen-bg__video {
  width: 100%;
  height: auto; }

/* banner-with-button */
/*-------------------------------------------------------------------------------------------------------------------*/
.banner-with-button {
  background: #42c8f4;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 4.625rem 0.9375rem; }
  .banner-with-button h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 1.5625rem;
    line-height: 2rem;
    display: inline-block;
    vertical-align: top;
    color: #ffffff;
    margin: 0.5rem 1.25rem;
    padding: 0; }
  .banner-with-button a {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    vertical-align: top; }
    .banner-with-button a:hover {
      text-decoration: none; }

/* ABOUT US */
/*-------------------------------------------------------------------------------------------------------------------*/
.about-zone h1 {
  font-size: 4.5rem;
  line-height: 6.25rem;
  color: #fff;
  font-weight: 900;
  font-family: Raleway, sans-serif;
  text-align: left;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0; }

.about-zone h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin: 0 auto 1.5625rem;
  text-align: left;
  padding-left: 1.5625rem;
  max-width: 50%;
  float: left; }

.about-zone .about-slides {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  .about-zone .about-slides .about-slide {
    list-style: none;
    padding: 3.125rem 0 1.875rem; }
    .about-zone .about-slides .about-slide .about-image {
      width: 15.625rem;
      margin: 0 auto;
      margin-bottom: 0rem; }
    .about-zone .about-slides .about-slide .about-text {
      font-family: 'Roboto', sans-serif;
      font-weight: normal;
      color: #818181;
      font-size: 1.125rem;
      line-height: 1.375rem;
      padding: 0 1.875rem; }

/* carrier-shipper-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.img-padding-top {
  padding-top: 0.5rem; }

.carrier-shipper-box {
  text-align: center; }
  .carrier-shipper-box .cos-area {
    padding: 5.9375rem 0;
    background: transparent;
    width: auto;
    margin: 0 3.125rem; }
    .carrier-shipper-box .cos-area h1 {
      font-size: 2.8125rem;
      line-height: 3.4375rem;
      color: #2a2a2a;
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      padding-bottom: 0.625rem; }
    .carrier-shipper-box .cos-area h2 {
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #818181;
      font-size: 1rem;
      line-height: 1.5rem;
      max-width: 600px;
      margin: 0 auto 4.6875rem; }
  .carrier-shipper-box .cos-slides-box {
    margin: 0;
    padding: 0; }
    .carrier-shipper-box .cos-slides-box li {
      list-style: none;
      margin-bottom: 4.6875rem; }
      .carrier-shipper-box .cos-slides-box li .cos-slides-box-text {
        background: #ffffff;
        padding: 3.125rem;
        display: block;
        border-top: 2.375rem solid #f7f7f7;
        height: 100%; }
      .carrier-shipper-box .cos-slides-box li .cover-image {
        overflow: hidden;
        position: relative;
        z-index: 1; }
      .carrier-shipper-box .cos-slides-box li .border {
        background-color: #ffffff;
        width: 100%;
        height: 2.375rem;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        border: 0 none !important;
        position: relative;
        z-index: 1; }
        .carrier-shipper-box .cos-slides-box li .border .border-grey {
          position: absolute;
          z-index: 987;
          bottom: 0;
          right: 0;
          background-color: #f7f7f7;
          height: 2.375rem;
          display: block;
          width: 25%;
          border: 0 !important; }
        .carrier-shipper-box .cos-slides-box li .border.border-left .border-grey {
          right: inherit;
          left: 0; }
    .carrier-shipper-box .cos-slides-box h1 {
      font-size: 2.1875rem;
      line-height: 2.8125rem;
      color: #2a2a2a;
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      padding-bottom: 0.625rem; }
    .carrier-shipper-box .cos-slides-box p {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: #9e9f9e;
      font-size: 1.1875rem;
      line-height: 1.5rem;
      margin: 0 0 1.5625rem 0; }
    .carrier-shipper-box .cos-slides-box .read-more {
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      color: #42c8f4;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: uppercase; }
    .carrier-shipper-box .cos-slides-box .cos-slides-box-a {
      text-decoration: none; }
      .carrier-shipper-box .cos-slides-box .cos-slides-box-a:hover {
        text-decoration: none; }
        .carrier-shipper-box .cos-slides-box .cos-slides-box-a:hover h1, .carrier-shipper-box .cos-slides-box .cos-slides-box-a:hover p {
          color: #42c8f4 !important; }
        .carrier-shipper-box .cos-slides-box .cos-slides-box-a:hover .cover-image::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          z-index: 0;
          background-color: rgba(66, 200, 244, 0.61); }

/* services-box-container */
/*-------------------------------------------------------------------------------------------------------------------*/
.services-box-container {
  padding: 5.9375rem 0;
  background: transparent;
  width: auto;
  margin: 0;
  text-align: center;
  position: relative;
  z-index: 9; }
  .services-box-container h1 {
    font-size: 4.5rem;
    line-height: 6.25rem;
    color: #2A2A2A;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    padding-bottom: 0.625rem; }
  .services-box-container ul {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .services-box-container ul li {
      list-style: none;
      padding-top: 0.9375rem;
      -webkit-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out; }
      .services-box-container ul li:hover {
        padding-top: 0rem; }
      .services-box-container ul li a.services-box-container-a {
        text-decoration: none;
        background: url("../img/home/modes-home-box.png") 50% 100% no-repeat transparent;
        display: block;
        overflow: hidden;
        background-size: contain;
        position: relative;
        z-index: 1;
        width: 17.5rem;
        margin: 0 auto; }
        .services-box-container ul li a.services-box-container-a .services-box-container-img {
          border: 0 none;
          background-color: transparent;
          background-size: contain;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          height: 18.75rem;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          padding-top: 4.6875rem;
          width: 15.625rem;
          margin: 0 auto; }
          .services-box-container ul li a.services-box-container-a .services-box-container-img img {
            margin: 0 auto;
            max-width: 12.5rem; }
        .services-box-container ul li a.services-box-container-a h1.services-box-container-text {
          font-family: 'Roboto', sans-serif;
          color: #fff;
          font-size: 1.125rem;
          line-height: 1.375rem;
          background-color: #3cf;
          font-weight: 500;
          padding: 1.5625rem 0;
          text-decoration: none;
          text-transform: uppercase;
          display: block;
          visibility: visible;
          opacity: 1;
          max-width: 19.375rem;
          margin: 0 auto;
          -webkit-transition: all 0.25s ease-in-out;
          transition: all 0.25s ease-in-out; }
        .services-box-container ul li a.services-box-container-a .hover {
          display: none; }
        .services-box-container ul li a.services-box-container-a .normal {
          display: block; }
        .services-box-container ul li a.services-box-container-a:hover {
          text-decoration: none; }
          .services-box-container ul li a.services-box-container-a:hover .normal {
            display: none; }
          .services-box-container ul li a.services-box-container-a:hover .hover {
            display: block; }
          .services-box-container ul li a.services-box-container-a:hover h1.services-box-container-text {
            color: #ffffff;
            background-color: #10598b;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
            visibility: visible;
            opacity: 1; }
          .services-box-container ul li a.services-box-container-a:hover .services-box-container-img {
            border: 0 none;
            background-color: transparent; }

/* get-in-touch-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.get-in-touch-box {
  padding: 5.9375rem 0 5.9375rem 0;
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  max-width: 71.125rem;
  text-align: center;
  position: relative;
  z-index: 9;
  overflow: hidden; }
  .get-in-touch-box h1 {
    font-size: 4.5rem;
    line-height: 6.25rem;
    color: #2a2a2a;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    padding-bottom: 0.625rem;
    margin-top: 0; }
  .get-in-touch-box p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #818181;
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 0 auto 4.375rem;
    max-width: 47.5rem; }
  .get-in-touch-box .gform_wrapper .gform_footer {
    padding: 1rem 0 0.625rem;
    margin: 1.625rem 0 0;
    clear: both;
    width: 100%; }
  .get-in-touch-box .gform_body li#field_1_5,
  .get-in-touch-box .gform_body li#field_1_6,
  .get-in-touch-box .gform_body li#field_1_7 {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    width: 33.3333%; }
    .get-in-touch-box .gform_body li#field_1_5 input,
    .get-in-touch-box .gform_body li#field_1_6 input,
    .get-in-touch-box .gform_body li#field_1_7 input {
      width: 100%; }
  .get-in-touch-box .gform_body #field_1_6 .ginput_container_email,
  .get-in-touch-box .gform_body #input_1_5 span {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding-right: 1rem;
    margin-right: -0.25rem; }
  .get-in-touch-box .gform_body li#field_1_8 {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 1.5625rem !important;
    display: inline-block;
    vertical-align: top;
    max-width: 100% !important;
    width: 100%; }
  .get-in-touch-box .gform_body .gfield_label {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8125rem !important;
    display: inline-block;
    vertical-align: top;
    color: #2B2B2B;
    margin: 0.5rem 0rem;
    padding: 0; }
  .get-in-touch-box .gform_body input {
    width: 100%;
    padding: 1.25rem 1.875rem;
    margin: 0 0 0.9375rem;
    border-radius: 0.3125rem; }
  .get-in-touch-box .gform_body .top_label div.ginput_complex.ginput_container.gf_name_has_1,
  .get-in-touch-box .gform_body .top_label div.ginput_complex.ginput_container.gf_name_has_2,
  .get-in-touch-box .gform_body .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
    width: auto !important;
    border-radius: 0.3125rem; }
  .get-in-touch-box .gform_body li#field_1_10 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .get-in-touch-box .gform_body li#field_1_10 .ginput_container_radio {
      width: 100%;
      margin-bottom: 5.3125rem; }
      .get-in-touch-box .gform_body li#field_1_10 .ginput_container_radio ul {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
    .get-in-touch-box .gform_body li#field_1_10 .gfield_label {
      display: none; }
    .get-in-touch-box .gform_body li#field_1_10 #input_1_10 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .get-in-touch-box .gform_body li#field_1_10 #input_1_10 li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        background-color: #ffffff;
        border-radius: 100%;
        width: 9.125rem;
        height: 9.125rem;
        margin: 0 3.5rem !important; }
        .get-in-touch-box .gform_body li#field_1_10 #input_1_10 li.active {
          -webkit-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.16); }
        .get-in-touch-box .gform_body li#field_1_10 #input_1_10 li label {
          display: none; }
        .get-in-touch-box .gform_body li#field_1_10 #input_1_10 li input {
          width: 9.125rem !important;
          height: 9.125rem !important;
          opacity: 0;
          cursor: pointer; }
        .get-in-touch-box .gform_body li#field_1_10 #input_1_10 li.gchoice_1_10_0 {
          background: url("../img/contact/ico-contact-phone.png") 50% 50% no-repeat transparent;
          background-color: #ffffff;
          background-size: contain; }
        .get-in-touch-box .gform_body li#field_1_10 #input_1_10 li.gchoice_1_10_1 {
          background: url("../img/contact/ico-contact-mail.png") 50% 50% no-repeat transparent;
          background-color: #ffffff;
          background-size: contain; }
  .get-in-touch-box .gform_body li#field_1_5 input, .get-in-touch-box .gform_body li#field_1_6 input, .get-in-touch-box .gform_body li#field_1_7 input {
    width: 100%;
    border: 0;
    background: #f7f7f7;
    padding: 1.25rem 1.875rem;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    font-family: 'Raleway', sans-serif;
    color: #b0b0b0;
    border-radius: 0.3125rem;
    min-height: 3.6875rem; }
  .get-in-touch-box .gform_body .textarea#input_1_8 {
    height: 10rem;
    width: 100%;
    border: 0;
    background: #f7f7f7;
    padding: 1.25rem 1.875rem;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    font-family: 'Raleway', sans-serif;
    color: #b0b0b0;
    border-radius: 0.3125rem;
    min-height: 13rem; }
  .get-in-touch-box .gform_body .gfield_error label {
    padding: 0.3125rem 0.625rem !important; }
  .get-in-touch-box .gform_wrapper .validation_message {
    padding: 0.3125rem 0.625rem !important; }
  .get-in-touch-box .gform_footer.top_label {
    text-align: center; }
    .get-in-touch-box .gform_footer.top_label #gform_submit_button_1 {
      border-radius: 1.5625rem;
      margin-right: 0;
      margin-left: 0;
      padding: 0.625rem 1.5625rem;
      background: #33CCFF;
      color: #ffffff;
      font-size: 1.5rem;
      line-height: 1.8125rem;
      text-transform: none;
      font-weight: 500;
      border: 0;
      display: inline-block;
      vertical-align: top;
      font-family: 'Roboto', sans-serif;
      min-width: 14.75rem; }
      .get-in-touch-box .gform_footer.top_label #gform_submit_button_1:hover {
        background: #014F86;
        color: #ffffff; }
  .get-in-touch-box.get-in-touch-box-sky .gfield_label {
    color: #ffffff; }
  .get-in-touch-box.get-in-touch-box-sky .gform_footer.top_label #gform_submit_button_1 {
    background: #ffffff;
    color: #42c8f4; }
    .get-in-touch-box.get-in-touch-box-sky .gform_footer.top_label #gform_submit_button_1:hover {
      background: #014F86;
      color: #fff; }

/* our-team-box-area */
/*-------------------------------------------------------------------------------------------------------------------*/
.our-team-box-area {
  padding: 5.9375rem 0;
  background: url(../img/about/team-bg.png) 50% 50% no-repeat;
  background-size: cover;
  width: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 3.125rem; }
  .our-team-box-area h1 {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
    color: #2a2a2a;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    text-align: left;
    padding: 0 0 0.625rem 0; }

/* breadcrumb-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.breadcrumb-box {
  padding: 1.5rem 0 1.125rem;
  background: #f9f9f9; }
  .breadcrumb-box ul {
    margin: 0;
    padding: 0; }
    .breadcrumb-box ul li {
      list-style: none;
      color: #d0d0d0;
      font-family: 'Roboto', sans-serif;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      font-weight: 500;
      text-transform: uppercase;
      display: inline-block;
      vertical-align: top;
      padding: 0 0.125rem; }
      .breadcrumb-box ul li a {
        list-style: none;
        color: #d0d0d0;
        font-family: 'Roboto', sans-serif;
        font-size: 0.8125rem;
        line-height: 1.125rem;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none; }
        .breadcrumb-box ul li a:hover {
          text-decoration: none; }

/* opportunities-list-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.jobs-list-desktop .responsive-x4 li {
  padding: 0;
  height: 30.625rem;
  min-width: 26.25rem;
  margin-right: -9.625rem; }

.opportunities-list-box {
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 98; }
  .opportunities-list-box .opp-bg {
    background: url("../img/about/oportunities-bg.png") 50% 0 no-repeat;
    background-size: cover;
    min-height: 0.0625rem;
    overflow: hidden;
    width: 100%; }
  .opportunities-list-box .cos-area {
    padding: 3.125rem 0;
    background: transparent;
    width: auto;
    margin: 0 3.125rem; }
    .opportunities-list-box .cos-area .cos-area-2 h1 {
      font-size: 2.8125rem;
      line-height: 3.4375rem;
      color: #2a2a2a;
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      padding-bottom: 0.625rem; }
    .opportunities-list-box .cos-area .cos-area-2 h2 {
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #818181;
      font-size: 1rem;
      line-height: 1.5rem;
      max-width: 600px;
      margin: 0 auto 4.6875rem; }
    .opportunities-list-box .cos-area .cos-area-2 .arrow-slider .arrow-slider-left {
      background: url("../img/about/arrow-slider-left.png") 0 0 no-repeat;
      border: 0;
      width: 1.125rem;
      height: 1.375rem;
      display: inline-block;
      vertical-align: top;
      text-indent: -99999px;
      outline: none; }
    .opportunities-list-box .cos-area .cos-area-2 .arrow-slider .arrow-slider-next {
      background: url("../img/about/arrow-slider-right.png") 100% 0 no-repeat;
      border: 0;
      width: 1.125rem;
      height: 1.375rem;
      display: inline-block;
      vertical-align: top;
      text-indent: -99999px;
      outline: none;
      left: 17.8125rem; }
  .opportunities-list-box #nav-tabContent {
    min-height: 30.625rem;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin: 0 3.125rem; }
  .opportunities-list-box .slick-list.draggable {
    margin: 0 4.0625rem;
    padding: 0 3.75rem; }
  .opportunities-list-box ul {
    padding: 0 0 3.4375rem; }
    .opportunities-list-box ul .slick-arrow {
      position: absolute;
      z-index: 987;
      top: 50%;
      margin-top: -1.3125rem; }
    .opportunities-list-box ul .slick-prev.slick-arrow {
      background: url(../img/about/arrow-slider-left.png) 0 0 no-repeat;
      background-size: 2.625rem 2.625rem;
      border: 0;
      width: 2.625rem;
      height: 2.625rem;
      display: inline-block;
      vertical-align: top;
      text-indent: -99999px;
      outline: none;
      left: 0;
      margin-left: 0; }
    .opportunities-list-box ul .slick-next.slick-arrow {
      background: url(../img/about/arrow-slider-right.png) 100% 0 no-repeat;
      background-size: 2.625rem 2.625rem;
      border: 0;
      width: 2.625rem;
      height: 2.625rem;
      display: inline-block;
      vertical-align: top;
      text-indent: -99999px;
      outline: none;
      right: 0;
      margin-right: 0; }
    .opportunities-list-box ul li {
      list-style: none;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      .opportunities-list-box ul li .the-linker {
        position: relative;
        z-index: 1;
        margin: 0;
        display: block;
        width: 100%; }
      .opportunities-list-box ul li h1 {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #515151;
        font-size: 1.1875rem;
        line-height: 1.5rem;
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%; }
      .opportunities-list-box ul li h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #212121;
        font-size: 0.75rem;
        line-height: 1.5rem;
        font-style: italic;
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%; }
      .opportunities-list-box ul li .t-zone {
        display: inline-block;
        vertical-align: top;
        min-height: 40rem;
        height: 100%;
        max-height: 40rem;
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
        min-width: 28.75rem;
        -webkit-transform: rotate(-15deg);
                transform: rotate(-15deg);
        margin-top: -5rem;
        border-left: 1.125rem solid #fff; }
      .opportunities-list-box ul li .t-zone-hover {
        display: none;
        vertical-align: top;
        min-height: 33.75rem;
        height: 100%;
        max-height: 33.75rem;
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
        min-width: 28.75rem;
        margin-top: 0rem; }
      .opportunities-list-box ul li:hover .t-zone-hover {
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        height: 100vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: absolute;
        z-index: 987;
        top: 0;
        left: -4.5rem; }
      .opportunities-list-box ul li .t-data {
        padding: 1.375rem;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        position: absolute;
        z-index: 987;
        bottom: 0;
        left: 0;
        height: 100%; }
      .opportunities-list-box ul li .t-zone-hover .t-data-hover {
        margin: 0 auto;
        max-width: 12.5rem;
        overflow: hidden;
        padding-top: 6.875rem;
        position: relative;
        left: -1.875rem; }
      .opportunities-list-box ul li .t-zone-hover::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        min-height: 37.5rem;
        min-width: 28.75rem;
        -webkit-transform: rotate(-15deg);
                transform: rotate(-15deg);
        margin-top: -2.625rem;
        margin-left: 5.5rem; }
      .opportunities-list-box ul li .t-zone-hover h1 {
        color: #ffffff;
        margin-bottom: 0.625rem;
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: bold;
        font-family: 'Raleway', sans-serif;
        text-align: right; }
      .opportunities-list-box ul li .t-zone-hover p {
        color: #ffffff;
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        text-align: right;
        margin-bottom: 1.875rem; }

@-moz-document url-prefix() {
  .opportunities-list-box ul li .t-zone-hover::before {
    margin-top: -5.125rem;
    margin-left: 11rem; } }

/* CONTACT US */
/*-------------------------------------------------------------------------------------------------------------------*/
.contact-the-container {
  padding: 2.75rem; }
  .contact-the-container h1 {
    margin-top: 0; }
  .contact-the-container .nav-contact {
    padding: 0 5.9375rem;
    margin: 3.125rem auto 4.375rem; }
    .contact-the-container .nav-contact li {
      list-style: none;
      display: inline-block;
      vertical-align: top;
      padding: 0.75rem 0.75rem 0.75rem 3.75rem;
      text-align: left;
      min-height: 2.8125rem; }
      .contact-the-container .nav-contact li.icon-telephone {
        background: url(../img/contact/icon-email.png) 0 0 no-repeat; }
      .contact-the-container .nav-contact li.icon-faxphone {
        background: url(../img/contact/icon-fax.png) 0 0 no-repeat; }
      .contact-the-container .nav-contact li.icon-email {
        background: url(../img/contact/icon-phone.png) 0 0 no-repeat; }

.contact-us-services .slides-contact-us-services {
  margin: 0;
  padding: 0; }
  .contact-us-services .slides-contact-us-services .slide-contact-us-services {
    margin: 0 0 2.4375rem 0;
    display: inline-block;
    padding: 6.25rem 0;
    background: #42c8f4 url(../img/contact/icon-triangle.png) 50% 0 no-repeat;
    text-align: center; }
    .contact-us-services .slides-contact-us-services .slide-contact-us-services .scus-image {
      width: 6.25rem;
      height: 6.25rem;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      margin-bottom: 1.5625rem;
      background-position: 0 0; }
    .contact-us-services .slides-contact-us-services .slide-contact-us-services h1 {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      color: #282929;
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin: 0 0 2.8125rem 0;
      padding: 0 0 2.25rem 0;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      width: 100%;
      position: relative;
      z-index: 1; }
      .contact-us-services .slides-contact-us-services .slide-contact-us-services h1::after {
        content: '';
        height: 3px;
        background-color: #282929;
        position: relative;
        z-index: 9;
        width: 5rem;
        display: block;
        overflow: hidden;
        margin: 0 auto;
        top: 2.3125rem; }
    .contact-us-services .slides-contact-us-services .slide-contact-us-services h2 {
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      color: #282929;
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin: 0 0 0 0;
      padding: 0 0 0.625rem 0;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      width: 100%; }
    .contact-us-services .slides-contact-us-services .slide-contact-us-services a {
      color: #ffffff;
      text-decoration: none;
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.5rem; }
      .contact-us-services .slides-contact-us-services .slide-contact-us-services a:hover {
        text-decoration: underline; }
    .contact-us-services .slides-contact-us-services .slide-contact-us-services.black-bg {
      background-color: #151515; }
      .contact-us-services .slides-contact-us-services .slide-contact-us-services.black-bg .scus-image {
        background-position: 0 100%; }
      .contact-us-services .slides-contact-us-services .slide-contact-us-services.black-bg h1 {
        color: #ffffff; }
        .contact-us-services .slides-contact-us-services .slide-contact-us-services.black-bg h1::after {
          background-color: #ffffff; }
      .contact-us-services .slides-contact-us-services .slide-contact-us-services.black-bg h2 {
        color: #ffffff; }
      .contact-us-services .slides-contact-us-services .slide-contact-us-services.black-bg a {
        color: #42c8f4;
        text-decoration: none; }
        .contact-us-services .slides-contact-us-services .slide-contact-us-services.black-bg a:hover {
          text-decoration: underline; }

/* ERROR 404 */
/*-------------------------------------------------------------------------------------------------------------------*/
.error404 .image-404-top {
  padding: 5.9375rem 0; }

.error404 .error-page-area {
  position: relative;
  z-index: 9;
  top: -17.5rem;
  padding: 0;
  text-align: center;
  width: 100%;
  max-width: 25rem; }
  .error404 .error-page-area h3 {
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.125rem;
    width: 100%;
    display: block;
    text-align: center; }

/* blog-the-container */
/*-------------------------------------------------------------------------------------------------------------------*/
blockquote {
  color: #42c8f4;
  border-left: 0.125rem solid #ddd;
  position: relative;
  z-index: 9;
  margin-left: 4rem;
  padding: 0 1.875rem 0 1.875rem; }
  blockquote p {
    color: #42c8f4 !important;
    font-weight: normal;
    font-style: italic;
    font-size: 0.875rem;
    line-height: 1.125rem; }
  blockquote::before {
    content: '';
    background: url(../img/blog/icon-quote.png) no-repeat transparent;
    position: absolute;
    z-index: 9887;
    width: 2.1875rem;
    height: 2rem;
    top: 0;
    left: -3.875rem;
    display: inline-block;
    vertical-align: top; }

.blog-the-container {
  padding: 1.25rem 0;
  width: 100%;
  overflow: hidden;
  max-width: 107.1875rem;
  margin: 0 auto;
  display: block;
  position: relative;
  z-index: 98; }
  .blog-the-container .fuller-post {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 987; }
  .blog-the-container .blog-post {
    position: relative;
    z-index: 9;
    width: 100%;
    max-width: 90.625rem;
    margin: 0 0 6.25rem 0;
    overflow: hidden;
    padding-bottom: 2.5rem; }
    .blog-the-container .blog-post:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #eee;
      height: 2.5rem;
      z-index: 987;
      border-left: 4.5rem solid #fff; }
  .blog-the-container .blog-desc h1 {
    font-size: 1rem;
    line-height: 1.375rem; }
  .blog-the-container .blog-desc h2 {
    font-size: 0.875rem;
    line-height: 1.375rem; }
  .blog-the-container .entry-date {
    position: absolute;
    z-index: 987;
    top: 50%;
    margin-top: -3.0625rem;
    right: 3.25rem;
    padding-right: 0.9375rem;
    background: url(../img/news/bg-date.png) no-repeat transparent;
    background-size: 11.3125rem 6.125rem;
    width: 11.3125rem;
    height: 6.125rem;
    display: block;
    text-align: left;
    padding-left: 0.9375rem;
    color: #fff;
    font-size: 2.375rem;
    line-height: 2.875rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif; }
    .blog-the-container .entry-date strong {
      font-weight: 300; }
  .blog-the-container .blog-image {
    position: relative;
    z-index: 987;
    overflow: hidden;
    -webkit-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.2); }
    .blog-the-container .blog-image .the-linker-link {
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
      background-position: 50% 50%; }
    .blog-the-container .blog-image:after {
      content: "";
      position: absolute;
      bottom: -2.1875rem;
      left: -0.125rem;
      height: 2.5rem;
      width: 110%;
      background: #eee;
      -webkit-transform: rotate(1deg);
      transform: rotate(1deg);
      border-left: 4.625rem solid #fff; }
    .blog-the-container .blog-image img {
      width: 100%; }
  .blog-the-container .blog-almacen {
    position: relative;
    z-index: 9;
    overflow: hidden;
    background: #eee;
    margin-top: 3.125rem !important; }
    .blog-the-container .blog-almacen .blog-almacen-content {
      padding: 1.25rem 2.5rem; }
  .blog-the-container .the-title-a {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 20px;
    color: #282929;
    text-decoration: none; }
    .blog-the-container .the-title-a h1 {
      font-family: 'Raleway', sans-serif;
      font-weight: 900;
      font-size: 4.5rem;
      line-height: 5rem;
      color: #2B2B2B; }
    .blog-the-container .the-title-a:hover {
      text-decoration: none; }
      .blog-the-container .the-title-a:hover h1 {
        color: #42c8f4;
        text-decoration: none; }
  .blog-the-container .blog-desc {
    color: #878787;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 0 auto 1.5625rem; }
    .blog-the-container .blog-desc p {
      color: #878787;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin: 0 auto 1.5625rem; }
  .blog-the-container .blog-extra {
    background: 0 none;
    border-top: 0.0625rem solid #ededed;
    padding: 1.5625rem 0;
    margin-bottom: 1.5625rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal; }
    .blog-the-container .blog-extra.blog-extra-single {
      border-bottom: 0.0625rem solid #ededed; }
  .blog-the-container .blog-extra-date {
    background: url(../img/blog/icon-clock.png) no-repeat;
    padding: 0 0 0 1.25rem;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    color: #b5b5b5;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    background-size: 0.8125rem 0.875rem;
    background-position: 0 0.125rem; }
  .blog-the-container .category-list {
    background: url(../img/blog/icon-flag.png) no-repeat;
    background-size: 0.9375rem 0.875rem;
    background-position: 0 0.3125rem;
    padding: 0 0 0 1.375rem;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    color: #b5b5b5;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal; }
    .blog-the-container .category-list a {
      font-size: 0.8125rem;
      line-height: 1.25rem;
      color: #b5b5b5;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      text-decoration: none;
      background: transparent; }
      .blog-the-container .category-list a:hover {
        background: transparent;
        text-decoration: none;
        color: #151515; }
  .blog-the-container .tag-list {
    background: url(../img/blog/icon-fav.png) no-repeat;
    background-position: 0 0.3125rem;
    padding: 0 0 0 1.375rem;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    color: #b5b5b5;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    background-size: 0.9375rem 0.75rem; }
    .blog-the-container .tag-list a {
      font-size: 0.8125rem;
      line-height: 1.25rem;
      color: #b5b5b5;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      text-decoration: none; }
      .blog-the-container .tag-list a:hover {
        text-decoration: none;
        color: #151515; }

.wp-pagenavi {
  background: 0 none;
  border-top: 0.0625rem solid #ededed;
  padding: 1.5625rem 0;
  margin-bottom: 1.5625rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #b5b5b5; }
  .wp-pagenavi a, .wp-pagenavi span {
    text-decoration: none;
    border: 0.0625rem solid #BFBFBF;
    padding: 0.1875rem 0.5625rem;
    margin: 0.125rem;
    border-radius: 0.1875rem; }
  .wp-pagenavi a {
    color: #42c8f4;
    border: 0.0625rem solid #42c8f4; }
    .wp-pagenavi a:hover {
      color: #151515; }

.share-social-area {
  border-bottom: 0.0625rem solid #ededed;
  padding: 1.5625rem 0;
  margin-bottom: 1.5625rem;
  display: block;
  overflow: hidden;
  width: 100%; }
  .share-social-area h3 {
    margin: 0;
    padding: 0 0 0.3125rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #b5b5b5; }

/* blog-post-single */
/*------------------------------------------------------------------------------------------------------------------*/
.blog-post-single .blog-almacen {
  background: transparent; }

.blog-post-single .blog-post::after {
  display: none; }

.blog-post-single .entry-date {
  margin-top: 0 !important;
  top: 0 !important;
  right: inherit;
  left: 0; }

.blog-post-single .the-linker-link {
  max-height: 33.75rem; }

.blog-post-single .blog-image {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }

/* the-post-navigation */
/*------------------------------------------------------------------------------------------------------------------*/
.navigation.post-navigation {
  border-bottom: 1px solid #ededed;
  padding: 0.0625rem 0 1.5625rem;
  margin-bottom: 1.5625rem;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  overflow: hidden; }

.post-navigation .nav-previous a, .post-navigation .nav-next a {
  border: 0.0625rem solid #ddd;
  border-radius: 0.3125rem;
  display: inline-block;
  padding: 0.3125rem 0.625rem;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #b5b5b5; }
  .post-navigation .nav-previous a:hover, .post-navigation .nav-next a:hover {
    text-decoration: none;
    color: #fff !important;
    background: #42c8f4 !important; }

.post-navigation .nav-next a::before,
.post-navigation .nav-previous a::before {
  margin-right: 0.4375rem; }

/* the-sidebar */
/*-------------------------------------------------------------------------------------------------------------------*/
.the-sidebar {
  width: 100%; }
  .the-sidebar .widget-title {
    color: #282929;
    background: url(../img/blog/icon-border.png) no-repeat 0 100%;
    padding-bottom: 0.9375rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.9375rem; }
  .the-sidebar .widget {
    margin: 4.375rem 0; }
    .the-sidebar .widget.widget_search {
      margin: 0 0 4.375rem 0; }
  .the-sidebar .widget_categories ul {
    margin: 0;
    padding: 0; }
    .the-sidebar .widget_categories ul .children {
      margin: 0.9375rem 0 0 2.1875rem;
      padding: 0; }
      .the-sidebar .widget_categories ul .children li {
        border-top: 0.0625rem solid #e6e5e5;
        border-bottom: 0 solid #e6e5e5; }
    .the-sidebar .widget_categories ul li {
      list-style: none;
      margin: 0;
      padding: 0.625rem 0;
      border-bottom: 0.0625rem solid #e6e5e5;
      display: inline-block;
      vertical-align: top;
      font-family: 'Roboto', sans-serif;
      font-size: 0.875rem;
      color: #151515;
      line-height: 1.25rem;
      font-weight: 500;
      width: 100%; }
      .the-sidebar .widget_categories ul li .nav-link {
        display: inline-block;
        padding: 0;
        vertical-align: top;
        margin: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 0.875rem;
        color: #151515 !important;
        line-height: 1.25rem;
        font-weight: 500; }
        .the-sidebar .widget_categories ul li .nav-link:hover {
          text-decoration: none;
          color: #42c8f4 !important; }
  .the-sidebar .search-form {
    background: #f0f0f0;
    padding: 0;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 1; }
    .the-sidebar .search-form label {
      display: inline-block;
      width: 100%;
      padding: 0;
      vertical-align: top;
      overflow: hidden;
      margin: 0;
      position: relative;
      z-index: 1; }
    .the-sidebar .search-form .form-control {
      display: block;
      width: 100%;
      padding: 0.625rem 3.4375rem 0.625rem 0.9375rem;
      font-size: 1.0625rem;
      line-height: 1.375rem;
      font-family: 'Raleway', sans-serif;
      font-weight: normal;
      color: #b0b0b0;
      -webkit-box-sizing: inherit;
              box-sizing: inherit;
      border-radius: 0;
      border: 1.125rem solid #f0f0f0; }
    .the-sidebar .search-form .search-submit {
      background: url(../img/blog/icon-search.png) no-repeat 0 50%;
      padding: 0 0 0 1.375rem;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      color: #b5b5b5;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      width: 3.1875rem;
      height: 2.5rem;
      border-radius: 0;
      text-indent: -99999px;
      overflow: hidden;
      top: 1.1875rem;
      right: 0.1875rem;
      position: absolute;
      z-index: 987; }
  .the-sidebar .tagcloud a {
    display: inline-block;
    padding: 0.625rem 1.125rem;
    vertical-align: top;
    margin-right: 0.625rem;
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem !important;
    color: #424242 !important;
    line-height: 1.25rem !important;
    font-weight: 400;
    border: 0.0625rem solid #e4e4e4;
    margin-bottom: 0.75rem; }
    .the-sidebar .tagcloud a:hover {
      text-decoration: none;
      color: #ffffff !important;
      background: #42c8f4 !important; }
  .the-sidebar #recentcomments .recentcomments {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #c5c5c5;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    background: url(../img/blog/icon-comment.png) 0 50% no-repeat;
    padding: 0.3125rem 0 0.3125rem 1.5625rem !important;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    overflow: hidden; }
    .the-sidebar #recentcomments .recentcomments a {
      display: inline-block;
      padding: 0;
      vertical-align: top;
      margin: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 0.875rem;
      color: #151515 !important;
      line-height: 1.25rem;
      font-weight: 400; }
      .the-sidebar #recentcomments .recentcomments a:hover {
        text-decoration: none;
        color: #42c8f4 !important; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b0b0b0 !important;
  opacity: 1 !important; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #b0b0b0 !important;
  opacity: 1 !important; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #b0b0b0 !important;
  opacity: 1 !important; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #b0b0b0 !important;
  opacity: 1 !important; }

/* COMMENTS */
/*-------------------------------------------------------------------------------------------------------------------*/
.comments-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem; }

.comment-content.card-block {
  padding: 0.3125rem 0.625rem; }

.comment-content.card-block p {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  color: #878787;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-style: italic;
  margin: 0 0 0.3125rem; }

.comment-list .card-header {
  padding: 0 0.625rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 none;
  font-family: 'Roboto', sans-serif; }
  .comment-list .card-header h5 {
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #151515; }
    .comment-list .card-header h5 a {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      color: #151515;
      font-size: 0.875rem;
      line-height: 1.125rem;
      text-decoration: none;
      font-style: normal; }
      .comment-list .card-header h5 a:hover {
        text-decoration: none; }
  .comment-list .card-header .fn {
    font-style: normal; }

.comment-list .card-footer {
  padding: 0 0.625rem 1.5625rem 0;
  background-color: transparent;
  border-top: 0 none; }

.comment-list .card {
  border: 0 none; }

.comment-list .comment {
  border-top: 0.0625rem solid #ededed;
  padding: 1.5625rem 0 0; }

.comment-list .comment-body.media.mb-4 {
  margin-bottom: 0 !important; }

.comment-list .comment-meta a {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  color: #878787;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-decoration: none;
  font-style: italic; }
  .comment-list .comment-meta a:hover {
    text-decoration: none; }

.comment-list .comment-reply-link.btn.btn-primary {
  border-radius: 1.5625rem;
  margin-right: 0.9375rem;
  padding: 0.4375rem 1.5625rem;
  background: #42c8f4;
  color: #fff;
  font-size: 0.9375rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  font-weight: 600;
  border: 0 none;
  height: 2.625rem; }
  .comment-list .comment-reply-link.btn.btn-primary:hover {
    background: #151515; }

.comment-list .comment-awaiting-moderation {
  background: #f0f0f0;
  padding: 0.3125rem 0.5rem;
  margin: 0.125rem 0.625rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  color: #878787;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-decoration: none;
  font-style: normal; }

.logged-in-as a {
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: normal;
  font-style: normal;
  color: #42c8f4;
  text-decoration: none; }
  .logged-in-as a:hover {
    color: #151515;
    text-decoration: none; }

.comment-respond {
  border: 0.0625rem dashed #42c8f4;
  padding: 2.1875rem;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%; }
  .comment-respond .comment-form-cookies-consent,
  .comment-respond .comment-notes {
    font-family: 'Open Sans', sans-serif;
    color: #878787;
    line-height: 1.125rem;
    font-size: 0.875rem; }
  .comment-respond .comment-reply-title {
    font-family: Montserrat, sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: bold;
    font-style: normal;
    margin-top: 0; }
  .comment-respond .form-submit {
    margin-bottom: 0; }
  .comment-respond #cancel-comment-reply-link,
  .comment-respond .form-submit #commentsubmit {
    border-radius: 1.5625rem;
    margin-right: 0.9375rem;
    padding: 0.4375rem 1.5625rem;
    background: #42c8f4;
    color: #fff;
    font-size: 0.9375rem;
    line-height: 1.6875rem;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    text-decoration: none;
    height: 2.625rem;
    display: inline-block;
    vertical-align: top; }
    .comment-respond #cancel-comment-reply-link:hover,
    .comment-respond .form-submit #commentsubmit:hover {
      background: #151515;
      text-decoration: none; }
  .comment-respond #cancel-comment-reply-link {
    float: right;
    margin-right: 0; }
  .comment-respond .comment-form-cookies-consent #wp-comment-cookies-consent {
    margin-right: 0.5rem; }
  .comment-respond .comment-form-author,
  .comment-respond .comment-form-email,
  .comment-respond .comment-form-url {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin-right: -0.5rem; }
    .comment-respond .comment-form-author input,
    .comment-respond .comment-form-email input,
    .comment-respond .comment-form-url input {
      background-color: #f7f7f7;
      border: 0;
      padding: 1.5625rem;
      font-family: 'Raleway', sans-serif;
      font-weight: 400;
      color: #b0b0b0;
      font-size: 1.0625rem;
      line-height: 1.5rem;
      border-radius: 0.3125rem;
      width: 95%; }

.comment-form .form-control {
  background-color: #f7f7f7;
  border: 0 none;
  padding: 1.5625rem 1.5625rem;
  resize: none;
  font-weight: normal;
  color: #b0b0b0;
  font-size: 1.0625rem;
  line-height: 1.375rem;
  font-family: 'Raleway', sans-serif;
  border-radius: 0.3125rem; }

.comment-form .form-allowed-tags,
.comment-form div.alert.alert-info {
  display: none; }

/* search-the-container */
/*-------------------------------------------------------------------------------------------------------------------*/
.search-the-container .post.hentry {
  margin-bottom: 0; }

.search-the-container .entry-title > a {
  color: #151515;
  text-decoration: none; }
  .search-the-container .entry-title > a:hover {
    color: #42c8f4;
    text-decoration: none; }

.search.search-results .jobs.type-jobs,
.search.search-results .testimonial.type-testimonial {
  display: none !important; }

/* the-linker */
/*-------------------------------------------------------------------------------------------------------------------*/
.the-linker {
  text-decoration: none; }
  .the-linker:hover {
    text-decoration: none; }

/* TABS */
/*-------------------------------------------------------------------------------------------------------------------*/
.nav-tabs {
  border: 0;
  margin-bottom: 2.8125rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  overflow: hidden; }
  .nav-tabs .nav-link {
    color: #2a2a2a;
    background-color: transparent;
    border: 0.0625rem solid #d9d9d9;
    text-transform: uppercase;
    font-weight: 400;
    text-decoration: none;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    font-family: Roboto, sans-serif;
    display: inline-block;
    vertical-align: top;
    border-radius: 1.5625rem;
    margin: 0 0.3125rem;
    padding: 0.4375rem 1.5625rem; }
    .nav-tabs .nav-link.active {
      text-decoration: none;
      background-color: #42c8f4;
      color: #ffffff;
      font-weight: 500; }
  .nav-tabs .nav-item {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    text-align: center; }

/* contact-us-maps */
/*-------------------------------------------------------------------------------------------------------------------*/
.contact-us-maps {
  margin: 0 0 2.8125rem;
  padding: 0; }
  .contact-us-maps p {
    margin: 0;
    padding: 0; }
  .contact-us-maps iframe {
    margin: 0;
    padding: 0;
    width: 100%; }

.about-zone-in-contact {
  text-align: center; }
  .about-zone-in-contact .cos-area-cus {
    padding: 5.9375rem 0;
    background: #f7f7f7;
    width: auto;
    margin: 0 3.125rem; }

/* CARRIER PAGE */
/*-------------------------------------------------------------------------------------------------------------------*/
.why-us-box {
  background-color: #ffffff; }
  .why-us-box h1 {
    font-size: 4.5rem;
    line-height: 6.25rem;
    color: #fff;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    text-align: left;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0; }
  .why-us-box .the-desc p {
    color: #fff;
    margin: 0 auto 1.5625rem;
    text-align: left;
    padding-left: 1.5625rem;
    max-width: 100%;
    float: left;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem; }
  .why-us-box .the-desc a {
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: #fff; }
    .why-us-box .the-desc a:hover {
      text-decoration: none; }

.equipment-box {
  text-align: center; }
  .equipment-box .equipment-box-container {
    padding: 0rem;
    background: transparent;
    width: auto;
    margin: 0 3.125rem; }
    .equipment-box .equipment-box-container h1 {
      font-size: 4.5rem;
      line-height: 5rem;
      color: #2B2B2B;
      font-weight: 900;
      font-family: Raleway, sans-serif;
      padding-bottom: 0.625rem;
      padding-top: 0rem; }
    .equipment-box .equipment-box-container .the-desc {
      max-width: 50rem;
      margin: 0 auto 1.5625rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      color: #37CAFB;
      font-size: 1.125rem;
      line-height: 1.375rem; }
    .equipment-box .equipment-box-container .equipment-repeater {
      text-align: center;
      margin: 7.5rem auto 0; }
      .equipment-box .equipment-box-container .equipment-repeater .white-time h1 {
        text-align: left;
        color: #ffffff !important;
        max-width: 100%; }
      .equipment-box .equipment-box-container .equipment-repeater .white-time .the-desc {
        text-align: left;
        color: #ffffff !important; }
      .equipment-box .equipment-box-container .equipment-repeater ul li {
        list-style: none;
        margin-bottom: 4.0625rem; }
        .equipment-box .equipment-box-container .equipment-repeater ul li h1 {
          font-size: 1.5rem;
          line-height: 1.8125rem;
          color: #42c8f4;
          font-weight: 700;
          font-family: 'Roboto', sans-serif;
          padding: 0 0 0.3125rem;
          margin: 0 auto;
          text-align: center;
          max-width: 100%;
          width: 100%;
          display: block; }
  .equipment-box.equipment-box-generic .equipment-box-container .equipment-repeater ul li h1 {
    max-width: 100%;
    text-align: left;
    margin-top: 0;
    padding-top: 0; }
  .equipment-box.equipment-box-generic .equipment-box-container .equipment-repeater ul li .the-desc {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #42c8f4;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    margin: 0 auto;
    text-align: left;
    max-width: 100%;
    width: 100%;
    display: block; }

.equipment-box.industry-box .equipment-box-container {
  padding: 5.9375rem 0; }
  .equipment-box.industry-box .equipment-box-container h1 {
    color: #ffffff;
    padding-top: 2.8125rem; }
  .equipment-box.industry-box .equipment-box-container .equipment-repeater ul li h1 {
    padding: 1.875rem 0 0.3125rem; }

/* about-pre-zone */
/*-------------------------------------------------------------------------------------------------------------------*/
.about-pre-zone {
  background: #ffffff;
  width: auto;
  margin: 3.125rem 3.125rem 3.125rem 3.125rem; }

.why-us-box-shipper {
  background: url(../img/shipper/shipper-repeater.jpg) 0 0 repeat-x;
  background-size: contain; }
  .why-us-box-shipper .contain-image {
    padding: 5.625rem 0; }

/* our-modes-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.our-modes-box {
  padding: 5.9375rem 0;
  background: url(../img/about/team-bg.png) 50% 50% no-repeat;
  background-size: cover;
  width: auto;
  margin: 0 auto; }
  .our-modes-box h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
    color: #2a2a2a;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    padding-bottom: 0.625rem;
    width: 100%;
    text-align: center;
    display: inline-block;
    vertical-align: top; }
  .our-modes-box h2 {
    max-width: 50rem;
    margin: 0 auto 4.6875rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    width: 100%; }
  .our-modes-box .services-box-container {
    padding: 0;
    background: transparent;
    width: auto;
    margin: 0;
    text-align: center; }
    .our-modes-box .services-box-container ul.row {
      margin: 0;
      padding: 0;
      width: 100%; }

/* skills-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.why-us-box.why-us-box-white {
  background: #fff; }
  .why-us-box.why-us-box-white h1 span {
    color: #42c8f4; }
  .why-us-box.why-us-box-white .the-desc {
    padding-right: 35%; }

.skills-box {
  margin: 0 2.1875rem; }
  .skills-box .bg-grey {
    background-color: #f7f7f7; }
  .skills-box ul {
    margin: 0 -1.875rem;
    padding: 0; }
    .skills-box ul li {
      list-style: none;
      border-left: 0.9375rem solid #fff;
      border-right: 0.9375rem solid #fff;
      padding: 8.125rem;
      text-align: center; }
      .skills-box ul li h1 {
        font-size: 2.5rem;
        line-height: 3.125rem;
        color: #2a2a2a;
        font-weight: 700;
        font-family: Montserrat, sans-serif;
        width: 100%;
        text-align: center;
        display: inline-block;
        vertical-align: top; }
      .skills-box ul li h2 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #818181;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        width: 100%; }

/* get-in-touch-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.job-form-box {
  padding: 5.9375rem 0;
  background-color: #ffffff;
  width: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  /* custom select */ }
  .job-form-box form {
    text-align: left;
    max-width: 100%;
    margin: 0 auto 3.125rem !important;
    padding: 3.75rem 1.25rem 2.5rem;
    -webkit-box-shadow: 0 0 0.3125rem 0.3125rem rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 0.3125rem 0.3125rem rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem; }
  .job-form-box h1 {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
    color: #2a2a2a;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 0.625rem;
    text-align: left; }
    .job-form-box h1 span {
      color: #42c8f4; }
  .job-form-box h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #818181;
    font-size: 1.1875rem;
    line-height: 1.5rem;
    margin: 0 auto 4.375rem;
    max-width: 100%;
    text-align: left; }
  .job-form-box .gform_body #gform_fields_4 {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden; }
  .job-form-box .gform_body li#field_4_11,
  .job-form-box .gform_body li#field_4_10 {
    visibility: hidden; }
  .job-form-box .gform_body li#field_4_11,
  .job-form-box .gform_body li#field_4_10,
  .job-form-box .gform_body li#field_4_9,
  .job-form-box .gform_body li#field_4_1,
  .job-form-box .gform_body li#field_4_5,
  .job-form-box .gform_body li#field_4_2,
  .job-form-box .gform_body li#field_4_4,
  .job-form-box .gform_body li#field_4_6,
  .job-form-box .gform_body li#field_4_7 {
    padding: 0 0.9375rem;
    margin: 0;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    width: 33.3333%; }
    .job-form-box .gform_body li#field_4_11 input,
    .job-form-box .gform_body li#field_4_10 input,
    .job-form-box .gform_body li#field_4_9 input,
    .job-form-box .gform_body li#field_4_1 input,
    .job-form-box .gform_body li#field_4_5 input,
    .job-form-box .gform_body li#field_4_2 input,
    .job-form-box .gform_body li#field_4_4 input,
    .job-form-box .gform_body li#field_4_6 input,
    .job-form-box .gform_body li#field_4_7 input {
      width: 100%; }
  .job-form-box .gform_body li#field_4_9 .gfield_label {
    background: url(../img/icon-resume.jpg) 50% 100% no-repeat #fff;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 3.125rem auto 1.875rem;
    height: 11.8125rem;
    display: block;
    text-indent: -999999px; }
  .job-form-box .gform_body #field_1_6 .ginput_container_email {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding-right: 1rem;
    margin-right: -0.25rem; }
  .job-form-box .gform_body .gfield_label {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    display: inline-block;
    vertical-align: top;
    color: #2a2a2a;
    margin: 0.5rem 0rem;
    padding: 0; }
  .job-form-box .gform_body input {
    width: 100%;
    padding: 1.25rem 1.875rem;
    margin: 0 0 0.9375rem; }
  .job-form-box .gform_body .top_label div.ginput_complex.ginput_container.gf_name_has_1,
  .job-form-box .gform_body .top_label div.ginput_complex.ginput_container.gf_name_has_2,
  .job-form-box .gform_body .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
    width: auto !important; }
  .job-form-box .gform_body li#field_4_9 input,
  .job-form-box .gform_body li#field_4_1 input,
  .job-form-box .gform_body li#field_4_5 input,
  .job-form-box .gform_body li#field_4_2 input,
  .job-form-box .gform_body li#field_4_4 input,
  .job-form-box .gform_body li#field_4_6 input,
  .job-form-box .gform_body li#field_4_7 input {
    width: 100%;
    border: 0;
    background: #f7f7f7;
    padding: 1.25rem 1.875rem;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    font-family: 'Raleway', sans-serif;
    color: #b0b0b0;
    border-radius: 0.3125rem;
    margin-bottom: 0.8125rem; }
  .job-form-box .gform_body li#field_4_9 {
    min-height: 21.875rem;
    border: 0.0625rem dashed #42c8f4;
    position: absolute;
    background-color: #ffffff;
    z-index: 987;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0; }
  .job-form-box .gform_body li#field_4_9 input {
    padding: 1.125rem 1.875rem 1.1875rem; }
  .job-form-box .gform_body .gfield_error label {
    padding: 0.3125rem 0.625rem !important; }
  .job-form-box .ginput_container.ginput_container_select {
    width: 100%;
    border: 0;
    padding: 0;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    font-family: 'Raleway', sans-serif;
    color: #b0b0b0;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    background: #f7f7f7 url(../img/icon-select.jpg) 100% 50% no-repeat; }
    .job-form-box .ginput_container.ginput_container_select .gfield_select {
      width: 120%;
      background: transparent;
      border: 0 none;
      padding: 1.1875rem 1.875rem; }
  .job-form-box .gform_wrapper .validation_message {
    padding: 0.3125rem 0.625rem !important; }
  .job-form-box .gform_footer.top_label {
    text-align: left;
    padding: 0.9375rem; }
    .job-form-box .gform_footer.top_label #gform_submit_button_4 {
      border-radius: 1.5625rem;
      margin-right: 0;
      margin-left: 0;
      padding: 0.625rem 1.5625rem;
      background: #42c8f4;
      color: #fff;
      font-size: 0.9375rem;
      line-height: 1.6875rem;
      text-transform: uppercase;
      font-weight: 600;
      border: 0 none;
      display: inline-block;
      vertical-align: top;
      font-family: 'Raleway', sans-serif; }
      .job-form-box .gform_footer.top_label #gform_submit_button_4:hover {
        background: #1a1a1a;
        color: #fff; }
  .job-form-box .gform_wrapper.gform_validation_error .top_label select.medium {
    width: 120%; }
  .job-form-box .gform_body .ginput_container.ginput_container_fileupload {
    position: relative;
    z-index: 1;
    display: block;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    margin: 0 auto;
    min-height: 21.875rem; }
  .job-form-box .gform_body .gfield .ginput_container #input_4_9 {
    position: absolute;
    z-index: 976;
    right: 0;
    top: 0;
    text-align: left;
    width: 100%;
    min-height: 21.875rem;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background-color: red; }
  .job-form-box .gform_body .theSpanFile {
    height: 2.5rem;
    width: 7.5rem;
    border-radius: 1.875rem;
    background-color: #42c8f4;
    padding: 0.625rem 0.9375rem;
    position: absolute;
    z-index: 9;
    right: 0;
    top: 0;
    text-align: center;
    cursor: pointer;
    font-family: Raleway, sans-serif;
    color: #fff;
    font-size: 0.9375rem;
    line-height: 1.375rem;
    text-transform: uppercase;
    font-weight: bold; }
  .job-form-box .gform_body .theBtnFile {
    height: 40px;
    width: 100%;
    border-radius: 0;
    background: url(../img/icon-pdf.png) 0 50% no-repeat #fff;
    display: block;
    z-index: 9;
    overflow: hidden;
    padding: 0.625rem 0.9375rem 0 2.5rem;
    cursor: pointer;
    bottom: 2.5rem;
    position: absolute;
    max-width: 17.5rem;
    left: 50%;
    margin-left: -8.75rem;
    color: #9c9c9c;
    font-style: italic;
    font-size: 0.875rem;
    line-height: 1rem;
    opacity: 0; }
    .job-form-box .gform_body .theBtnFile p {
      display: block;
      width: 100%;
      overflow: hidden;
      height: 1.25rem;
      line-height: 1.5rem; }
  .job-form-box .gform_body #close-delete {
    background: url(../img/icon-delete.png) no-repeat #fff 50% 50%;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    overflow: hidden;
    text-indent: -99999px;
    position: absolute;
    z-index: 987;
    bottom: 3.125rem;
    right: 2.5rem;
    border-radius: 100%;
    opacity: 0; }
  .job-form-box .gform_body .ginput_preview {
    position: absolute;
    z-index: 987;
    bottom: 0.9375rem;
    padding: 0 1.25rem; }
    .job-form-box .gform_body .ginput_preview .gform_delete {
      display: none; }
  .job-form-box .gform_body .gform_confirmation_wrapper {
    width: 100%;
    padding: 0.9375rem;
    border: 0.0625rem dashed #42c8f4;
    text-align: center; }

/* cura-network-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.cura-network-box {
  background: #ffffff;
  padding: 0 3.125rem;
  margin: 3.125rem auto; }
  .cura-network-box .cura-network-box-container {
    background: #5bcdf0; }
  .cura-network-box h1 {
    color: #ffffff; }
  .cura-network-box .the-desc {
    color: #ffffff; }
    .cura-network-box .the-desc p {
      color: #ffffff; }
  .cura-network-box .btn-default {
    margin-top: 1.5625rem; }

.experience-box {
  padding: 0; }
  .experience-box .experience-box-container {
    padding: 3.4375rem 0 2.1875rem 0;
    background: #f7f7f7;
    width: auto;
    margin: 0 3.125rem 3.125rem; }
    .experience-box .experience-box-container .experience-repeater {
      text-align: center;
      margin: 5.625rem auto 0; }
      .experience-box .experience-box-container .experience-repeater.experience-repeater-white ul li h1 {
        color: #ffffff; }

.experience-box-generic .equipment-repeater ul li h1 {
  color: #ffffff !important; }

.experience-box-generic .equipment-repeater ul li .the-desc {
  color: #ffffff !important; }
  .experience-box-generic .equipment-repeater ul li .the-desc p {
    color: #ffffff !important; }

.experience-box-generic .equipment-repeater ul li:last-child h1 {
  color: #ffffff !important; }

.experience-box-generic .equipment-repeater ul li:last-child .the-desc {
  color: #ffffff !important; }
  .experience-box-generic .equipment-repeater ul li:last-child .the-desc p {
    color: #ffffff !important; }
  .experience-box-generic .equipment-repeater ul li:last-child .the-desc a {
    color: #ffffff !important; }
    .experience-box-generic .equipment-repeater ul li:last-child .the-desc a:hover {
      color: #ffffff !important; }

/* contact-us-services */
/*-------------------------------------------------------------------------------------------------------------------*/
.contact-us-services {
  margin: 0 3.125rem; }

.row2 {
  margin-right: -1.25rem;
  margin-left: -1.25rem; }

/* newHomeSlider */
/*-------------------------------------------------------------------------------------------------------------------*/
.newHomeSlider {
  background: #000000; }
  .newHomeSlider #demo_SLIDER_HOMEPAGE {
    height: 100%; }
    .newHomeSlider #demo_SLIDER_HOMEPAGE .col-md-8 {
      padding-top: 7.1875rem; }
    .newHomeSlider #demo_SLIDER_HOMEPAGE .col-md-4 {
      padding-top: 7.1875rem; }
    .newHomeSlider #demo_SLIDER_HOMEPAGE .carousel-inner {
      height: 100%; }
      .newHomeSlider #demo_SLIDER_HOMEPAGE .carousel-inner .carousel-item {
        height: 100%; }
      .newHomeSlider #demo_SLIDER_HOMEPAGE .carousel-inner .container-small {
        height: 100%; }
  .newHomeSlider .hero-part-text {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/* mastheadSLIDER */
/*-------------------------------------------------------------------------------------------------------------------*/
.carousel-indicators {
  margin-bottom: 0;
  padding-bottom: 0;
  right: 0;
  left: 0;
  z-index: 9876;
  display: none; }

.mastheadSLIDER {
  background: #000000;
  max-height: 37.5rem;
  min-height: 37.5rem;
  display: none; }
  .mastheadSLIDER #demoSLIDER_SHIPPER {
    max-height: 30.3125rem;
    min-height: 30.3125rem; }
  .mastheadSLIDER .carousel-inner {
    max-height: 30.3125rem;
    min-height: 30.3125rem; }
    .mastheadSLIDER .carousel-inner .carousel-item {
      max-height: 30.3125rem;
      min-height: 30.3125rem; }
      .mastheadSLIDER .carousel-inner .carousel-item img {
        width: 100%;
        max-height: 30.3125rem;
        min-height: 30.3125rem; }

/* remove breadcrumbs */
/*------------------------------------------------------------------------------------------------------------------*/
.page-template-page-shipper .breadcrumb-box,
.home.page-template .breadcrumb-box {
  display: none !important; }

/* background-sky-network */
/*------------------------------------------------------------------------------------------------------------------*/
.background-sky-network {
  background: url("../img/home/about-home-bg.png") 50% 100% no-repeat;
  padding-bottom: 10rem;
  width: 100%;
  overflow: hidden;
  display: block;
  padding-top: 5.3125rem;
  margin-bottom: 0rem;
  position: relative;
  top: -3.75rem;
  z-index: 9;
  min-height: 34.375rem;
  background-size: 107.1875rem 125rem; }
  .background-sky-network.less-padding {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem; }

/* black-sky-img-bg */
/*------------------------------------------------------------------------------------------------------------------*/
.black-sky-img-bg {
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  padding-top: 0;
  top: 0;
  z-index: 9; }

.white-bg {
  background: #ffffff; }

.diagonal-bg {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  max-width: 107.1875rem;
  margin: 0 auto; }
  .diagonal-bg .diagonal-bg-top {
    background: url("../img/black-bg.png") 50% 0 no-repeat !important;
    display: block;
    width: 100%;
    overflow: hidden;
    height: 6.625rem; }
  .diagonal-bg .diagonal-bg-center {
    background-color: #393939;
    padding-bottom: 50px; }
  .diagonal-bg .diagonal-bg-bot {
    background: url("../img/black-bg-bot.png") 50% 100% no-repeat !important;
    background-size: cover !important;
    width: 100%;
    overflow: hidden;
    display: block;
    height: 16rem;
    background-color: transparent;
    padding: 0;
    text-align: center; }
    .diagonal-bg .diagonal-bg-bot img {
      width: 100%;
      height: auto; }

/* our-mode-compax */
/*------------------------------------------------------------------------------------------------------------------*/
.our-modes-container {
  position: relative;
  z-index: 97; }
  .our-modes-container .the-desc {
    max-width: 56.25rem;
    padding: 0;
    width: 100%;
    text-align: center;
    margin: 0 auto; }
    .our-modes-container .the-desc h4.subtitle {
      text-align: center;
      padding: 0;
      color: #818181;
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin: 0 auto 2.8125rem;
      width: 100%;
      max-width: 56.25rem;
      padding-left: 2.5rem; }
  .our-modes-container.our-modes-container-mask {
    position: relative;
    z-index: 1;
    margin-top: -37rem;
    overflow: hidden; }
  .our-modes-container .our-modes-full {
    padding-bottom: 0rem;
    background-size: contain;
    overflow: hidden;
    margin-top: 0rem; }
    .our-modes-container .our-modes-full .mask-modes-container {
      background: url("../img/home/modes-home-bg.png") 50% 50% no-repeat;
      background-attachment: fixed !important;
      background-position: center !important;
      background-repeat: repeat !important;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 37.0625rem; }
      .our-modes-container .our-modes-full .mask-modes-container .mask-modes {
        background: url("../img/mask-modes.png") no-repeat !important;
        background-size: cover !important;
        background-repeat: repeat !important;
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: 37.0625rem; }
    .our-modes-container .our-modes-full .our-modes-ul {
      position: relative;
      z-index: 97; }
    .our-modes-container .our-modes-full .our-mode-compax h1 {
      font-size: 4.5rem;
      line-height: 6.25rem;
      color: #2A2A2A;
      font-weight: 900;
      font-family: Raleway, sans-serif;
      text-align: center;
      padding-bottom: 0.625rem;
      margin-top: 0; }

/* free-quote-form-box */
/*------------------------------------------------------------------------------------------------------------------*/
.gform_wrapper ul.gform_fields li.gfield {
  list-style: none !important; }

.free-quote-form-box {
  width: 28.125rem;
  height: auto;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 40rem;
  right: 20.625rem;
  z-index: 987;
  text-align: left; }
  .free-quote-form-box .gform_wrapper ul.gform_fields li.gfield {
    padding-right: 0; }
  .free-quote-form-box .free-quote-form-box-bg-1 {
    background: url(../img/home/free-quote-bg.png) 50% 0 no-repeat;
    width: 28.125rem;
    height: auto;
    display: block;
    overflow: hidden;
    text-align: left;
    padding: 0.9375rem 0;
    background-size: 28.125rem 75rem; }
  .free-quote-form-box .free-quote-form-box-bg-2 {
    background: url(../img/home/free-quote-bg-part-2.png) 50% 0 no-repeat;
    max-width: 28.125rem;
    height: 1.1875rem;
    width: 100%;
    display: block;
    overflow: hidden;
    text-align: left;
    background-size: 28.125rem 1.1875rem; }
  .free-quote-form-box h1 {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    color: #33CCFF;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    padding-bottom: 0rem;
    max-width: 23.5rem; }
  .free-quote-form-box .gform_wrapper {
    width: 100%;
    max-width: 23.125rem;
    margin: 0 auto; }
    .free-quote-form-box .gform_wrapper ul li.gfield {
      margin-top: 0.6875rem !important; }
    .free-quote-form-box .gform_wrapper form {
      text-align: left;
      width: 100%;
      margin: 0;
      max-width: 20.125rem; }
    .free-quote-form-box .gform_wrapper .gfield_required {
      color: #3CF !important; }
    .free-quote-form-box .gform_wrapper .gform_footer {
      padding: 0.625rem 0 0.625rem;
      margin: 0.625rem 0 0;
      clear: both;
      width: 100%;
      text-align: center;
      max-width: 20.125rem; }
      .free-quote-form-box .gform_wrapper .gform_footer input {
        width: 100%;
        max-width: 12.625rem;
        min-width: 12.625rem;
        font-size: 1.5rem !important;
        line-height: 1.8125rem !important;
        color: #ffffff;
        font-weight: 500;
        border: 0 none;
        border-radius: 1.5625rem;
        margin-right: 0;
        margin-left: 0;
        padding: .625rem 1.5625rem;
        background: #3CF;
        text-transform: none;
        display: inline-block;
        vertical-align: top;
        font-family: Roboto, sans-serif; }
        .free-quote-form-box .gform_wrapper .gform_footer input:hover {
          background: #014F86 !important; }
    .free-quote-form-box .gform_wrapper .top_label div.ginput_container {
      margin-top: 0.3125rem; }
    .free-quote-form-box .gform_wrapper #input_7_16_3_container label {
      display: none; }
    .free-quote-form-box .gform_wrapper label {
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: 500;
      color: #3CF;
      font-family: 'Roboto', sans-serif !important;
      padding-bottom: 0;
      margin-bottom: 0; }
    .free-quote-form-box .gform_wrapper input {
      border-radius: 0.625rem;
      min-height: 3rem;
      height: 3rem;
      border: 0.0625rem solid #707070;
      width: 100%;
      max-width: 20.125rem;
      padding: 0 0.625rem !important;
      font-family: 'Raleway', sans-serif !important;
      color: #b0b0b0;
      background: #ffffff;
      outline: none; }
    .free-quote-form-box .gform_wrapper .top_label input.medium,
    .free-quote-form-box .gform_wrapper .top_label select.medium {
      border-radius: 0.625rem;
      min-height: 3rem;
      height: 3rem;
      border: 0.0625rem solid #707070;
      width: 100% !important;
      max-width: 20.125rem;
      line-height: 3rem;
      padding: 0.875rem 0.625rem 0.75rem 0.625rem;
      font-family: 'Raleway', sans-serif !important;
      color: #b0b0b0;
      background: #ffffff;
      outline: none; }

/* mapeado */
/*------------------------------------------------------------------------------------------------------------------*/
.tab-pane .mapeado {
  width: 100%;
  max-width: 107.1875rem;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 987;
  top: 0;
  left: 0; }
  .tab-pane .mapeado .point_style img {
    width: 1.75rem;
    height: 2.375rem; }

/* nav-tabs-shipper */
/*------------------------------------------------------------------------------------------------------------------*/
.nav-tabs-shipper {
  padding: 0.3125rem;
  position: relative;
  z-index: 1050; }
  .nav-tabs-shipper .nav-item {
    border-radius: 0;
    margin: 0 2.1875rem; }
    .nav-tabs-shipper .nav-item .nav-link {
      border-radius: 0;
      min-width: 18.75rem;
      background-color: transparent;
      width: 100%;
      height: 3.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border: 0 none;
      font-size: 1.75rem;
      line-height: 2.4375rem;
      color: #818181;
      font-weight: 900;
      font-family: Raleway, sans-serif;
      text-transform: none; }
      .nav-tabs-shipper .nav-item .nav-link span {
        background: url("../img/shipper/icon-marker-gris.png") 50% 0 no-repeat;
        background-size: 1.75rem 2.375rem;
        width: 1.75rem;
        height: 2.375rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin: 0 0.9375rem; }
      .nav-tabs-shipper .nav-item .nav-link.active {
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        color: #33CCFF; }
        .nav-tabs-shipper .nav-item .nav-link.active span {
          background: url("../img/shipper/icon-marker-sky.png") 50% 0 no-repeat;
          background-size: 1.75rem 2.375rem; }
      .nav-tabs-shipper .nav-item .nav-link:hover {
        cursor: pointer;
        opacity: 0.75; }

#footer .menu-item-34 {
  display: none !important; }

.footer-logo a {
  width: 16.5625rem;
  display: block; }

/* horizontal-box-right-container-2 */
/*------------------------------------------------------------------------------------------------------------------*/
.horizontal-box-right-container-2 {
  background-size: 120rem 51.875rem !important; }

.horizontal-box-repeater-2 {
  max-width: 90.625rem;
  float: right; }

.img-size-284x166px img {
  width: 17.75rem;
  height: auto; }

.img-size-203x203px img {
  width: 12.6875rem;
  height: auto; }

.img-size-330x171px img {
  width: 20.625rem;
  height: auto; }

.img-size-350x240px img {
  width: 21.875rem;
  height: auto; }

.img-size-350x150px img {
  width: 21.875rem;
  height: auto; }

.img-size-300x150px img {
  width: 18.75rem;
  height: auto; }

.img-size-284x166px img {
  width: 17.75rem;
  height: auto; }

.img-fondo-size-1722x1610px {
  background-size: 107.625rem 100.625rem; }

/* free-quote-form-box */
/*------------------------------------------------------------------------------------------------------------------*/
.jobs-template-default.single .free-quote-form-box,
.page-template-page-request .free-quote-form-box,
.single.single-post .free-quote-form-box,
.page.page-id-30 .free-quote-form-box {
  display: none !important; }

.page-id-463 .free-quote-form-box {
  display: none !important; }

.page-id-463 .bg-animation-bot {
  display: none !important; }

/* divider-image */
.divider-image {
  width: 100%;
  display: block;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  z-index: 1;
  margin-top: -23.75rem; }

.diagonal-sky-bg {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  overflow: hidden; }
  .diagonal-sky-bg .diagonal-sky-bg-top {
    background: url("../img/sky-bg.png") 50% 0 no-repeat !important;
    background-size: cover !important;
    display: block;
    width: 100%;
    overflow: hidden;
    height: 5rem; }
  .diagonal-sky-bg .diagonal-sky-bg-center {
    padding-bottom: 1rem;
    background-color: #00ceff !important; }
  .diagonal-sky-bg .diagonal-sky-bg-bot {
    background: url("../img/sky-bg-bot.png") 50% 100% no-repeat !important;
    background-size: cover !important;
    width: 100%;
    overflow: hidden;
    display: block;
    height: 8.75rem; }

#powerTip {
  background-color: #33CCFF;
  text-align: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  font-family: Raleway, sans-serif; }
  #powerTip .box_view_html {
    color: #ffffff;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.375rem;
    max-width: 33.125rem;
    white-space: normal;
    overflow: hidden;
    position: relative; }
    #powerTip .box_view_html p {
      margin-bottom: 0; }

#powerTip.n::before {
  border-top: 0.625rem solid #33CCFF !important; }

#powerTip.s::before {
  border-bottom: 0.625rem solid #33CCFF !important; }

/* header-room */
/*-------------------------------------------------------------------------------------------------------------------*/
.header-room {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  height: auto; }
  .header-room .site-header {
    padding-top: 8.3125rem; }

.slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin: 0.9375rem auto !important;
  outline: none; }
  .slick-dots li {
    outline: none;
    border: 0 none;
    background: none;
    margin: 0 0.3125rem; }
    .slick-dots li::marker {
      content: "";
      outline: none; }
    .slick-dots li button {
      border: 0 none;
      background: #3CF;
      width: 0.3125rem;
      height: 0.625rem;
      text-indent: -8888878px;
      outline: none; }
    .slick-dots li.slick-active button {
      border: 0 none;
      background: #1b1b1b; }

.jobs-list-mobile {
  display: none; }

/* animation */
/*------------------------------------------------------------------------------------------------------------------*/
.marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99999px;
  background-color: transparent;
  overflow: hidden;
  z-index: 1; }

.markerTo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99999px;
  background-color: transparent;
  overflow: hidden;
  z-index: 5; }

.marker .circler {
  visibility: hidden; }

.container-for-animation {
  position: absolute;
  z-index: 9;
  top: 10.625rem;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: block; }

/* page-template-page-blog */
.page-template-page-blog #footer {
  position: relative;
  z-index: 987; }

.page-template-page-blog #footer .bg-animation-bot {
  display: none; }

/* gform_confirmation_wrapper */
.gform_confirmation_wrapper {
  max-width: 20.3125rem;
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: left;
  min-height: 25.625rem; }

/* page-template-page-shipper */
.page-template-page-shipper .horizontal-box-right.horizontal-box-format-center .the-desc.the-desc-shipper {
  margin: 0 auto 4.0625rem;
  max-width: 34.375rem;
  width: 100%;
  padding-top: 0; }

/* BLOQUE-FULL */
.BLOQUE-FULL {
  width: 99.25rem;
  margin: 0 auto;
  overflow: hidden;
  background-color: red;
  height: 0.0625rem; }

/* BLOQUE-POST-LOGO */
.BLOQUE-POST-LOGO {
  width: 73.75rem;
  margin: 0 auto;
  overflow: hidden;
  background-color: green;
  height: 0.0625rem; }

/* BLOQUE-TITLE */
.BLOQUE-TITLE {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background-color: blue;
  height: 0.0625rem; }

/* mascaron */
.mascaronimg {
  background-position: 50% 50%; }
  .mascaronimg .mascaron {
    background: url(../img/mask.png) no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden; }
  .mascaronimg .mascaron-big {
    background: url(../img/mask-big.png) no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden; }

/* fix title specialized service*/
body.postid-107 .hero-part-text h1 {
  font-size: 3.0625rem;
  margin-top: 4.0625rem; }

/* area-manager-tabs */
.area-manager-tabs {
  height: 34.375rem;
  overflow: hidden; }

.testimonials-relationship-manager {
  height: 25.9375rem;
  overflow: hidden; }

.tab-pane.fade {
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out; }

/* BLOQUE-SCW */
/*-------------------------------------------------------------------------------------------------------------------*/
.BLOQUE-SCW .our-mode-compax {
  padding: 0 3.125rem; }

.BLOQUE-SCW .services-box-container {
  padding: 3.125rem 0 0 0; }

.BLOQUE-SCW .mask-modes-container {
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: repeat !important;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 37.0625rem; }
  .BLOQUE-SCW .mask-modes-container .mask-modes {
    background: url("../img/mask-modes.png") no-repeat !important;
    background-size: cover !important;
    background-repeat: repeat !important;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 37.0625rem; }

.BLOQUE-SCW .services-box-container ul li {
  padding-top: 0; }

.BLOQUE-SCW .slides {
  padding: 0;
  margin: 0; }
  .BLOQUE-SCW .slides .slide {
    list-style: none;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .BLOQUE-SCW .slides .slide a.scw_container {
      max-width: 35.9375rem;
      width: 100%;
      height: auto;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      cursor: pointer;
      text-decoration: none; }
      .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
        position: absolute;
        z-index: 987;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 6.25rem; }
      .BLOQUE-SCW .slides .slide a.scw_container h1 {
        font-family: Raleway, sans-serif;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 3.5625rem;
        line-height: 5rem; }
      .BLOQUE-SCW .slides .slide a.scw_container .button {
        background-color: #ffffff;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.375rem;
        border-radius: 1.5625rem;
        text-transform: none;
        padding: 0.375rem 1.5625rem;
        color: #33CCFF;
        text-decoration: none; }
        .BLOQUE-SCW .slides .slide a.scw_container .button:hover {
          background-color: #3C3C3C !important;
          text-decoration: none; }
      .BLOQUE-SCW .slides .slide a.scw_container:hover .button {
        background-color: #3C3C3C !important;
        text-decoration: none; }

/* content_header_box */
.content_header_box {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  .content_header_box.is-fixed, .content_header_box.sticky {
    position: fixed;
    z-index: 98765432;
    -webkit-animation-name: stickySlideDown;
            animation-name: stickySlideDown;
    padding: 0;
    -webkit-transition: none;
    transition: none;
    top: 0;
    background-color: #ffffff !important;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16); }
    .content_header_box.is-fixed .top-of-page, .content_header_box.sticky .top-of-page {
      -webkit-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      display: none; }
    .content_header_box.is-fixed .menu_box_primary .navbar.navbar-expand-xl ul li a, .content_header_box.sticky .menu_box_primary .navbar.navbar-expand-xl ul li a {
      color: #42C8F4 !important; }
      .content_header_box.is-fixed .menu_box_primary .navbar.navbar-expand-xl ul li a:hover, .content_header_box.sticky .menu_box_primary .navbar.navbar-expand-xl ul li a:hover {
        color: #3c3c3c !important; }
    .content_header_box.is-fixed .menu_box_primary .dropdown-menu, .content_header_box.sticky .menu_box_primary .dropdown-menu {
      background-color: white !important; }

@-webkit-keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

/* testimonials-box-area */
/*----------------------------------------------------------------------*/
.testimonials-box-area {
  padding: 0;
  background-color: transparent;
  width: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 987;
  overflow: hidden; }
  .testimonials-box-area h1 {
    font-size: 4.5rem;
    line-height: 6.25rem;
    color: #2A2A2A;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    padding-bottom: 0.625rem;
    max-width: 50rem;
    margin: 0 auto;
    padding-top: 5rem; }
  .testimonials-box-area p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #ffffff;
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 0 auto 3.125rem;
    max-width: 47.5rem; }
  .testimonials-box-area .testimonials-relationship {
    margin: 0;
    padding: 0; }
    .testimonials-box-area .testimonials-relationship ul.responsive-mobile,
    .testimonials-box-area .testimonials-relationship ul.responsive {
      padding: 0 3.75rem;
      margin: 0; }
      .testimonials-box-area .testimonials-relationship ul.responsive-mobile li,
      .testimonials-box-area .testimonials-relationship ul.responsive li {
        list-style: none;
        background-color: transparent;
        margin-bottom: 1.5625rem; }
        .testimonials-box-area .testimonials-relationship ul.responsive-mobile li .t-zone,
        .testimonials-box-area .testimonials-relationship ul.responsive li .t-zone {
          background-color: #fff;
          padding: 0.9375rem 0.9375rem;
          display: block;
          width: 100%;
          text-decoration: none;
          text-align: left;
          position: relative;
          z-index: 1;
          min-width: 19.375rem; }
          .testimonials-box-area .testimonials-relationship ul.responsive-mobile li .t-zone:hover,
          .testimonials-box-area .testimonials-relationship ul.responsive li .t-zone:hover {
            text-decoration: none; }
        .testimonials-box-area .testimonials-relationship ul.responsive-mobile li .t-data,
        .testimonials-box-area .testimonials-relationship ul.responsive li .t-data {
          display: inline-block;
          vertical-align: top;
          -webkit-box-shadow: 0 0 0.3125rem 0rem rgba(0, 0, 0, 0.15);
                  box-shadow: 0 0 0.3125rem 0rem rgba(0, 0, 0, 0.15);
          padding: 1.25rem;
          width: 100%;
          min-height: 21.875rem; }
          .testimonials-box-area .testimonials-relationship ul.responsive-mobile li .t-data h1,
          .testimonials-box-area .testimonials-relationship ul.responsive li .t-data h1 {
            font-family: Raleway, sans-serif;
            font-weight: 700;
            color: #2A2A2A;
            font-size: 2.125rem;
            line-height: 2.375rem;
            margin: 0;
            padding: 0 0 0.625rem; }
          .testimonials-box-area .testimonials-relationship ul.responsive-mobile li .t-data h2,
          .testimonials-box-area .testimonials-relationship ul.responsive li .t-data h2 {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            color: #33CCFF;
            font-size: 1.4375rem;
            line-height: 2.1875rem;
            margin: 0;
            padding: 0 0 0.625rem;
            text-transform: none; }
          .testimonials-box-area .testimonials-relationship ul.responsive-mobile li .t-data p,
          .testimonials-box-area .testimonials-relationship ul.responsive li .t-data p {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            color: #2A2A2A;
            font-size: 1.25rem;
            line-height: 2.1875rem;
            margin: 0;
            padding: 0 0 0.625rem; }
      .testimonials-box-area .testimonials-relationship ul.responsive-mobile .slick-arrow,
      .testimonials-box-area .testimonials-relationship ul.responsive .slick-arrow {
        position: absolute;
        z-index: 987;
        top: 50%; }
      .testimonials-box-area .testimonials-relationship ul.responsive-mobile .slick-prev.slick-arrow,
      .testimonials-box-area .testimonials-relationship ul.responsive .slick-prev.slick-arrow {
        background: url(../img/about/arrow-slider-left.png) 0 0 no-repeat;
        background-size: 2.625rem 2.625rem;
        border: 0;
        width: 2.625rem;
        height: 2.625rem;
        display: inline-block;
        vertical-align: top;
        text-indent: -99999px;
        outline: none;
        left: 0;
        margin-left: 0;
        margin-top: -1.3125rem; }
      .testimonials-box-area .testimonials-relationship ul.responsive-mobile .slick-next.slick-arrow,
      .testimonials-box-area .testimonials-relationship ul.responsive .slick-next.slick-arrow {
        background: url(../img/about/arrow-slider-right.png) 0 0 no-repeat;
        background-size: 2.625rem 2.625rem;
        border: 0;
        width: 2.625rem;
        height: 2.625rem;
        display: inline-block;
        vertical-align: top;
        text-indent: -99999px;
        outline: none;
        right: 0;
        margin-right: 0;
        margin-top: -1.3125rem; }

/* page-template-page-request */
/*-----------------------------------------------------------*/
body.page-template-page-request h1 {
  font-size: 4.5rem;
  line-height: 6.25rem;
  color: #33CCFF;
  font-weight: 900;
  font-family: Raleway, sans-serif;
  text-align: left;
  width: 100%;
  padding-top: 3.125rem; }

body.page-template-page-request .gform_wrapper {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 90.625rem;
  padding: 2.75rem 0; }

body.page-template-page-request ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #333333 !important;
  opacity: 1 !important; }

body.page-template-page-request ::-moz-placeholder {
  /* Firefox 19+ */
  color: #333333 !important;
  opacity: 1 !important; }

body.page-template-page-request :-ms-input-placeholder {
  /* IE 10+ */
  color: #333333 !important;
  opacity: 1 !important; }

body.page-template-page-request :-moz-placeholder {
  /* Firefox 18- */
  color: #333333 !important;
  opacity: 1 !important; }

body.page-template-page-request .gform_wrapper .ginput_container input {
  background: #f7f7f7 !important;
  padding: 1.25rem 1.875rem !important;
  font-size: 1.0625rem !important;
  line-height: 1.5rem !important;
  font-family: Raleway, sans-serif !important;
  color: #333333 !important;
  width: 100% !important;
  height: 4rem !important;
  border: 0.0625rem solid #333333 !important; }

body.page-template-page-request .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield div.ginput_complex span.ginput_left select,
body.page-template-page-request .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield div.ginput_complex span.ginput_right select,
body.page-template-page-request .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
  margin-left: 0rem !important; }

body.page-template-page-request .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_first,
body.page-template-page-request .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_last,
body.page-template-page-request .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_middle {
  padding-top: 0rem !important; }

body.page-template-page-request .gform_wrapper .ginput_container select,
body.page-template-page-request .gform_wrapper .ginput_container .gfield_select {
  background: #f7f7f7;
  padding: 1.25rem 1.875rem;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-family: Raleway, sans-serif;
  color: #333333;
  height: 4rem;
  width: 100% !important;
  outline: none;
  margin: 0;
  border: 0.0625rem solid #333333; }

body.page-template-page-request .gform_wrapper .ginput_container.ginput_container_select {
  border: 0;
  padding: 0;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-family: Raleway, sans-serif;
  color: #333333;
  width: 100% !important;
  overflow: hidden;
  outline: none;
  background: #f7f7f7; }

body.page-template-page-request body .gform_wrapper.gform_wrapper img.ui-datepicker-trigger {
  width: 1.0625rem !important;
  height: 1rem !important;
  display: -moz-inline-stack;
  display: inline-block;
  margin-left: -2.1875rem; }

body.page-template-page-request .gform_wrapper .ginput_container.ginput_container_textarea textarea {
  height: 160px;
  width: 100%;
  background: #f7f7f7;
  padding: 1.25rem 1.875rem;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-family: Raleway, sans-serif;
  color: #333333;
  border: 0.0625rem solid #333333; }

body.page-template-page-request .gform_wrapper li.gfield.gsection {
  width: 100% !important; }

body.page-template-page-request .gform_wrapper .gform_footer .gform_button.button {
  border-radius: 1.5625rem;
  margin-right: 0;
  margin-left: 0;
  padding: 0.625rem 1.5625rem;
  background: #42c8f4;
  color: #fff;
  font-size: 0.9375rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  display: inline-block;
  vertical-align: top;
  font-family: Raleway, sans-serif;
  margin-bottom: 2.8125rem; }

body.page-template-page-request .gform_wrapper .gform_footer .gform_button.button:hover {
  background: #1a1a1a;
  color: #fff; }

body.page-template-page-request .gform_wrapper .ginput_container_radio .gfield_radio li {
  display: inline-block;
  vertical-align: top;
  width: 40%; }

body.page-template-page-request .gform_wrapper .gform_heading .gform_title {
  font-size: 2.8125rem;
  line-height: 3.4375rem;
  color: #2a2a2a;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
  padding-bottom: 0.625rem;
  width: 100%;
  text-align: center; }

body.page-template-page-request .gform_wrapper .gform_heading .gform_description {
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  color: #2a2a2a;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
  padding-bottom: 0.625rem;
  width: 100%;
  text-align: center; }

body.page-template-page-request .gform_wrapper ul.gform_fields li.gfield {
  padding-right: 1rem;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin: 0 -0.125rem;
  margin-bottom: 1.5625rem; }

body.page-template-page-request .ginput_container.ginput_container_date {
  position: relative;
  z-index: 9; }

body.page-template-page-request .ginput_container.ginput_container_date .ui-datepicker-trigger {
  position: absolute;
  z-index: 987;
  top: 25px;
  right: 20px; }

body.page-template-page-request .gform_wrapper.gform_wrapper .gform_footer {
  text-align: center !important; }

body.page-template-page-request .full_container_for_logic {
  width: 100% !important;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  min-height: 110px; }

body.page-template-page-request .full_container_for_logic li.gfield {
  width: 100% !important;
  position: absolute;
  z-index: 987;
  top: 0; }

body.page-template-page-request .gform_wrapper.gform_wrapper .full_container_for_logic {
  min-height: 110px; }

body.page-template-page-request .gform_wrapper.gform_wrapper .full_container_for_logic li.gfield,
body.page-template-page-request .gform_wrapper.gform_wrapper .full_container_for_logic li.gfield label {
  background-color: #ffffff; }

@media (max-width: 767px) {
  body.page-template-page-request .gform_wrapper ul.gform_fields li.gfield {
    padding-right: 16px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 0 -2px;
    margin-bottom: 25px; } }

body.page-template-page-request [type="radio"]:checked,
body.page-template-page-request [type="radio"]:not(:checked) {
  position: absolute !important;
  left: -9999px !important; }

body.page-template-page-request [type="radio"]:checked + label,
body.page-template-page-request [type="radio"]:not(:checked) + label {
  position: relative !important;
  padding-left: 42px !important;
  cursor: pointer !important;
  line-height: 30px !important;
  display: inline-block !important;
  color: #666 !important; }

body.page-template-page-request [type="radio"]:checked + label:before,
body.page-template-page-request [type="radio"]:not(:checked) + label:before {
  content: '' !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 28px !important;
  height: 28px !important;
  border: 1px solid #42c8f4 !important;
  border-radius: 100% !important;
  background: #fff !important; }

body.page-template-page-request [type="radio"]:checked + label:after,
body.page-template-page-request [type="radio"]:not(:checked) + label:after {
  content: '' !important;
  width: 22px !important;
  height: 22px !important;
  background: #42c8f4 !important;
  position: absolute !important;
  top: 3px !important;
  left: 3px !important;
  border-radius: 100% !important;
  -webkit-transition: all 0.2s ease !important;
  transition: all 0.2s ease !important; }

body.page-template-page-request [type="radio"]:not(:checked) + label:after {
  opacity: 0 !important;
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important; }

body.page-template-page-request [type="radio"]:checked + label:after {
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important; }

/* horizontal-box-right */
/*-------------------------------------------------------------------------------------------------------------------*/
.horizontal-box-right {
  width: 100%;
  margin-bottom: 3.125rem; }
  .horizontal-box-right.horizontal-temperature {
    position: relative;
    z-index: 987; }
  .horizontal-box-right.horizontal-dryvan {
    position: relative;
    z-index: 987; }
  .horizontal-box-right h1 {
    font-size: 4.5rem;
    line-height: 6.25rem;
    color: #2a2a2a;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    padding-bottom: 0.625rem;
    text-align: right;
    max-width: 61.25rem;
    display: inline-block;
    vertical-align: top;
    width: 100%; }
  .horizontal-box-right p {
    color: #818181;
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: 0 auto;
    width: 100%;
    text-align: right;
    max-width: 56.25rem;
    display: inline-block;
    vertical-align: top;
    padding-left: 2.5rem; }
  .horizontal-box-right .horizontal-box-repeater {
    text-align: right;
    padding: 6.25rem 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end; }
    .horizontal-box-right .horizontal-box-repeater .horizontal-box-slides {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .horizontal-box-right .horizontal-box-repeater .horizontal-box-slides li {
        list-style: none;
        text-align: center; }
        .horizontal-box-right .horizontal-box-repeater .horizontal-box-slides li h2 {
          color: #818181;
          font-size: 1.5rem;
          line-height: 1.8125rem;
          font-weight: 500;
          font-family: Roboto, sans-serif; }
  .horizontal-box-right.horizontal-box-format-center {
    text-align: center;
    margin-top: -3.75rem; }
    .horizontal-box-right.horizontal-box-format-center h1 {
      text-align: center; }
    .horizontal-box-right.horizontal-box-format-center .horizontal-box-repeater {
      text-align: center;
      min-height: 0.0625rem; }
    .horizontal-box-right.horizontal-box-format-center .horizontal-box-slides {
      width: 100%; }
    .horizontal-box-right.horizontal-box-format-center .the-desc {
      max-width: 56.25rem;
      padding: 0;
      width: 100%;
      text-align: center;
      margin: 0 auto; }
      .horizontal-box-right.horizontal-box-format-center .the-desc p {
        max-width: 56.25rem;
        text-align: center;
        padding: 0; }
    .horizontal-box-right.horizontal-box-format-center .horizontal-box-repeater {
      padding: 4.375rem 3.125rem; }
    .horizontal-box-right.horizontal-box-format-center li {
      list-style: none;
      text-align: center; }

/* the-opendeck */
/*-------------------------------------------------------------------------------------------------------------------*/
.the-opendeck-area {
  width: 100%;
  overflow: hidden; }
  .the-opendeck-area h1 {
    padding-top: 3.125rem !important; }
  .the-opendeck-area.experience-box .experience-box-container {
    min-height: 55.625rem;
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
    max-width: 107.1875rem;
    padding: 0; }
  .the-opendeck-area.equipment-box .equipment-box-container h1 {
    color: #ffffff; }
  .the-opendeck-area.equipment-box .equipment-box-container .the-desc {
    color: #ffffff; }
    .the-opendeck-area.equipment-box .equipment-box-container .the-desc p {
      color: #ffffff; }
  .the-opendeck-area h2 {
    color: #ffffff;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    max-width: 12.75rem;
    margin: 1.5rem auto; }

/* the-other-area */
/*-------------------------------------------------------------------------------------------------------------------*/
.the-other-area h1 {
  font-size: 4.5rem;
  line-height: 6.25rem;
  color: #2a2a2a;
  font-weight: 900;
  font-family: Raleway, sans-serif;
  padding-bottom: 0.625rem;
  text-align: center;
  padding-top: 1.875rem; }

.the-other-area li.the-other-area-slide {
  list-style: none;
  padding-top: 5rem; }
  .the-other-area li.the-other-area-slide .the-other-area-slide-width {
    max-width: 42.5rem;
    margin: 0 auto; }
  .the-other-area li.the-other-area-slide h2 {
    color: #33CCFF;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    max-width: 100%;
    margin: 2rem auto 0;
    text-align: center; }
  .the-other-area li.the-other-area-slide .the-other-area-slideitem {
    padding: 0 2.5rem; }
  .the-other-area li.the-other-area-slide .the-desc {
    color: #818181;
    text-align: center; }
    .the-other-area li.the-other-area-slide .the-desc p {
      color: #818181;
      text-align: center; }

.the-other-area.the-other-area-format {
  margin-top: 5rem; }
  .the-other-area.the-other-area-format li.the-other-area-slide {
    padding-top: 3.75rem; }
  .the-other-area.the-other-area-format h1 {
    color: #ffffff !important;
    padding-top: 2.5rem !important; }
  .the-other-area.the-other-area-format .the-desc {
    max-width: 25rem;
    margin: 0 auto; }
    .the-other-area.the-other-area-format .the-desc p {
      color: #ffffff !important; }
  .the-other-area.the-other-area-format .the-other-area-box-container {
    width: 100%;
    max-width: 107.625rem;
    margin: 0 auto;
    overflow: hidden; }

/* vertical-list-area */
.vertical-list-area li {
  list-style: none; }

.vertical-list-area .vertical-list-area-img {
  padding: 6.25rem 1.25rem; }
  .vertical-list-area .vertical-list-area-img img {
    width: 47.125rem;
    height: auto; }

.vertical-list-area h3 {
  color: #33CCFF;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  font-weight: bold;
  font-family: Roboto, sans-serif;
  max-width: 100%;
  margin: 2.625rem auto 0;
  text-align: left; }

.vertical-list-area h4 {
  color: #818181;
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  max-width: 100%;
  margin: 1.375rem auto 0;
  text-align: left; }

/* horizontal-x4 */
.horizontal-box-right.horizontal-box-format-center.horizontal-x4 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0; }
  .horizontal-box-right.horizontal-box-format-center.horizontal-x4 .horizontal-contenedor {
    max-width: 34.6875rem;
    margin: 0 auto; }
  .horizontal-box-right.horizontal-box-format-center.horizontal-x4 .horizontal-box-repeater {
    margin-bottom: 0;
    padding-bottom: 0; }
  .horizontal-box-right.horizontal-box-format-center.horizontal-x4 .horizontal-box-repeater .horizontal-box-slides li {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
    .horizontal-box-right.horizontal-box-format-center.horizontal-x4 .horizontal-box-repeater .horizontal-box-slides li h2 {
      color: #33CCFF;
      font-size: 1.5rem;
      line-height: 1.8125rem;
      font-weight: 500;
      font-family: Roboto, sans-serif;
      max-width: 100%;
      margin: 2rem auto 0;
      text-align: center; }
    .horizontal-box-right.horizontal-box-format-center.horizontal-x4 .horizontal-box-repeater .horizontal-box-slides li .the-desc {
      color: #818181;
      text-align: center; }
      .horizontal-box-right.horizontal-box-format-center.horizontal-x4 .horizontal-box-repeater .horizontal-box-slides li .the-desc p {
        color: #818181;
        text-align: center; }

/* FOOTER */
/*---------------------------------------------------------------------*/
#footer {
  background: #2B2B2B !important;
  padding-top: 10.625rem;
  padding-bottom: 8.125rem;
  position: relative;
  z-index: 1;
  min-height: 46.875rem; }
  #footer.footer2 {
    position: relative;
    background: none !important;
    z-index: 98765;
    margin-top: -46.875rem;
    min-height: 46.875rem; }
  #footer h1 {
    color: #fff;
    font-size: 1.5rem;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.8125rem;
    text-transform: uppercase;
    text-align: left;
    margin-top: 1.5625rem;
    padding-top: 0; }
  #footer .dropdown-toggle::after,
  #footer .caret,
  #footer .dropdown-menu {
    display: none !important; }
  #footer .navbar,
  #footer ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
    vertical-align: top; }
    #footer .navbar li,
    #footer ul li {
      list-style: none;
      margin: 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #a8a8a8;
      font-size: 1.125rem;
      line-height: 1.375rem;
      padding: 0 0 0.9375rem;
      text-decoration: none; }
      #footer .navbar li a,
      #footer ul li a {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #a8a8a8;
        font-size: 1.125rem;
        line-height: 1.375rem;
        padding: 0 0 0.9375rem;
        text-decoration: none; }
        #footer .navbar li a:hover,
        #footer ul li a:hover {
          text-decoration: none;
          color: #42c8f4; }

.scrollup {
  width: 2.375rem;
  height: 2.375rem;
  position: fixed;
  bottom: 0.625rem;
  right: 0.625rem;
  display: none;
  text-indent: -9999px;
  background: url("../img/arrow-orange-hi.png") no-repeat transparent;
  background-size: 2.375rem 2.375rem;
  opacity: 1;
  outline: 0;
  z-index: 98765432;
  border-radius: 0; }

.desktop-view {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.desktop-view-block {
  display: block;
  width: 100%; }

.mobile-view,
.mobile-view-block {
  display: none; }

.visibility-mobile-view {
  visibility: hidden; }

.img_for_mobile {
  display: none; }

/* IE11 */
/*------------------------------------------------------------------------------------------------------------------*/
_:-ms-fullscreen, :root body .desktop-view-block,
_:-ms-fullscreen, :root body .desktop-view {
  display: block; }

@media (max-width: 1023px) {
  _:-ms-fullscreen, :root body .mobile-view,
  _:-ms-fullscreen, :root body .mobile-view-block {
    display: block; } }

div.lineaLateralDerecha {
  background: url(../img/bg-line-right.png) 0 0 no-repeat transparent;
  display: block;
  width: 100%;
  height: 34px;
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: 987;
  margin-top: -16px; }

/* 1740 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1740px) {
  /* divider-image */
  .divider-image {
    margin-top: -10rem; }
  .background-sky-network {
    margin: 0 auto;
    max-width: 95rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .BLOQUE-FULL {
    width: 91.25rem; }
  .BLOQUE-POST-LOGO {
    width: 81.25rem; }
  .free-quote-form-box {
    right: 12.5rem; }
  .container-small {
    max-width: 84.375rem;
    margin: 0 auto; }
  .services-box-container ul li a.services-box-container-a {
    width: 15.625rem;
    margin: 0 auto; } }

/* 1600 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1600px) {
  .background-sky-network {
    margin: 0 auto;
    max-width: 82.5rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .BLOQUE-FULL {
    width: 78.75rem; }
  .BLOQUE-POST-LOGO {
    width: 62.5rem; }
  .free-quote-form-box {
    right: 11.25rem; }
  .container-small {
    max-width: 71.875rem;
    margin: 0 auto; }
  .services-box-container ul li a.services-box-container-a {
    width: 15.625rem;
    margin: 0 auto; }
  .services-box-container ul li a.services-box-container-a h1.services-box-container-text {
    font-size: 0.875rem; }
  .jobs-list-desktop .responsive-x4 li {
    min-width: 23.25rem; }
  .opportunities-list-box ul li .t-zone-hover .t-data-hover {
    max-width: 9.5rem;
    left: -2.875rem; }
  .BLOQUE-SCW .our-modes-container.our-modes-container-mask {
    margin-top: -21.25rem; }
  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: 2.3125rem;
    line-height: 2.75rem; }
  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: 5.3125rem; } }

/* 1365 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1365px) {
  .background-sky-network {
    margin: 0 auto;
    max-width: 76.25rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .BLOQUE-FULL {
    width: 72.5rem; }
  .BLOQUE-POST-LOGO {
    width: 56.25rem; }
  .free-quote-form-box {
    right: 8.75rem; }
  .container-small {
    max-width: 65.625rem;
    margin: 0 auto; }
  .services-box-container ul li a.services-box-container-a {
    width: 15.625rem;
    margin: 0 auto; }
  .services-box-container ul li a.services-box-container-a h1.services-box-container-text {
    font-size: 0.875rem; }
  .jobs-list-desktop .responsive-x4 li,
  .opportunities-list-box ul li .t-zone,
  .opportunities-list-box ul li .t-zone-hover {
    min-width: 20.25rem; }
  .menu_box_primary .navbar.navbar-expand-xl ul li a {
    padding: .3125rem 0.538rem; }
  .error404 header#masthead, .home.page-template header#masthead {
    max-height: 46.25rem;
    min-height: 46.25rem; }
  .opportunities-list-box ul li:hover .t-zone-hover {
    left: -2.99rem; }
  .opportunities-list-box ul li .t-zone-hover .t-data-hover {
    padding-top: 2.875rem;
    left: -1.875rem; }
  .opportunities-list-box ul li:hover .t-zone-hover {
    left: -3.27rem; }
  .opportunities-list-box ul li .t-zone-hover::before {
    margin-top: -21.125rem;
    margin-left: 11rem;
    min-height: 50em; } }

/* 1280 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1279px) {
  html {
    font-size: 13px; }
  .BLOQUE-SCW .our-modes-container.our-modes-container-mask {
    margin-top: -22.5rem; }
  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: 2.3125rem;
    line-height: 2.75rem; }
  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: 5.3125rem; }
  .jobs-list-desktop {
    display: none !important; }
  .jobs-list-mobile {
    display: block !important; }
  .blog-the-container .entry-date,
  div.marker,
  div.markerTo {
    display: none !important; }
  .blog-the-container .blog-post {
    margin: 0 auto 6.25rem; }
  .free-quote-form-box {
    width: 28.125rem;
    height: auto;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 40rem;
    right: 7.5rem;
    z-index: 987;
    text-align: left; }
  .footer-logo a {
    margin: 0 auto; }
  .jobs-list-desktop .responsive-x4 li {
    min-width: 24.25rem; }
  .opportunities-list-box ul li .t-zone-hover .t-data-hover {
    max-width: 8.5rem; }
  #main-nav.collapse:not(.show) {
    display: block; }
  .opportunities-list-box .cos-area {
    margin: 0; }
  .opportunities-list-box #nav-tabContent {
    margin: 0;
    min-height: 0.0625rem; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: 0;
    border: 0 none;
    min-height: auto;
    max-height: 100%; }
  .opportunities-list-box .slick-list.draggable {
    margin: 0;
    padding: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-data {
    display: none !important; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover::before {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    margin-top: 0;
    margin-left: 0; }
  .opportunities-list-box ul.responsive-x4-mobile .slick-prev.slick-arrow {
    left: 0.625rem; }
  .opportunities-list-box ul.responsive-x4-mobile .slick-next.slick-arrow {
    right: 0.625rem; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover p,
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover h1 {
    text-align: center; }
  .opportunities-list-box .btn-default.btn-apply {
    float: none; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide:hover .t-zone-hover {
    top: 0;
    left: 0; }
  .sidenav .navbar-brand {
    width: 100%; }
    .sidenav .navbar-brand a {
      max-width: 12.5rem; }
  ul.responsive-x4-mobile li.responsive-x4-li {
    padding: 0;
    height: auto;
    min-width: auto;
    margin-right: 0;
    list-style: none; }
  .markerTo {
    display: none; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    min-height: 0.0625rem;
    height: 100%; }
  .opportunities-list-box ul.responsive-x4-mobile li:hover .t-zone-hover {
    min-height: 0.0625rem;
    height: 100vh;
    left: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide img {
    display: block;
    width: 100%; }
  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover .t-data-hover {
    padding-top: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover {
    max-height: 100%; }
  .opportunities-list-box ul {
    padding: 0; }
  .testimonials-box-area .testimonials-relationship ul.responsive-mobile {
    padding: 0;
    margin: 0; }
  /* area-manager-tabs */
  .area-manager-tabs,
  .testimonials-relationship-manager {
    height: 100%; } }

/* 1200 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1200px) {
  .our-modes-container.our-modes-container-mask {
    margin-top: -48rem; }
  .BLOQUE-SCW .our-modes-container.our-modes-container-mask {
    margin-top: -21.25rem; }
  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: 2.3125rem;
    line-height: 2.75rem; }
  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: 5.3125rem; } }

/* 1199 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1199px) {
  .BLOQUE-SCW .our-modes-container.our-modes-container-mask {
    margin-top: -40rem; }
  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: 3.5625rem;
    line-height: 5rem; }
  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: 6.25rem; } }

/* 1170 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1170px) {
  .error404 header#masthead,
  .home.page-template header#masthead {
    max-height: 50.25rem;
    min-height: 50.25rem; }
  .horizontal-box-repeater-2 {
    max-width: 70.625rem; }
  .BLOQUE-SCW .our-modes-container.our-modes-container-mask {
    margin-top: -46.875rem; }
  .BLOQUE-ABOUT {
    margin-top: 15.625rem; } }

/* 1025 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (min-width: 1025px) {
  .mascaronimg {
    background-attachment: fixed !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; }
  header#masthead {
    background-attachment: fixed;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; } }

/* 1024 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {
  .our-modes-container-mask {
    display: none !important; }
  .BLOQUE-OURMODES-stan .container-small-our-modes-bg {
    background: url(../img/home/modes-home-bg-mb.png) 50% 50% no-repeat;
    padding-bottom: 0; }
  .our-modes-container.our-modes-container-mask {
    margin-top: -48rem; }
  .container-small-our-modes-bg {
    padding-bottom: 0;
    width: 100%;
    max-width: 100%; }
  .BLOQUE-SCW .our-mode-compax {
    padding: 0; }
  .BLOQUE-ABOUT {
    margin-top: 0rem; }
  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: 3.5625rem;
    line-height: 5rem; }
  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: 6.25rem; } }

/* 996 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 996px) {
  html {
    font-size: 14px; }
  .blog-the-container .blog-almacen .blog-almacen-content {
    padding: 2.5rem 2.5rem; }
  .blog-the-container .entry-date {
    display: none !important; }
  body .free-quote-form-box {
    right: 0rem !important; }
  .get-in-touch-box-form .gform_wrapper form {
    max-width: 43.75rem; }
  .get-in-touch-box .gform_body li#field_1_5,
  .get-in-touch-box .gform_body li#field_1_6 {
    width: 50%; }
  .get-in-touch-box .gform_body li#field_1_7 {
    width: 100%; }
  .footer-logo a {
    display: block;
    margin: 0 auto; }
  .menu_box_primary .navbar.navbar-expand-xl ul li {
    padding-left: 0;
    padding-right: 0; }
  .navbar-brand a {
    width: 11.563rem;
    display: block; }
  .jobs-list-desktop .responsive-x4 li {
    min-width: 19.25rem; }
  body .divider-image {
    margin-top: 0; }
  .get-in-touch-box .gform_body #field_1_6 .ginput_container_email {
    padding-right: 0;
    margin-right: 0; }
  .services-box-container ul li a.services-box-container-a {
    margin: 0 auto; }
  .our-modes-full {
    background-position: 50% 50% !important;
    padding-bottom: 0; }
  .get-in-touch-box {
    padding-top: 0 !important; }
  /* home */
  .error404 header#masthead,
  .home.page-template header#masthead {
    max-height: 54.25rem;
    min-height: 54.25rem; }
  video.fullscreen-bg__video {
    width: auto;
    height: auto; }
  .opportunities-list-box .cos-area {
    margin: 0; }
  .opportunities-list-box #nav-tabContent {
    margin: 0;
    min-height: 0.0625rem; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: 0;
    border: 0 none;
    min-height: auto;
    max-height: 100%; }
  .opportunities-list-box .slick-list.draggable {
    margin: 0;
    padding: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-data {
    display: none !important; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover::before {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    margin-top: 0;
    margin-left: 0; }
  .opportunities-list-box ul.responsive-x4-mobile .slick-prev.slick-arrow {
    left: 0.625rem; }
  .opportunities-list-box ul.responsive-x4-mobile .slick-next.slick-arrow {
    right: 0.625rem; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover p,
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover h1 {
    text-align: center; }
  .opportunities-list-box .btn-default.btn-apply {
    float: none; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide:hover .t-zone-hover {
    top: 0;
    left: 0; }
  .sidenav .navbar-brand {
    width: 100%; }
    .sidenav .navbar-brand a {
      max-width: 12.5rem; }
  ul.responsive-x4-mobile li.responsive-x4-li {
    padding: 0;
    height: auto;
    min-width: auto;
    margin-right: 0;
    list-style: none; }
  .markerTo {
    display: none; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    min-height: 0.0625rem;
    height: 100%; }
  .opportunities-list-box ul.responsive-x4-mobile li:hover .t-zone-hover {
    min-height: 0.0625rem;
    height: 100vh;
    left: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide img {
    display: block;
    width: 100%; }
  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover .t-data-hover {
    padding-top: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover {
    max-height: 100%; }
  .opportunities-list-box ul {
    padding: 0; }
  .testimonials-box-area .testimonials-relationship ul.responsive-mobile {
    padding: 0;
    margin: 0; }
  /* area-manager-tabs */
  .area-manager-tabs,
  .testimonials-relationship-manager {
    height: 100%; }
  .about-zone .about-slides .about-slide .about-text {
    max-width: 25rem;
    margin: 0 auto; }
  .get-in-touch-box-form .gform_wrapper form {
    max-width: 25rem; }
  .services-box-container ul li {
    margin-bottom: 2.5rem; }
  .equipment-box .equipment-box-container h1 {
    font-size: 3.75rem; }
  .equipment-box.industry-box .equipment-box-container {
    margin: 0; }
  .blog-the-container .the-title-a h1 {
    font-size: 3rem;
    line-height: 3.4rem; }
  .get-in-touch-box-form .gform_wrapper form {
    max-width: 44rem; }
  /* service */
  .mobile-hcenter {
    margin: 0 auto;
    text-align: center; }
    .mobile-hcenter h3 {
      text-align: center !important; }
    .mobile-hcenter h4 {
      text-align: center !important; }
  .mobile-imager,
  .mobile-breaker {
    display: none !important; } }

/* 992 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 900px) {
  html {
    font-size: 12px; }
  .error404 header#masthead,
  .home.page-template header#masthead {
    max-height: 42.25rem;
    min-height: 42.25rem; } }

/* 767 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
  html {
    font-size: 16px; }
  body #footer .social-network-top {
    text-align: center; }
  .container-small-our-modes-bg {
    background: url(../img/home/modes-home-bg-mobile.png) 50% 50% no-repeat;
    padding-bottom: 0; }
  .mobile-view {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .mobile-view-block {
    display: block;
    width: 100%; }
  .desktop-view,
  .desktop-view-block {
    display: none; }
  .top-of-page ul.top_nav,
  .top-of-page .social-network-top {
    text-align: center; }
  .mobile-view-block {
    margin: 0;
    padding: 0; }
  .free-quote-form-box {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    width: 100%;
    padding: 1.5625rem; }
  .get-in-touch-box .gform_body li#field_1_10 #input_1_10 li {
    margin: 0 1.5rem !important; }
  .free-quote-form-box .free-quote-form-box-bg-2,
  .free-quote-form-box .free-quote-form-box-bg-1 {
    margin: 0 auto;
    background: none; }
  .free-quote-form-box .gform_wrapper input,
  .free-quote-form-box h1 {
    max-width: 100%; }
  .free-quote-form-box .gform_wrapper form {
    width: 100%;
    max-width: 100%; }
  .free-quote-form-box .gform_wrapper .top_label input.medium,
  .free-quote-form-box .gform_wrapper .top_label select.medium {
    width: 100% !important;
    max-width: 100%; }
  .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type="submit"],
  .gform_wrapper .gform_page_footer input.button,
  .gform_wrapper .gform_page_footer input[type="submit"] {
    margin: 0; }
  .free-quote-form-box .gform_wrapper .gform_footer {
    width: 100%;
    max-width: 100%; }
  .opportunities-list-box ul li .t-zone-hover .t-data-hover {
    padding-top: 0; }
  .background-sky-network {
    padding-top: 3.125rem;
    margin-bottom: 5.625rem;
    top: 0; }
  .why-us-box h1 {
    text-align: center;
    width: 100%; }
  .why-us-box .the-desc p {
    text-align: center; }
  .the-other-area li.the-other-area-slide {
    padding-top: 0; }
  /* divider-image */
  .divider-image {
    margin-top: -11.25rem; }
  .horizontal-box-right.horizontal-box-format-center {
    margin-top: 0; }
  .our-modes-full {
    padding-bottom: 0; }
  .services-box-container ul li a.services-box-container-a {
    margin-top: 2.8125rem; }
  .horizontal-box-right h1 {
    max-width: 100%;
    width: 100%;
    text-align: center; }
  .horizontal-box-right p {
    padding-left: 0;
    text-align: center; }
  #footer h1 {
    text-align: center; }
  .footer-logo a {
    margin: 0 auto; }
  #footer .navbar li, #footer ul li,
  #footer .navbar li a, #footer ul li a {
    text-align: center; }
  .services-box-container ul li a.services-box-container-a {
    margin: 0 auto; }
  .footer1 {
    display: none !important; }
  #footer.footer2 {
    background: #2B2B2B !important;
    min-height: 82rem;
    margin-top: 0; }
  .nav-tabs-shipper .nav-item {
    margin: 0; }
    .nav-tabs-shipper .nav-item .nav-link {
      min-width: 0.0625rem; }
  .tab-pane .mapeado .point_style img {
    width: 0.875rem;
    height: 1.125rem; }
  .fullscreen-bg__video {
    width: auto;
    height: auto; }
  .about-zone h1 {
    text-align: center; }
  .about-zone h2 {
    text-align: center;
    float: none;
    width: 100%;
    max-width: 100%; }
  .gform_wrapper .gform_footer input.button,
  .gform_wrapper .gform_footer input[type="submit"],
  .gform_wrapper .gform_page_footer input.button,
  .gform_wrapper .gform_page_footer input[type="submit"] {
    margin: 0 auto 1rem !important;
    min-height: 3rem !important; }
  .gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="image"]):not([type="file"]) {
    min-height: 3rem !important; }
  .get-in-touch-box .gform_body li#field_1_5,
  .get-in-touch-box .gform_body li#field_1_6,
  .get-in-touch-box .gform_body li#field_1_7 {
    padding: 0;
    margin: 0;
    width: 100%; }
  .get-in-touch-box .gform_body #field_1_6 .ginput_container_email,
  .get-in-touch-box .gform_body #input_1_5 span {
    width: 100%;
    padding-right: 0;
    margin-right: 0; }
  .blog-the-container .blog-almacen-content .the-title-a h1 {
    margin-top: 0; }
  .blog-the-container .the-title-a h1 {
    font-size: 2.125rem;
    line-height: 2.375rem; }
  .blog-the-container .blog-almacen {
    margin-top: 0 !important; }
  .blog-the-container .blog-image {
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent; }
  .blog-the-container .blog-image::after,
  .blog-the-container .blog-post::after {
    visibility: hidden; }
  .blog-the-container .blog-almacen .blog-almacen-content {
    text-align: center; }
  .blog-the-container .entry-date {
    top: 0;
    margin-top: 0;
    right: inherit;
    left: 0; }
  .our-team-relationship .cos-area-2 {
    padding: 0 0.9375rem; }
  .background-sky-network.less-padding {
    padding-top: 0;
    padding-bottom: 3.25rem; }
  .testimonials-box-area .testimonials-relationship ul.responsive {
    padding: 0;
    margin: 0; }
  .bg-animation,
  .bg-animation-right,
  .bg-animation-bot {
    display: none; }
  .why-us-box .the-desc p {
    text-align: center;
    padding-left: 0;
    max-width: 100%;
    float: none; }
  .opportunities-list-box .cos-area {
    margin: 0; }
  .opportunities-list-box #nav-tabContent {
    margin: 0;
    min-height: 0.0625rem; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: 0;
    border: 0 none;
    min-height: auto;
    max-height: 100%; }
  .opportunities-list-box .slick-list.draggable {
    margin: 0;
    padding: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-data {
    display: none !important; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover::before {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    margin-top: 0;
    margin-left: 0; }
  .opportunities-list-box ul.responsive-x4-mobile .slick-prev.slick-arrow {
    left: 0.625rem; }
  .opportunities-list-box ul.responsive-x4-mobile .slick-next.slick-arrow {
    right: 0.625rem; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover p,
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone-hover h1 {
    text-align: center; }
  .opportunities-list-box .btn-default.btn-apply {
    float: none; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide:hover .t-zone-hover {
    top: 0;
    left: 0; }
  .sidenav .navbar-brand {
    width: 100%; }
    .sidenav .navbar-brand a {
      max-width: 12.5rem; }
  ul.responsive-x4-mobile li.responsive-x4-li {
    padding: 0;
    height: auto;
    min-width: auto;
    margin-right: 0;
    list-style: none; }
  .markerTo {
    display: none; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    min-height: 0.0625rem;
    height: 100%; }
  .opportunities-list-box ul.responsive-x4-mobile li:hover .t-zone-hover {
    min-height: 0.0625rem;
    height: 100vh;
    left: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide img {
    display: block;
    width: 100%; }
  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover .t-data-hover {
    padding-top: 0; }
  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover {
    max-height: 100%; }
  .opportunities-list-box ul {
    padding: 0; }
  .testimonials-box-area .testimonials-relationship ul.responsive-mobile {
    padding: 0;
    margin: 0; }
  /* area-manager-tabs */
  .area-manager-tabs,
  .testimonials-relationship-manager {
    height: 100%; }
  .about-zone .about-slides .about-slide .about-text {
    max-width: 25rem;
    margin: 0 auto; }
  .get-in-touch-box-form .gform_wrapper form {
    max-width: 25rem; }
  .services-box-container ul li {
    margin-bottom: 2.5rem; }
  .equipment-box .equipment-box-container h1 {
    font-size: 3.75rem; }
  .equipment-box.industry-box .equipment-box-container {
    margin: 0; }
  .about-zone h2 {
    padding-left: 0; }
  /* new design */
  .BLOQUE-SCW .our-mode-compax {
    padding: 0; }
  .header-mobile .navbar-toggler {
    margin-top: 0px; }
  .top-of-page ul.top_nav li {
    list-style: none;
    padding: 5px 0 0 0;
    width: 48%; } }

/* 576 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 576px) {
  html {
    font-size: 14px; }
  .get-in-touch-box-form .gform_wrapper form {
    max-width: 28.5rem; }
  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: 2.125rem;
    line-height: 2.625rem; }
  .BLOQUE-SCW .slides .slide a.scw_container {
    height: 15.875rem; }
  .BLOQUE-SCW .our-mode-compax {
    padding: 0; } }

/* 479 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 479px) {
  html {
    font-size: 12px; }
  .BLOQUE-SCW .slides .slide a.scw_container {
    width: 26.5rem;
    height: 15.625rem; } }

/* 374 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 374px) {
  html {
    font-size: 11px; } }

/* 359 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 359px) {
  html {
    font-size: 9px; } }
