/* GENERAL */
/*-------------------------------------------------------------------------------------------------------------------*/
html {
  font-size: 16px;
}

body {
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

a {
  outline: none;

  &:hover {
    outline: none;
  }
}

a, button, input {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.visible_hidden {
  visibility: hidden;
}

.cover-image {
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.contain-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.visibility-hidden {
  visibility: hidden;
}

.padding-30-0 {
  padding: rem(30px 0);
}

.padding-30-15 {
  padding: rem(30px 15px);
}

.padding-70-0 {
  padding: rem(70px 0);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
}

.flexbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(500px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flexbox-container-left {
  align-items: start;
}

.flexbox-container-right {
  align-items: end;
}

#content.site-content {
  padding-bottom: 0;
  padding-top: 0;
}

.justify-content {
  justify-content: center;
}

.container {
  max-width: rem(1715px);
}

.container-small {
  max-width: rem(1425px);
  margin: 0 auto;
}

.container-big {
  max-width: rem(1715px);
  overflow: hidden;
  margin: 0 auto;
}

.container.container-full {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

/* HEADER */
/*-------------------------------------------------------------------------------------------------------------------*/

.top-of-page {
  background: #33ccff;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 98;

  .btn_orange_contact_us {
    background-color: #FF9100;
    font-size: rem(18px);
    font-weight: 900;
    line-height: rem(22px);
    border-radius: rem(25px);
    text-transform: uppercase;
    padding: rem(6px 25px);
    color: #ffffff;
    text-decoration: none;

    &:hover {
      background-color: #ffffff !important;
      text-decoration: none;
    }
  }

  ul.top_nav {
    text-align: left;
    margin: 0;
    padding: rem(10px);

    li {
      list-style: none;
      display: inline-block;
      vertical-align: top;
      padding: rem(0 45px 0 0);
      font-size: rem(14px);
      line-height: rem(28px);
      color: #ffffff !important;

      &.text-bold {
        font-weight: bold;
      }

      a {
        font-size: rem(14px);
        line-height: rem(28px);
        color: #ffffff !important;

        &:hover {
          color: #0a0b0d !important;
        }
      }
    }
  }

}

body #footer .social-network-top {
  text-align: left;
  margin: 0;
  padding: rem(10px 0);

  li {
    list-style: none;
    display: inline-block;
    vertical-align: top;
    padding: rem(0 25px 0 0);
    text-align: left;

    a {
      font-size: rem(28px);
      line-height: rem(36px);
      color: #ffffff !important;

      &:hover {
        color: #42C8F4 !important;
      }
    }
  }
}

header#masthead {
  margin-bottom: 0;
  background-color: #151515;
  max-height: rem(768px);
  height: 100%;
  min-height: rem(768px);
  overflow: visible;
  position: relative;
  z-index: 9;
  background-position: 50% 50%;

  &::before {
    content: "";
    background: url('../img/opa-bg.png') 50% 50% repeat !important;
    background-size: cover !important;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    display: block;
    height: 100%;
    opacity: 0.60;
  }

  &.without-bg {
    max-height: rem(135px);
    min-height: rem(135px);
    z-index: rem(987);

    .hero-part-text {
      display: none !important;
    }
  }
}

.home.page-template {
  header#masthead {
    max-height: rem(900px);
    min-height: rem(900px);
  }

  .flexbox-container {
    height: rem(700px);
  }
}

.error404 {
  header#masthead {
    background-color: #0d0d0d;
    max-height: rem(900px);
    min-height: rem(900px);
  }

  .flexbox-container {
    height: rem(700px);
  }
}

.hero-part-btns {
  display: flex;
  align-items: start;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;

  margin: rem(12px 0 0 0);
  padding: 0;

  li {
    list-style: none;
  }
}

.hero-part-text {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  width: 100%;

  h1 {
    color: #ffffff;
    font-size: rem(60px);
    line-height: rem(70px);
    text-transform: none;
    display: inline-block;
    vertical-align: top;
    padding: rem(0 20px);
    margin: rem(0 0 15px 0);
    font-weight: 900;
    text-align: center;
    width: 100%;
  }

  h2 {
    color: #42c8f4;
    font-size: rem(17px);
    line-height: rem(24px);
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0;
    font-weight: bold;
  }

  h3.hero_sub2 {
    font-size: rem(18px);
    line-height: rem(24px);
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    width: 100%;
    max-width: rem(700px);
    margin: 0 auto;
  }

}

.btn-default {
  border-radius: rem(25px);
  display: inline-block;
  vertical-align: top;
  margin-right: rem(15px);
  padding: rem(0 25px);
  background: #1a1a1a;
  color: #ffffff;
  font-size: rem(18px);
  line-height: rem(47px);
  min-width: rem(260px);
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  text-align: center;

  &.btn-default-small {
    text-transform: none;
    min-width: rem(236px);
    text-align: center;

    &:hover {
      background-color: #1a1a1a !important;
      color: #ffffff !important;
      text-decoration: none;
    }
  }

  &:hover {
    background-color: #ffffff;
    color: #1a1a1a;
    text-decoration: none;
  }

  &.skycolor {
    background-color: #42c8f4;

    &:hover {
      background-color: #ffffff;
      color: #1a1a1a;
      text-decoration: none;
    }

    &.blackhover {
      &:hover {
        text-decoration: none;
        background-color: #151515;
        color: #ffffff;
      }
    }
  }

  &.whitecolor {
    background-color: #ffffff;
    color: #5bcdf0;

    &:hover {
      text-decoration: none;
      background-color: #151515;
      color: #ffffff;
    }
  }

  &.btn-see-more {
    float: right;
    font-size: rem(12px);
    padding: rem(6px 20px);
    line-height: rem(18px);
    display: inline-block;
    vertical-align: top;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      background-color: #42c8f4;
      color: #ffffff;
    }
  }

  &.btn-loader {
    background-color: #ffffff;
    color: #2B2B2B;
    text-decoration: none;
    border: rem(2px solid #dddddd);
    margin-bottom: rem(80px);
  }

  &.btn-apply {
    min-width: rem(80px);
    float: right;
    margin-right: 0;
  }

}

button.alm-load-more-btn.more {
  border-radius: rem(25px);
  display: inline-block;
  vertical-align: top;
  margin-right: rem(15px);
  padding: rem(0 25px);
  font-size: rem(18px);
  line-height: rem(47px);
  min-width: rem(260px);
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  text-align: center;
  background: #ffffff !important;
  height: auto;
  color: #2B2B2B;
  text-decoration: none;
  border: rem(2px solid #dddddd);
  margin-bottom: rem(80px);


  &::before {
    left: rem(12px) !important;
    top: rem(2px) !important;
  }

  &:hover {
    text-decoration: none;
    background-color: #2B2B2B !important;
    color: #ffffff;
  }
}

.navbar-brand a {
  width: rem(265px);
  display: block;
}

.content_header_box {
  //border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  background: transparent;
  position: absolute;
  z-index: 987;
  width: 100%;
}

.btn-play {
  background: url('../img/btn-play.png') no-repeat;
  width: rem(138px);
  height: rem(138px);
  display: block;
  text-indent: -99999px;
  overflow: hidden;

  &:hover {
    background-position: rem(-138px 0);
    text-decoration: none;
  }
}

.menu_box_primary {

  #menu-primary-nav {
    padding: 0;
    margin: 0;
  }

  .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.75) !important;
    position: absolute !important;
    border: 0 none;
  }

  .navbar.navbar-expand-xl {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    margin: 0;
    padding: rem(26px 30px 10px);

    ul {
      li {
        display: inline-block;
        vertical-align: top;
        list-style: none;
        padding-left: rem(20px);
        padding-right: rem(20px);

        a {
          color: #ffffff;
          padding: rem(5px 15px);
          font-weight: 500;
          font-size: rem(13px);
          text-transform: uppercase;
          line-height: rem(18px);
          text-decoration: none;

          &:hover {
            outline: none;
            text-decoration: none;
            color: #42c8f4;
          }
        }
      }
    }

  }

  .navbar-contact-us {
    display: inline-block;
    vertical-align: top;
    list-style: none;

    .top_nav {
      display: inline-block;
      vertical-align: top;
      list-style: none;

      margin: 0;
      padding: rem(15px 30px);

      li {
        display: inline-block;
        vertical-align: top;
        list-style: none;
        font-style: italic;
        font-size: rem(15px);
        line-height: rem(22px);
        color: #ffffff;
        font-weight: normal;
        padding: rem(0 20px);

        i {
          padding-right: rem(10px);
        }

        a {
          outline: none;
          font-size: rem(15px);
          line-height: rem(22px);
          color: #ffffff;
          text-decoration: none;
          font-style: italic;
          font-weight: normal;
          cursor: pointer;

          i {
            padding-right: rem(10px);
          }

          &:hover {
            outline: none;
            text-decoration: none;
            color: #42c8f4;
          }
        }

      }

    }
  }

}


/* header-mobile */
/*-------------------------------------------------------------------------------------------------------------------*/
.header-mobile {
  padding: rem(32px 0);
  width: 100%;
  display: none;

  .navbar-toggler {
    background: #fff;
    float: right;
    margin-top: rem(8px);
    border-radius: 100%;
    width: rem(58px);
    height: rem(58px);
  }
}

/* SIDENAV */
/*-------------------------------------------------------------------------------------------------------------------*/
#mySidenav {
  display: none;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 987654;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  overflow-x: hidden;
  -webkit-transition: .5s;
  transition: .5s;
  padding-top: 0;

  .navbar-brand {
    display: block;
    width: 100%;
    min-height: rem(113px);
    border-bottom: rem(1px solid #ddd);

    a {
      padding: rem(32px 20px);
      display: block;
      width: 100%;
      text-decoration: none;
      overflow: hidden;

      &:hover {
        text-decoration: none;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: none;

    li {
      list-style: none;
      margin: 0;
      text-align: center;
      padding: 0;
      border-bottom: rem(1px solid #dddddd);

      a {
        font-size: rem(22px);
        font-weight: bold;
        line-height: rem(32px);
        padding: rem(10px 25px);
        margin: 0;
        display: block;
        text-align: right;
        text-transform: uppercase;
        color: #151515;
        text-decoration: none;

        &:hover {
          text-decoration: none;
          background-color: #42c8f4;
          color: #ffffff;
        }

      }
    }
  }

  .menu-menu-top-container {
    border-top: rem(2px solid #dddddd);
  }

  .footer_social_red {
    color: #636465;
    background: none;
    padding: 0;
    margin: rem(28px);
  }

  .closebtn {
    text-decoration: none;
    text-transform: uppercase;

    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    background: #42c8f4;

    display: block;
    position: absolute;
    margin-left: 0;

    border-radius: rem(100%);
    padding: rem(2px 12px);
    top: rem(43px);
    right: rem(19px);
    font-size: rem(30px);

    width: rem(40px);
    height: rem(40px);
    line-height: rem(35px);

    &:hover {
      text-decoration: none;
      color: #151515;
    }
  }

  #menu-nav-primary .menu-item-316,
  #menu-menu-principal .menu-item-45 {
    display: block;
  }

  #menu-nav-primary,
  #menu-menu-principal {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: rem(20px);
        line-height: rem(24px);
        font-weight: normal;
        padding: rem(8px 8px 8px 32px);
        color: #636465;
        display: block;

        &:hover {
          text-decoration: none;
          color: #ffdb25;
        }

      }
    }

  }

}

/* VIDEO */
/*-------------------------------------------------------------------------------------------------------------------*/
.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: auto;
  background-color: #000;
  min-height: 900px;

  &::before {
    content: "";
    background: url(../img/pattern.png) rgba(0, 0, 0, .25);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

.fullscreen-bg__video {
  width: 100%;
  height: auto;
}

/* banner-with-button */
/*-------------------------------------------------------------------------------------------------------------------*/
.banner-with-button {
  background: #42c8f4;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: rem(74px 15px);

  h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: rem(25px);
    line-height: rem(32px);
    display: inline-block;
    vertical-align: top;
    color: #ffffff;
    margin: rem(8px 20px);
    padding: 0;
  }

  a {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;

    &:hover {
      text-decoration: none;
    }
  }


}

/* ABOUT US */
/*-------------------------------------------------------------------------------------------------------------------*/

.about-zone {

  h1 {
    font-size: rem(72px);
    line-height: rem(100px);
    color: #fff;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    //padding-bottom: rem(10px);
    text-align: left;
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: #ffffff;
    font-size: rem(18px);
    line-height: rem(22px);
    margin: rem(0 auto 25px);
    text-align: left;
    padding-left: rem(25px);
    max-width: 50%;
    float: left;
  }

  .about-slides {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: row;

    .about-slide {
      list-style: none;
      padding: rem(50px 0 30px);

      .about-image {
        width: rem(250px);
        margin: 0 auto;
        margin-bottom: rem(0px);
      }

      .about-text {
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        color: #818181;
        font-size: rem(18px);
        line-height: rem(22px);
        padding: rem(0 30px);
      }

    }

  }

}

/* carrier-shipper-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.img-padding-top {
  padding-top: rem(8px);
}

.carrier-shipper-box {
  text-align: center;

  .cos-area {
    padding: rem(95px 0);
    background: transparent;
    width: auto;
    margin: rem(0 50px);

    h1 {
      font-size: rem(45px);
      line-height: rem(55px);
      color: #2a2a2a;
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      padding-bottom: rem(10px);
    }

    h2 {
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #818181;
      font-size: rem(16px);
      line-height: rem(24px);
      max-width: 600px;
      margin: rem(0 auto 75px);
    }

  }

  .cos-slides-box {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: rem(75px);

      .cos-slides-box-text {
        background: #ffffff;
        padding: rem(50px);
        display: block;
        border-top: rem(38px solid #f7f7f7);
        height: 100%;
      }

      .cover-image {
        overflow: hidden;
        position: relative;
        z-index: 1;
      }

      .border {
        background-color: #ffffff;
        width: 100%;
        height: rem(38px);
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        border: 0 none !important;
        position: relative;
        z-index: 1;

        .border-grey {
          position: absolute;
          z-index: 987;
          bottom: 0;
          right: 0;
          background-color: #f7f7f7;
          height: rem(38px);
          display: block;
          width: 25%;
          border: 0 !important;
        }

        &.border-left {
          .border-grey {
            right: inherit;
            left: 0;
          }
        }
      }
    }

    h1 {
      font-size: rem(35px);
      line-height: rem(45px);
      color: #2a2a2a;
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      padding-bottom: rem(10px);
    }

    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: #9e9f9e;
      font-size: rem(19px);
      line-height: rem(24px);
      margin: rem(0 0 25px 0);
    }

    .read-more {
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      color: #42c8f4;
      font-size: rem(16px);
      line-height: rem(24px);
      text-transform: uppercase;
    }

    .cos-slides-box-a {
      text-decoration: none;


      &:hover {
        text-decoration: none;

        h1, p {
          color: #42c8f4 !important;
        }

        .cover-image {

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: 0;
            background-color: rgba(66, 200, 244, 0.61);
          }
        }
      }

    }

  }

}

/* services-box-container */
/*-------------------------------------------------------------------------------------------------------------------*/
.services-box-container {
  padding: rem(95px 0);
  background: transparent;
  width: auto;
  margin: 0;
  text-align: center;
  position: relative;
  z-index: 9;

  h1 {
    font-size: rem(72px);
    line-height: rem(100px);
    color: #2A2A2A;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    padding-bottom: rem(10px);
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;

    li {
      list-style: none;
      padding-top: rem(15px);

      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;


      &:hover {
        padding-top: rem(0px);
      }

      a.services-box-container-a {
        text-decoration: none;
        background: url('../img/home/modes-home-box.png') 50% 100% no-repeat transparent;
        display: block;
        overflow: hidden;
        background-size: contain;
        position: relative;
        z-index: 1;
        width: rem(280px);
        margin: 0 auto;

        .services-box-container-img {
          border: 0 none;
          background-color: transparent;
          background-size: contain;
          display: flex;
          align-items: center;
          justify-content: center;
          height: rem(300px);
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          padding-top: rem(75px);
          width: rem(250px);
          margin: 0 auto;

          img {
            margin: 0 auto;
            max-width: rem(200px);
          }
        }

        h1.services-box-container-text {
          font-family: 'Roboto', sans-serif;
          color: #fff;
          font-size: rem(18px);
          line-height: rem(22px);
          background-color: #3cf;
          font-weight: 500;
          padding: rem(25px 0);
          text-decoration: none;
          text-transform: uppercase;
          display: block;
          visibility: visible;
          opacity: 1;
          max-width: rem(310px);
          margin: 0 auto;

          -webkit-transition: all 0.25s ease-in-out;
          -moz-transition: all 0.25s ease-in-out;
          -o-transition: all 0.25s ease-in-out;
          transition: all 0.25s ease-in-out;
        }

        .hover {
          display: none;
        }

        .normal {
          display: block;
        }

        &:hover {
          text-decoration: none;

          .normal {
            display: none;
          }

          .hover {
            display: block;
          }

          h1.services-box-container-text {
            color: #ffffff;
            background-color: #10598b;
            -webkit-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
            visibility: visible;
            opacity: 1;
          }

          .services-box-container-img {
            border: 0 none;
            background-color: transparent;
          }

        }
      }

    }
  }

}


/* get-in-touch-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.get-in-touch-box {
  padding: rem(95px 0 95px 0);
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  max-width: rem(1138px);
  text-align: center;
  position: relative;
  z-index: 9;
  overflow: hidden;

  h1 {
    font-size: rem(72px);
    line-height: rem(100px);
    color: #2a2a2a;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    padding-bottom: rem(10px);
    margin-top: 0;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #818181;
    font-size: rem(18px);
    line-height: rem(22px);
    margin: rem(0 auto 70px);
    max-width: rem(760px);
  }

  .gform_wrapper .gform_footer {
    padding: rem(16px 0 10px);
    margin: rem(26px 0 0);
    clear: both;
    width: 100%;
  }

  .gform_body {

    li#field_1_5,
    li#field_1_6,
    li#field_1_7 {
      padding: 0;
      margin: 0;
      list-style: none;

      display: inline-block;
      vertical-align: top;

      width: 33.3333%;

      input {
        width: 100%;
      }
    }

    #field_1_6 .ginput_container_email,
    #input_1_5 span {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding-right: rem(16px);
      margin-right: rem(-4px);
    }

    li#field_1_8 {
      padding: 0;
      margin: 0;
      list-style: none;

      margin-top: rem(25px) !important;

      display: inline-block;
      vertical-align: top;
      max-width: 100% !important;
      width: 100%;
    }

    .gfield_label {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: rem(24px);
      line-height: rem(29px !important);
      display: inline-block;
      vertical-align: top;
      color: #2B2B2B;
      margin: rem(8px 0px);
      padding: 0;
    }

    input {
      width: 100%;
      padding: rem(20px 30px);
      margin: rem(0 0 15px);
      border-radius: rem(5px);
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_1,
    .top_label div.ginput_complex.ginput_container.gf_name_has_2,
    .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
      width: auto !important;
      border-radius: rem(5px);
    }

    li#field_1_10 {
      display: flex;
      flex-direction: row;

      .ginput_container_radio {
        width: 100%;
        margin-bottom: rem(85px);

        ul {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }

      .gfield_label {
        display: none;
      }

      #input_1_10 {
        display: flex;
        flex-direction: row;

        li {
          display: flex;
          flex-direction: row;
          background-color: #ffffff;
          border-radius: 100%;
          width: rem(146px);
          height: rem(146px);
          margin: rem(0 56px) !important;

          &.active {
            -webkit-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .16);
            -moz-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .16);
            box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .16);
          }

          label {
            display: none;
          }

          input {
            width: rem(146px) !important;
            height: rem(146px) !important;
            opacity: 0;
            cursor: pointer;
          }

          &.gchoice_1_10_0 {
            background: url('../img/contact/ico-contact-phone.png') 50% 50% no-repeat transparent;
            background-color: #ffffff;
            background-size: contain;
          }

          &.gchoice_1_10_1 {
            background: url('../img/contact/ico-contact-mail.png') 50% 50% no-repeat transparent;
            background-color: #ffffff;
            background-size: contain;
          }


        }

      }

    }

    li#field_1_5 input, li#field_1_6 input, li#field_1_7 input {
      width: 100%;
      border: 0;
      background: #f7f7f7;
      padding: rem(20px 30px);
      font-size: rem(17px);
      line-height: rem(24px);
      font-family: 'Raleway', sans-serif;
      color: #b0b0b0;
      border-radius: rem(5px);
      min-height: rem(59px);
    }

    .textarea#input_1_8 {
      height: rem(160px);
      width: 100%;
      border: 0;
      background: #f7f7f7;
      padding: rem(20px 30px);
      font-size: rem(17px);
      line-height: rem(24px);
      font-family: 'Raleway', sans-serif;
      color: #b0b0b0;
      border-radius: rem(5px);
      min-height: rem(208px);
    }

    .gfield_error label {
      padding: rem(5px 10px) !important;
    }

  }

  .gform_wrapper .validation_message {
    padding: rem(5px 10px) !important;
  }

  .gform_footer.top_label {
    text-align: center;

    #gform_submit_button_1 {
      border-radius: rem(25px);
      margin-right: 0;
      margin-left: 0;
      padding: rem(10px 25px);
      background: #33CCFF;
      color: #ffffff;
      font-size: rem(24px);
      line-height: rem(29px);
      text-transform: none;
      font-weight: 500;
      border: 0;
      display: inline-block;
      vertical-align: top;
      font-family: 'Roboto', sans-serif;
      min-width: rem(236px);

      &:hover {
        background: #014F86;
        color: #ffffff;
      }
    }

  }

  &.get-in-touch-box-sky {
    .gfield_label {
      color: #ffffff;
    }

    .gform_footer.top_label {
      #gform_submit_button_1 {
        background: #ffffff;
        color: #42c8f4;

        &:hover {
          background: #014F86;
          color: #fff;
        }
      }
    }
  }

}

/* our-team-box-area */
/*-------------------------------------------------------------------------------------------------------------------*/
.our-team-box-area {
  padding: rem(95px 0);
  background: url(../img/about/team-bg.png) 50% 50% no-repeat;
  background-size: cover;
  width: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: rem(50px);

  h1 {
    font-size: rem(45px);
    line-height: rem(55px);
    color: #2a2a2a;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    text-align: left;
    padding: rem(0 0 10px 0);
  }

}


/* breadcrumb-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.breadcrumb-box {
  padding: rem(24px 0 18px);
  background: #f9f9f9;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      color: #d0d0d0;
      font-family: 'Roboto', sans-serif;
      font-size: rem(13px);
      line-height: rem(18px);
      font-weight: 500;
      text-transform: uppercase;
      display: inline-block;
      vertical-align: top;
      padding: rem(0 2px);

      a {
        list-style: none;
        color: #d0d0d0;
        font-family: 'Roboto', sans-serif;
        font-size: rem(13px);
        line-height: rem(18px);
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}


/* opportunities-list-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.jobs-list-desktop {
  .responsive-x4 {
    li {
      padding: 0;
      height: rem(490px);
      min-width: rem(420px);
      margin-right: rem(-154px);
    }

  }
}

.opportunities-list-box {
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 98;

  .opp-bg {
    background: url('../img/about/oportunities-bg.png') 50% 0 no-repeat;
    background-size: cover;
    min-height: rem(1px);
    overflow: hidden;
    width: 100%;
  }

  .cos-area {
    padding: rem(50px 0);
    background: transparent;
    width: auto;
    margin: rem(0 50px);

    .cos-area-2 {

      h1 {
        font-size: rem(45px);
        line-height: rem(55px);
        color: #2a2a2a;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        padding-bottom: rem(10px);
      }

      h2 {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        color: #818181;
        font-size: rem(16px);
        line-height: rem(24px);
        max-width: 600px;
        margin: rem(0 auto 75px);
      }

      .arrow-slider {

        .arrow-slider-left {
          background: url('../img/about/arrow-slider-left.png') 0 0 no-repeat;
          border: 0;
          width: rem(18px);
          height: rem(22px);
          display: inline-block;
          vertical-align: top;
          text-indent: -99999px;
          outline: none;
        }

        .arrow-slider-next {
          background: url('../img/about/arrow-slider-right.png') 100% 0 no-repeat;
          border: 0;
          width: rem(18px);
          height: rem(22px);
          display: inline-block;
          vertical-align: top;
          text-indent: -99999px;
          outline: none;
          left: rem(285px);
        }

      }

    }

  }

  #nav-tabContent {
    min-height: rem(490px);
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin: rem(0 50px);
  }

  .slick-list.draggable {
    margin: rem(0 65px);
    padding: rem(0 60px);
  }

  ul {
    padding: rem(0 0 55px);

    .slick-arrow {
      position: absolute;
      z-index: 987;
      top: 50%;
      margin-top: rem(-21px);
    }

    .slick-prev.slick-arrow {
      background: url(../img/about/arrow-slider-left.png) 0 0 no-repeat;
      background-size: rem(42px 42px);
      border: 0;
      width: rem(42px);
      height: rem(42px);
      display: inline-block;
      vertical-align: top;
      text-indent: -99999px;
      outline: none;
      left: 0;
      margin-left: 0;
    }

    .slick-next.slick-arrow {
      background: url(../img/about/arrow-slider-right.png) 100% 0 no-repeat;
      background-size: rem(42px 42px);
      border: 0;
      width: rem(42px);
      height: rem(42px);
      display: inline-block;
      vertical-align: top;
      text-indent: -99999px;
      outline: none;
      right: 0;
      margin-right: 0;
    }

    li {
      list-style: none;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;

      .the-linker {
        position: relative;
        z-index: 1;
        margin: 0;
        display: block;
        width: 100%;
      }

      h1 {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #515151;
        font-size: rem(19px);
        line-height: rem(24px);
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
      }

      h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #212121;
        font-size: rem(12px);
        line-height: rem(24px);
        font-style: italic;
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
      }

      .t-zone {
        display: inline-block;
        vertical-align: top;
        min-height: rem(640px);
        height: 100%;
        max-height: rem(640px);
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
        min-width: rem(460px);
        transform: rotate(-15deg);
        margin-top: rem(-80px);
        border-left: rem(18px solid #fff);
      }

      .t-zone-hover {
        display: none;
        vertical-align: top;
        min-height: rem(540px);
        height: 100%;
        max-height: rem(540px);
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
        min-width: rem(460px);
        margin-top: rem(0px);
      }

      &:hover {

        .t-zone-hover {
          -webkit-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          position: absolute;
          z-index: 987;
          top: 0;
          left: rem(-72px);
        }

      }

      .t-data {
        padding: rem(22px);
        text-align: center;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        position: absolute;
        z-index: 987;
        bottom: 0;
        left: 0;
        height: 100%;
      }

      .t-zone-hover {

        .t-data-hover {
          margin: 0 auto;
          max-width: rem(200px);
          overflow: hidden;
          padding-top: rem(110px);
          position: relative;
          left: rem(-30px);
        }

        &::before {
          content: "";
          background-color: rgba(0, 0, 0, .8);
          width: 100%;
          height: 100%;
          display: block;
          overflow: hidden;
          position: absolute;
          z-index: -1;
          min-height: rem(600px);
          min-width: rem(460px);
          transform: rotate(-15deg);
          margin-top: rem(-42px);
          margin-left: rem(88px);
        }

        h1 {
          color: #ffffff;
          margin-bottom: rem(10px);
          font-size: rem(24px);
          line-height: rem(28px);
          font-weight: bold;
          font-family: 'Raleway', sans-serif;
          text-align: right;
        }

        p {
          color: #ffffff;
          font-size: rem(14px);
          line-height: rem(18px);
          font-weight: 500;
          font-family: 'Roboto', sans-serif;
          text-align: right;
          margin-bottom: rem(30px);
        }
      }

    }
  }

}

@-moz-document url-prefix() {
  .opportunities-list-box ul li .t-zone-hover::before {
    margin-top: -5.125rem;
    margin-left: 11rem;
  }
}

/* CONTACT US */
/*-------------------------------------------------------------------------------------------------------------------*/
.contact-the-container {
  padding: rem(44px);

  h1 {
    margin-top: 0;
  }

  .nav-contact {
    padding: rem(0 95px);
    margin: rem(50px auto 70px);

    li {
      list-style: none;
      display: inline-block;
      vertical-align: top;
      padding: rem(12px 12px 12px 60px);
      text-align: left;
      min-height: rem(45px);

      &.icon-telephone {
        background: url(../img/contact/icon-email.png) 0 0 no-repeat;
      }

      &.icon-faxphone {
        background: url(../img/contact/icon-fax.png) 0 0 no-repeat;
      }

      &.icon-email {
        background: url(../img/contact/icon-phone.png) 0 0 no-repeat;
      }


    }
  }

}

.contact-us-services {

  .slides-contact-us-services {
    margin: 0;
    padding: 0;

    .slide-contact-us-services {
      margin: rem(0 0 39px 0);
      display: inline-block;
      padding: rem(100px 0);
      background: #42c8f4 url(../img/contact/icon-triangle.png) 50% 0 no-repeat;
      text-align: center;

      .scus-image {
        width: rem(100px);
        height: rem(100px);
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        margin-bottom: rem(25px);
        background-position: 0 0;
      }

      h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #282929;
        font-size: rem(20px);
        line-height: rem(24px);
        margin: rem(0 0 45px 0);
        padding: rem(0 0 36px 0);
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
        position: relative;
        z-index: 1;

        &::after {
          content: '';
          height: 3px;
          background-color: #282929;
          position: relative;
          z-index: 9;
          width: rem(80px);
          display: block;
          overflow: hidden;
          margin: 0 auto;
          top: rem(37px);
        }
      }

      h2 {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: #282929;
        font-size: rem(18px);
        line-height: rem(24px);
        margin: rem(0 0 0 0);
        padding: rem(0 0 10px 0);
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
      }

      a {
        color: #ffffff;
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        font-size: rem(18px);
        line-height: rem(24px);

        &:hover {
          text-decoration: underline;
        }
      }

      &.black-bg {
        background-color: #151515;

        .scus-image {
          background-position: 0 100%;
        }

        h1 {
          color: #ffffff;

          &::after {
            background-color: #ffffff;
          }
        }

        h2 {
          color: #ffffff;
        }

        a {
          color: #42c8f4;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

      }

    }

  }

}


/* ERROR 404 */
/*-------------------------------------------------------------------------------------------------------------------*/
.error404 {

  .image-404-top {
    padding: rem(95px 0);
  }

  .error-page-area {
    position: relative;
    z-index: 9;
    top: rem(-280px);
    padding: 0;
    text-align: center;
    width: 100%;
    max-width: rem(400px);

    h3 {
      color: #fff;
      font-size: rem(14px);
      line-height: rem(18px);
      width: 100%;
      display: block;
      text-align: center;
    }

  }
}


/* blog-the-container */
/*-------------------------------------------------------------------------------------------------------------------*/

blockquote {
  color: #42c8f4;
  border-left: rem(2px solid #ddd);
  position: relative;
  z-index: 9;
  margin-left: rem(64px);
  padding: rem(0 30px 0 30px);

  p {
    color: #42c8f4 !important;
    font-weight: normal;
    font-style: italic;
    font-size: rem(14px);
    line-height: rem(18px);
  }

  &::before {
    content: '';
    background: url(../img/blog/icon-quote.png) no-repeat transparent;
    position: absolute;
    z-index: 9887;
    width: rem(35px);
    height: rem(32px);
    top: 0;
    left: rem(-62px);
    display: inline-block;
    vertical-align: top;
  }
}

.blog-the-container {
  padding: rem(20px 0);
  width: 100%;
  overflow: hidden;
  max-width: rem(1715px);
  margin: 0 auto;
  display: block;
  position: relative;
  z-index: 98;

  .fuller-post {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 987;
    //background: url(../img/blog/sky-bg.png) 100% 0 repeat-y #ffffff;
    //background-size: rem(8px 13px);
  }

  .blog-post {
    position: relative;
    z-index: 9;
    width: 100%;
    max-width: rem(1450px);
    margin: rem(0 0 100px 0);
    overflow: hidden;
    padding-bottom: rem(40px);

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #eee;
      height: rem(40px);
      z-index: 987;
      border-left: rem(72px solid #fff);
    }
  }

  .blog-desc {
    h1 {
      font-size: rem(16px);
      line-height: rem(22px);
    }

    h2 {
      font-size: rem(14px);
      line-height: rem(22px);
    }
  }

  .entry-date {
    position: absolute;
    z-index: 987;
    top: 50%;
    margin-top: rem(-49px);
    right: rem(52px);
    padding-right: rem(15px);
    background: url(../img/news/bg-date.png) no-repeat transparent;
    background-size: rem(181px 98px);
    width: rem(181px);
    height: rem(98px);
    display: block;
    text-align: left;
    padding-left: rem(15px);
    color: #fff;
    font-size: rem(38px);
    line-height: rem(46px);
    font-weight: 500;
    font-family: 'Roboto', sans-serif;

    strong {
      font-weight: 300;
    }
  }

  .blog-image {
    position: relative;
    z-index: 987;
    overflow: hidden;
    box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, .2);

    .the-linker-link {
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
      background-position: 50% 50%;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: rem(-35px);
      left: rem(-2px);
      height: rem(40px);
      width: 110%;
      background: #eee;
      -webkit-transform: rotate(1deg);
      -moz-transform: rotate(1deg);
      transform: rotate(1deg);
      border-left: rem(74px solid #fff);
    }

    img {
      width: 100%;
    }

  }

  .blog-almacen {
    position: relative;
    z-index: 9;
    overflow: hidden;
    background: #eee;
    margin-top: rem(50px) !important;

    .blog-almacen-content {
      padding: rem(20px 40px);
    }

  }

  .the-title-a {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 20px;
    color: #282929;
    text-decoration: none;

    h1 {
      font-family: 'Raleway', sans-serif;
      font-weight: 900;
      font-size: rem(72px);
      line-height: rem(80px);
      color: #2B2B2B;
    }

    &:hover {
      text-decoration: none;

      h1 {
        color: #42c8f4;
        text-decoration: none;
      }

    }

  }

  .blog-desc {
    color: #878787;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(22px);
    margin: rem(0 auto 25px);

    p {
      color: #878787;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: rem(18px);
      line-height: rem(22px);
      margin: rem(0 auto 25px);
    }
  }

  .blog-extra {
    background: 0 none;
    border-top: rem(1px solid #ededed);
    padding: rem(25px 0);
    margin-bottom: rem(25px);
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;

    &.blog-extra-single {
      border-bottom: rem(1px solid #ededed);
    }
  }

  .blog-extra-date {
    background: url(../img/blog/icon-clock.png) no-repeat;
    padding: rem(0 0 0 20px);
    font-size: rem(13px);
    line-height: rem(18px);
    color: #b5b5b5;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    background-size: rem(13px 14px);
    background-position: rem(0 2px);
  }

  .category-list {
    background: url(../img/blog/icon-flag.png) no-repeat;
    background-size: rem(15px 14px);
    background-position: rem(0 5px);
    padding: rem(0 0 0 22px);
    font-size: rem(13px);
    line-height: rem(20px);
    color: #b5b5b5;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;


    a {
      font-size: rem(13px);
      line-height: rem(20px);
      color: #b5b5b5;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      text-decoration: none;
      background: transparent;

      &:hover {
        background: transparent;
        text-decoration: none;
        color: #151515;
      }
    }
  }

  .tag-list {
    background: url(../img/blog/icon-fav.png) no-repeat;
    background-position: rem(0 5px);
    padding: rem(0 0 0 22px);
    font-size: rem(13px);
    line-height: rem(20px);
    color: #b5b5b5;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    background-size: rem(15px 12px);

    a {
      font-size: rem(13px);
      line-height: rem(20px);
      color: #b5b5b5;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: #151515;
      }
    }
  }

}

.wp-pagenavi {
  background: 0 none;
  border-top: rem(1px solid #ededed);
  padding: rem(25px 0);
  margin-bottom: rem(25px);
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: rem(14px);
  line-height: rem(18px);
  color: #b5b5b5;

  a, span {
    text-decoration: none;
    border: rem(1px solid #BFBFBF);
    padding: rem(3px 9px);
    margin: rem(2px);
    border-radius: rem(3px);
  }

  a {
    color: #42c8f4;
    border: rem(1px solid #42c8f4);

    &:hover {
      color: #151515;
    }
  }
}

.share-social-area {
  border-bottom: rem(1px solid #ededed);
  padding: rem(25px 0);
  margin-bottom: rem(25px);
  display: block;
  overflow: hidden;
  width: 100%;

  h3 {
    margin: 0;
    padding: rem(0 0 5px);
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: rem(14px);
    line-height: rem(18px);
    color: #b5b5b5;
  }
}

/* blog-post-single */
/*------------------------------------------------------------------------------------------------------------------*/
.blog-post-single {
  .blog-almacen {
    background: transparent;
  }

  .blog-post::after {
    display: none;
  }

  .entry-date {
    margin-top: 0 !important;
    top: 0 !important;
    right: inherit;
    left: 0;
  }

  .the-linker-link {
    max-height: rem(540px);
  }

  .blog-image {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

}

/* the-post-navigation */
/*------------------------------------------------------------------------------------------------------------------*/
.navigation.post-navigation {
  border-bottom: 1px solid #ededed;
  padding: rem(1px 0 25px);
  margin-bottom: rem(25px);
  width: 100%;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

.post-navigation .nav-previous a, .post-navigation .nav-next a {
  border: rem(1px solid #ddd);
  border-radius: rem(5px);
  display: inline-block;
  padding: rem(5px 10px);
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: rem(14px);
  line-height: rem(18px);
  color: #b5b5b5;

  &:hover {
    text-decoration: none;
    color: #fff !important;
    background: #42c8f4 !important;
  }
}

.post-navigation .nav-next a::before,
.post-navigation .nav-previous a::before {
  margin-right: rem(7px);
}

/* the-sidebar */
/*-------------------------------------------------------------------------------------------------------------------*/
.the-sidebar {
  width: 100%;

  .widget-title {
    color: #282929;
    background: url(../img/blog/icon-border.png) no-repeat 0 100%;
    padding-bottom: rem(15px);
    font-family: 'Montserrat', sans-serif;
    font-size: rem(20px);
    line-height: rem(24px);
    margin-bottom: rem(15px);
  }

  .widget {
    margin: rem(70px 0);

    &.widget_search {
      margin: rem(0 0 70px 0);
    }
  }

  .widget_categories {
    ul {
      margin: 0;
      padding: 0;

      .children {
        margin: rem(15px 0 0 35px);
        padding: 0;

        li {
          border-top: rem(1px solid #e6e5e5);
          border-bottom: rem(0 solid #e6e5e5);
        }
      }

      li {
        list-style: none;
        margin: 0;
        padding: rem(10px 0);
        border-bottom: rem(1px solid #e6e5e5);
        display: inline-block;
        vertical-align: top;
        font-family: 'Roboto', sans-serif;
        font-size: rem(14px);
        color: #151515;
        line-height: rem(20px);
        font-weight: 500;
        width: 100%;

        .nav-link {
          display: inline-block;
          padding: 0;
          vertical-align: top;
          margin: 0;
          font-family: 'Roboto', sans-serif;
          font-size: rem(14px);
          color: #151515 !important;
          line-height: rem(20px);
          font-weight: 500;

          &:hover {
            text-decoration: none;
            color: #42c8f4 !important;
          }
        }
      }
    }
  }

  .search-form {
    background: #f0f0f0;
    padding: 0;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 1;

    label {
      display: inline-block;
      width: 100%;
      padding: 0;
      vertical-align: top;
      overflow: hidden;
      margin: 0;
      position: relative;
      z-index: 1;
    }

    .form-control {
      display: block;
      width: 100%;
      padding: rem(10px 55px 10px 15px);
      font-size: rem(17px);
      line-height: rem(22px);
      font-family: 'Raleway', sans-serif;
      font-weight: normal;
      color: #b0b0b0;
      box-sizing: inherit;
      border-radius: 0;
      border: rem(18px solid #f0f0f0);
    }

    .search-submit {
      background: url(../img/blog/icon-search.png) no-repeat 0 50%;
      padding: rem(0 0 0 22px);
      font-size: rem(13px);
      line-height: rem(20px);
      color: #b5b5b5;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      width: rem(51px);
      height: rem(40px);
      border-radius: 0;
      text-indent: -99999px;
      overflow: hidden;
      top: rem(19px);
      right: rem(3px);
      position: absolute;
      z-index: 987;
    }

  }

  .tagcloud {

    a {
      display: inline-block;
      padding: rem(10px 18px);
      vertical-align: top;
      margin-right: rem(10px);
      font-family: 'Roboto', sans-serif;
      font-size: rem(14px) !important;
      color: #424242 !important;
      line-height: rem(20px) !important;
      font-weight: 400;
      border: rem(1px solid #e4e4e4);
      margin-bottom: rem(12px);


      &:hover {
        text-decoration: none;
        color: #ffffff !important;
        background: #42c8f4 !important;
      }
    }
  }


  #recentcomments {

    .recentcomments {
      font-size: rem(14px);
      line-height: rem(20px);
      color: #c5c5c5;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;

      background: url(../img/blog/icon-comment.png) 0 50% no-repeat;
      padding: rem(5px 0 5px 25px) !important;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      overflow: hidden;

      a {
        display: inline-block;
        padding: 0;
        vertical-align: top;
        margin: 0;
        font-family: 'Roboto', sans-serif;
        font-size: rem(14px);
        color: #151515 !important;
        line-height: rem(20px);
        font-weight: 400;


        &:hover {
          text-decoration: none;
          color: #42c8f4 !important;
        }
      }
    }

  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #b0b0b0 !important;
  opacity: 1 !important;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #b0b0b0 !important;
  opacity: 1 !important;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #b0b0b0 !important;
  opacity: 1 !important;
}

:-moz-placeholder { /* Firefox 18- */
  color: #b0b0b0 !important;
  opacity: 1 !important;
}


/* COMMENTS */
/*-------------------------------------------------------------------------------------------------------------------*/
.comments-title {
  font-family: 'Montserrat', sans-serif;
  font-size: rem(20px);
  line-height: rem(24px);
}

.comment-content.card-block {
  padding: rem(5px 10px);
}

.comment-content.card-block {
  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: #878787;
    font-size: rem(14px);
    line-height: rem(18px);
    font-style: italic;
    margin: rem(0 0 5px);
  }
}

.comment-list {

  .card-header {
    padding: rem(0 10px);
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 0 none;
    font-family: 'Roboto', sans-serif;

    h5 {
      margin-bottom: 0;
      font-family: 'Roboto', sans-serif;
      font-size: rem(14px);
      line-height: rem(18px);
      color: #151515;

      a {
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        color: #151515;
        font-size: rem(14px);
        line-height: rem(18px);
        text-decoration: none;
        font-style: normal;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .fn {
      font-style: normal;
    }

  }

  .card-footer {
    padding: rem(0 10px 25px 0);
    background-color: transparent;
    border-top: 0 none;
  }

  .card {
    border: 0 none;
  }

  .comment {
    border-top: rem(1px solid #ededed);
    padding: rem(25px 0 0);
  }

  .comment-body.media.mb-4 {
    margin-bottom: 0 !important;
  }

  .comment-meta {
    a {
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #878787;
      font-size: rem(14px);
      line-height: rem(18px);
      text-decoration: none;
      font-style: italic;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .comment-reply-link.btn.btn-primary {
    border-radius: rem(25px);
    margin-right: rem(15px);
    padding: rem(7px 25px);
    background: #42c8f4;
    color: #fff;
    font-size: rem(15px);
    line-height: rem(27px);
    text-transform: uppercase;
    font-weight: 600;
    border: 0 none;
    height: rem(42px);

    &:hover {
      background: #151515;
    }
  }

  .comment-awaiting-moderation {
    background: #f0f0f0;
    padding: rem(5px 8px);
    margin: rem(2px 10px);
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: #878787;
    font-size: rem(14px);
    line-height: rem(18px);
    text-decoration: none;
    font-style: normal;
  }

}

.logged-in-as {

  a {
    font-family: Montserrat, sans-serif;
    font-size: rem(14px);
    line-height: rem(24px);
    font-weight: normal;
    font-style: normal;
    color: #42c8f4;
    text-decoration: none;

    &:hover {
      color: #151515;
      text-decoration: none;
    }
  }

}

.comment-respond {
  border: rem(1px dashed #42c8f4);
  padding: rem(35px);
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;

  .comment-form-cookies-consent,
  .comment-notes {
    font-family: 'Open Sans', sans-serif;
    color: #878787;
    line-height: rem(18px);
    font-size: rem(14px);
  }

  .comment-reply-title {
    font-family: Montserrat, sans-serif;
    font-size: rem(20px);
    line-height: rem(24px);
    font-weight: bold;
    font-style: normal;
    margin-top: 0;
  }

  .form-submit {
    margin-bottom: 0;
  }

  #cancel-comment-reply-link,
  .form-submit #commentsubmit {
    border-radius: rem(25px);
    margin-right: rem(15px);
    padding: rem(7px 25px);
    background: #42c8f4;
    color: #fff;
    font-size: rem(15px);
    line-height: rem(27px);
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    text-decoration: none;
    height: rem(42px);
    display: inline-block;
    vertical-align: top;

    &:hover {
      background: #151515;
      text-decoration: none;
    }
  }

  #cancel-comment-reply-link {
    float: right;
    margin-right: 0;
  }

  .comment-form-cookies-consent {
    #wp-comment-cookies-consent {
      margin-right: rem(8px);
    }
  }


  //FORM
  .comment-form-author,
  .comment-form-email,
  .comment-form-url {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin-right: rem(-8px);

    input {
      background-color: #f7f7f7;
      border: 0;
      padding: rem(25px);
      font-family: 'Raleway', sans-serif;
      font-weight: 400;
      color: #b0b0b0;
      font-size: rem(17px);
      line-height: rem(24px);
      border-radius: rem(5px);
      width: 95%;
    }
  }

}

.comment-form {

  .form-control {
    background-color: #f7f7f7;
    border: 0 none;
    padding: rem(25px 25px);
    resize: none;

    font-weight: normal;
    color: #b0b0b0;
    font-size: rem(17px);
    line-height: rem(22px);
    font-family: 'Raleway', sans-serif;

    border-radius: rem(5px);
  }

  .form-allowed-tags,
  div.alert.alert-info {
    display: none;
  }

}

/* search-the-container */
/*-------------------------------------------------------------------------------------------------------------------*/

.search-the-container {
  .post.hentry {
    margin-bottom: 0;
  }

  .entry-title > a {
    color: #151515;
    text-decoration: none;

    &:hover {
      color: #42c8f4;
      text-decoration: none;
    }
  }
}


.search.search-results {

  .jobs.type-jobs,
  .testimonial.type-testimonial {
    display: none !important;
  }

}


/* the-linker */
/*-------------------------------------------------------------------------------------------------------------------*/
.the-linker {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}


/* TABS */
/*-------------------------------------------------------------------------------------------------------------------*/
.nav-tabs {
  border: 0;
  margin-bottom: rem(45px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  .nav-link {
    color: #2a2a2a;
    background-color: transparent;
    border: rem(1px solid #d9d9d9);
    text-transform: uppercase;
    font-weight: 400;
    text-decoration: none;
    font-size: rem(15px);
    line-height: rem(24px);
    font-family: Roboto, sans-serif;
    display: inline-block;
    vertical-align: top;
    border-radius: rem(25px);
    margin: rem(0 5px);
    padding: rem(7px 25px);

    &.active {
      text-decoration: none;
      background-color: #42c8f4;
      color: #ffffff;
      font-weight: 500;
    }
  }

  .nav-item {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-align: center;
  }

}


/* contact-us-maps */
/*-------------------------------------------------------------------------------------------------------------------*/
.contact-us-maps {
  margin: rem(0 0 45px);
  padding: 0;

  p {
    margin: 0;
    padding: 0;
  }

  iframe {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.about-zone-in-contact {
  text-align: center;

  .cos-area-cus {
    padding: rem(95px 0);
    background: #f7f7f7;
    width: auto;
    margin: rem(0 50px);
  }
}


/* CARRIER PAGE */
/*-------------------------------------------------------------------------------------------------------------------*/

.why-us-box {
  background-color: #ffffff;

  h1 {
    font-size: rem(72px);
    line-height: rem(100px);
    color: #fff;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    //padding-bottom: rem(10px);
    text-align: left;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
  }

  .the-desc {
    p {
      color: #fff;
      margin: rem(0 auto 25px);
      text-align: left;
      padding-left: rem(25px);
      max-width: 100%;
      float: left;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: rem(18px);
      line-height: rem(22px);
    }

    a {
      text-decoration: none;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: rem(18px);
      line-height: rem(22px);
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }
  }

}

.equipment-box {
  text-align: center;

  .equipment-box-container {
    padding: rem(0px);
    background: transparent;
    width: auto;
    margin: rem(0 50px);

    h1 {
      font-size: rem(72px);
      line-height: rem(80px);
      color: #2B2B2B;
      font-weight: 900;
      font-family: Raleway, sans-serif;
      padding-bottom: rem(10px);
      padding-top: rem(0px);
    }

    .the-desc {
      max-width: rem(800px);
      margin: rem(0 auto 25px);
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      color: #37CAFB;
      font-size: rem(18px);
      line-height: rem(22px);
    }

    .equipment-repeater {
      text-align: center;
      margin: rem(120px auto 0);

      .white-time h1 {
        text-align: left;
        color: #ffffff !important;
        max-width: 100%;
      }

      .white-time .the-desc {
        text-align: left;
        color: #ffffff !important;
      }

      ul {

        li {
          list-style: none;
          margin-bottom: rem(65px);

          h1 {
            font-size: rem(24px);
            line-height: rem(29px);
            color: #42c8f4;
            font-weight: 700;
            font-family: 'Roboto', sans-serif;
            padding: rem(0 0 5px);
            margin: 0 auto;
            text-align: center;
            max-width: 100%;
            width: 100%;
            display: block;
          }

        }

      }

    }

  }

  &.equipment-box-generic {

    .equipment-box-container {
      .equipment-repeater {
        ul {
          li {

            h1 {
              max-width: 100%;
              text-align: left;
              margin-top: 0;
              padding-top: 0;
            }

            .the-desc {
              font-size: rem(16px);
              line-height: rem(24px);
              color: #42c8f4;
              font-weight: 400;
              font-family: 'Open Sans', sans-serif;
              padding: 0;
              margin: 0 auto;
              text-align: left;
              max-width: 100%;
              width: 100%;
              display: block;
            }

          }
        }
      }
    }

  }
}

.equipment-box.industry-box {

  .equipment-box-container {
    padding: rem(95px 0);

    h1 {
      color: #ffffff;
      padding-top: rem(45px);
    }

    .equipment-repeater {

      ul {

        li {

          h1 {
            padding: rem(30px 0 5px);
          }

        }

      }

    }

  }
}

/* about-pre-zone */
/*-------------------------------------------------------------------------------------------------------------------*/
.about-pre-zone {
  background: #ffffff;
  width: auto;
  margin: rem(50px 50px 50px 50px);
}

.why-us-box-shipper {
  background: url(../img/shipper/shipper-repeater.jpg) 0 0 repeat-x;
  background-size: contain;

  .contain-image {
    padding: rem(90px 0);
  }
}

/* our-modes-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.our-modes-box {
  padding: rem(95px 0);
  background: url(../img/about/team-bg.png) 50% 50% no-repeat;
  background-size: cover;
  width: auto;
  margin: 0 auto;

  h1 {
    font-size: rem(40px);
    line-height: rem(50px);
    color: #2a2a2a;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    padding-bottom: rem(10px);
    width: 100%;
    text-align: center;
    display: inline-block;
    vertical-align: top;
  }

  h2 {
    max-width: rem(800px);
    margin: rem(0 auto 75px);
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: #ffffff;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  .services-box-container {
    padding: 0;
    background: transparent;
    width: auto;
    margin: 0;
    text-align: center;

    ul.row {
      margin: 0;
      padding: 0;
      width: 100%;
    }

  }

}


/* skills-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.why-us-box.why-us-box-white {
  background: #fff;

  h1 {
    span {
      color: #42c8f4;
    }
  }

  .the-desc {
    padding-right: 35%;
  }

}

.skills-box {
  margin: rem(0 35px);

  .bg-grey {
    background-color: #f7f7f7;
  }

  ul {
    margin: rem(0 -30px);
    padding: 0;

    li {
      list-style: none;
      border-left: rem(15px solid #fff);
      border-right: rem(15px solid #fff);
      padding: rem(130px);
      text-align: center;

      h1 {
        font-size: rem(40px);
        line-height: rem(50px);
        color: #2a2a2a;
        font-weight: 700;
        font-family: Montserrat, sans-serif;
        width: 100%;
        text-align: center;
        display: inline-block;
        vertical-align: top;
      }

      h2 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(24px);
        color: #818181;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        width: 100%;
      }
    }
  }

}


/* get-in-touch-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.job-form-box {
  padding: rem(95px 0);
  background-color: #ffffff;
  width: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;

  form {
    text-align: left;
    max-width: 100%;
    margin: rem(0 auto 50px) !important;
    padding: rem(60px 20px 40px);
    box-shadow: rem(0 0 5px 5px rgba(0, 0, 0, .1));
    border-radius: rem(10px);
  }

  h1 {
    font-size: rem(45px);
    line-height: rem(55px);
    color: #2a2a2a;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: rem(10px);
    text-align: left;

    span {
      color: #42c8f4;
    }
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #818181;
    font-size: rem(19px);
    line-height: rem(24px);
    margin: rem(0 auto 70px);
    max-width: 100%;
    text-align: left;
  }


  .gform_body {

    #gform_fields_4 {
      position: relative;
      z-index: 1;
      width: 100%;
      overflow: hidden;
    }

    li#field_4_11,
    li#field_4_10 {
      visibility: hidden;
    }

    li#field_4_11,
    li#field_4_10,
    li#field_4_9,
    li#field_4_1,
    li#field_4_5,
    li#field_4_2,
    li#field_4_4,
    li#field_4_6,
    li#field_4_7 {
      padding: rem(0 15px);
      margin: 0;
      list-style: none;

      display: inline-block;
      vertical-align: top;

      width: 33.3333%;

      input {
        width: 100%;
      }
    }

    li#field_4_9 .gfield_label {
      background: url(../img/icon-resume.jpg) 50% 100% no-repeat #fff;
      position: absolute;
      width: 100%;
      z-index: 1;
      top: 0;
      left: 0;
      margin: rem(50px auto 30px);
      height: rem(189px);
      display: block;
      text-indent: -999999px;
    }

    #field_1_6 .ginput_container_email {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding-right: rem(16px);
      margin-right: rem(-4px);
    }

    .gfield_label {
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
      font-size: rem(17px);
      line-height: rem(24px);
      display: inline-block;
      vertical-align: top;
      color: #2a2a2a;
      margin: rem(8px 0px);
      padding: 0;
    }

    input {
      width: 100%;
      padding: rem(20px 30px);
      margin: rem(0 0 15px);
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_1,
    .top_label div.ginput_complex.ginput_container.gf_name_has_2,
    .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
      width: auto !important;
    }

    li#field_4_9 input,
    li#field_4_1 input,
    li#field_4_5 input,
    li#field_4_2 input,
    li#field_4_4 input,
    li#field_4_6 input,
    li#field_4_7 input {
      width: 100%;
      border: 0;
      background: #f7f7f7;
      padding: rem(20px 30px);
      font-size: rem(17px);
      line-height: rem(24px);
      font-family: 'Raleway', sans-serif;
      color: #b0b0b0;
      border-radius: rem(5px);
      margin-bottom: rem(13px);
    }

    li#field_4_9 {
      min-height: rem(350px);
      border: rem(1px dashed #42c8f4);
      position: absolute;
      background-color: #ffffff;
      z-index: 987;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
    }

    li#field_4_9 input {
      padding: rem(18px 30px 19px);
    }

    .gfield_error label {
      padding: rem(5px 10px) !important;
    }

  }

  .ginput_container.ginput_container_select {
    width: 100%;
    border: 0;
    padding: 0;
    font-size: rem(17px);
    line-height: rem(24px);
    font-family: 'Raleway', sans-serif;
    color: #b0b0b0;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;

    background: #f7f7f7 url(../img/icon-select.jpg) 100% 50% no-repeat;

    .gfield_select {
      width: 120%;
      background: transparent;
      border: 0 none;
      padding: rem(19px 30px);
    }
  }

  .gform_wrapper .validation_message {
    padding: rem(5px 10px) !important;
  }

  .gform_footer.top_label {
    text-align: left;
    padding: rem(15px);

    #gform_submit_button_4 {
      border-radius: rem(25px);
      margin-right: 0;
      margin-left: 0;
      padding: rem(10px 25px);
      background: #42c8f4;
      color: #fff;
      font-size: rem(15px);
      line-height: rem(27px);
      text-transform: uppercase;
      font-weight: 600;
      border: 0 none;
      display: inline-block;
      vertical-align: top;
      font-family: 'Raleway', sans-serif;

      &:hover {
        background: #1a1a1a;
        color: #fff;
      }
    }

  }

  .gform_wrapper.gform_validation_error .top_label select.medium {
    width: 120%;
  }

  /* custom select */
  .gform_body {

    .ginput_container.ginput_container_fileupload {
      position: relative;
      z-index: 1;
      display: block;
      overflow: hidden;
      width: 100%;
      cursor: pointer;
      margin: 0 auto;
      min-height: rem(350px);
    }

    .gfield .ginput_container #input_4_9 {
      position: absolute;
      z-index: 976;
      right: 0;
      top: 0;
      text-align: left;
      width: 100%;
      min-height: rem(350px);
      opacity: 0;
      cursor: pointer;
      padding: 0;
      margin: 0;
      background-color: red;
    }

    .theSpanFile {
      height: rem(40px);
      width: rem(120px);
      border-radius: rem(30px);
      background-color: #42c8f4;
      padding: rem(10px 15px);
      position: absolute;
      z-index: 9;
      right: 0;
      top: 0;
      text-align: center;
      cursor: pointer;
      font-family: Raleway, sans-serif;
      color: #fff;
      font-size: rem(15px);
      line-height: rem(22px);
      text-transform: uppercase;
      font-weight: bold;
    }

    .theBtnFile {
      height: 40px;
      width: 100%;
      border-radius: 0;
      background: url(../img/icon-pdf.png) 0 50% no-repeat #fff;
      display: block;
      z-index: 9;
      overflow: hidden;
      padding: rem(10px 15px 0 40px);
      cursor: pointer;
      bottom: rem(40px);
      position: absolute;
      max-width: rem(280px);
      left: 50%;
      margin-left: rem(-140px);
      color: #9c9c9c;
      font-style: italic;
      font-size: rem(14px);
      line-height: rem(16px);
      opacity: 0;

      p {
        display: block;
        width: 100%;
        overflow: hidden;
        height: rem(20px);
        line-height: rem(24px);
      }
    }

    #close-delete {
      background: url(../img/icon-delete.png) no-repeat #fff 50% 50%;
      display: block;
      width: rem(20px);
      height: rem(20px);
      overflow: hidden;
      text-indent: -99999px;
      position: absolute;
      z-index: 987;
      bottom: rem(50px);
      right: rem(40px);
      border-radius: 100%;
      opacity: 0;
    }

    .ginput_preview {
      position: absolute;
      z-index: 987;
      bottom: rem(15px);
      padding: rem(0 20px);

      .gform_delete {
        display: none;
      }
    }

    .gform_confirmation_wrapper {
      width: 100%;
      padding: rem(15px);
      border: rem(1px dashed #42c8f4);
      text-align: center;
    }

  }
}

/* cura-network-box */
/*-------------------------------------------------------------------------------------------------------------------*/
.cura-network-box {
  background: #ffffff;
  padding: rem(0 50px);
  margin: rem(50px auto);

  .cura-network-box-container {
    background: #5bcdf0;
  }

  h1 {
    color: #ffffff;
  }

  .the-desc {
    color: #ffffff;

    p {
      color: #ffffff;
    }
  }

  .btn-default {
    margin-top: rem(25px);
  }
}

.experience-box {
  padding: 0;

  .experience-box-container {
    padding: rem(55px 0 35px 0);
    background: #f7f7f7;
    width: auto;
    margin: rem(0 50px 50px);

    .experience-repeater {
      text-align: center;
      margin: rem(90px auto 0);

      &.experience-repeater-white {
        ul li h1 {
          color: #ffffff;
        }
      }

    }

  }

}

.experience-box-generic {

  .equipment-repeater {

    ul {

      li {

        h1 {
          color: #ffffff !important;
        }

        .the-desc {
          color: #ffffff !important;

          p {
            color: #ffffff !important;
          }
        }

        &:last-child {

          h1 {
            color: #ffffff !important;
          }

          .the-desc {
            color: #ffffff !important;

            p {
              color: #ffffff !important;
            }

            a {
              color: #ffffff !important;

              &:hover {
                color: #ffffff !important;
              }
            }
          }

        }

      }

    }


  }


}

/* contact-us-services */
/*-------------------------------------------------------------------------------------------------------------------*/
.contact-us-services {
  margin: rem(0 50px);
}

.row2 {
  margin-right: rem(-20px);
  margin-left: rem(-20px);
}

/* newHomeSlider */
/*-------------------------------------------------------------------------------------------------------------------*/
.newHomeSlider {
  background: #000000;

  #demo_SLIDER_HOMEPAGE {
    height: 100%;

    .col-md-8 {
      padding-top: rem(115px);
    }

    .col-md-4 {
      padding-top: rem(115px);
    }

    .carousel-inner {
      height: 100%;

      .carousel-item {
        height: 100%;
      }

      .container-small {
        height: 100%;
      }
    }

  }

  .hero-part-text {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* mastheadSLIDER */
/*-------------------------------------------------------------------------------------------------------------------*/
.carousel-indicators {
  margin-bottom: 0;
  padding-bottom: 0;
  right: 0;
  left: 0;
  z-index: 9876;
  display: none;
}

.mastheadSLIDER {
  background: #000000;
  max-height: rem(600px);
  min-height: rem(600px);
  display: none;

  #demoSLIDER_SHIPPER {
    max-height: rem(485px);
    min-height: rem(485px);
  }

  .carousel-inner {
    max-height: rem(485px);
    min-height: rem(485px);

    .carousel-item {
      max-height: rem(485px);
      min-height: rem(485px);

      img {
        width: 100%;
        max-height: rem(485px);
        min-height: rem(485px);
      }
    }
  }


}


/* remove breadcrumbs */
/*------------------------------------------------------------------------------------------------------------------*/
.page-template-page-shipper,
.home.page-template {
  .breadcrumb-box {
    display: none !important;
  }
}

/* background-sky-network */
/*------------------------------------------------------------------------------------------------------------------*/
.background-sky-network {
  background: url('../img/home/about-home-bg.png') 50% 100% no-repeat;
  padding-bottom: rem(160px);
  width: 100%;
  overflow: hidden;
  display: block;
  padding-top: rem(85px);
  margin-bottom: rem(0px);
  position: relative;
  top: rem(-60px);
  z-index: 9;
  min-height: rem(550px);
  background-size: rem(1715px 2000px);

  &.less-padding {
    padding-top: rem(100px);
    padding-bottom: rem(100px);
  }

}

/* black-sky-img-bg */
/*------------------------------------------------------------------------------------------------------------------*/
.black-sky-img-bg {
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  padding-top: 0;
  top: 0;
  z-index: 9;
}

.white-bg {
  background: #ffffff;
}

.diagonal-bg {
  position: relative;
  z-index: 1;
  //padding-bottom: rem(284px);
  //background-color: #393939 !important;
  display: block;
  width: 100%;
  //overflow: hidden;
  max-width: rem(1715px);
  margin: 0 auto;

  .diagonal-bg-top {
    background: url('../img/black-bg.png') 50% 0 no-repeat !important;
    display: block;
    width: 100%;
    overflow: hidden;
    height: rem(106px);
  }

  .diagonal-bg-center {
    background-color: #393939;
    padding-bottom: 50px;
  }

  .diagonal-bg-bot {
    background: url('../img/black-bg-bot.png') 50% 100% no-repeat !important;
    background-size: cover !important;
    width: 100%;
    overflow: hidden;
    display: block;
    height: 16rem;
    background-color: transparent;
    padding: 0;
    text-align: center;

    img {
      width: 100%;
      height: auto;

    }
  }

}

/* our-mode-compax */
/*------------------------------------------------------------------------------------------------------------------*/
.our-modes-container {
  position: relative;
  z-index: 97;

  .the-desc {
    max-width: 56.25rem;
    padding: 0;
    width: 100%;
    text-align: center;
    margin: 0 auto;

    h4.subtitle {
      text-align: center;
      padding: 0;
      color: #818181;
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin: rem(0 auto 45px);
      width: 100%;
      max-width: 56.25rem;
      padding-left: 2.5rem;
    }

  }

  &.our-modes-container-mask {
    position: relative;
    z-index: 1;
    margin-top: rem(-592px);
    overflow: hidden;
  }

  .our-modes-full {
    padding-bottom: rem(0px);
    background-size: contain;
    overflow: hidden;
    margin-top: rem(0px);

    .mask-modes-container {
      background: url('../img/home/modes-home-bg.png') 50% 50% no-repeat;
      background-attachment: fixed !important;
      background-position: center !important;
      background-repeat: repeat !important;
      position: relative;
      z-index: 1;
      width: 100%;
      height: rem(593px);

      .mask-modes {
        background: url('../img/mask-modes.png') no-repeat !important;
        background-size: cover !important;
        background-repeat: repeat !important;
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: rem(593px);
      }

    }

    .our-modes-ul {
      position: relative;
      z-index: 97;
    }

    .our-mode-compax {

      h1 {
        font-size: rem(72px);
        line-height: rem(100px);
        color: #2A2A2A;
        font-weight: 900;
        font-family: Raleway, sans-serif;
        text-align: center;
        padding-bottom: rem(10px);
        margin-top: 0;
      }

    }

  }

}


/* free-quote-form-box */
/*------------------------------------------------------------------------------------------------------------------*/
.gform_wrapper ul.gform_fields li.gfield {
  list-style: none !important;
}

.free-quote-form-box {
  width: rem(450px);
  height: auto;
  display: block;
  overflow: hidden;
  position: absolute;
  top: rem(640px);
  right: rem(330px);
  z-index: 987;
  text-align: left;

  .gform_wrapper ul.gform_fields li.gfield {
    padding-right: 0;
  }

  .free-quote-form-box-bg-1 {
    background: url(../img/home/free-quote-bg.png) 50% 0 no-repeat;
    width: rem(450px);
    height: auto;
    display: block;
    overflow: hidden;
    text-align: left;
    padding: rem(15px 0);
    background-size: rem(450px 1200px);
  }

  .free-quote-form-box-bg-2 {
    background: url(../img/home/free-quote-bg-part-2.png) 50% 0 no-repeat;
    max-width: rem(450px);
    height: rem(19px);
    width: 100%;
    display: block;
    overflow: hidden;
    text-align: left;
    background-size: rem(450px 19px);
  }

  h1 {
    text-align: center;
    font-size: rem(24px);
    line-height: rem(29px);
    color: #33CCFF;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    padding-bottom: rem(0px);
    max-width: rem(376px);
  }

  .gform_wrapper {
    width: 100%;
    max-width: rem(370px);
    margin: rem(0 auto);

    ul li.gfield {
      margin-top: rem(11px) !important;
    }

    form {
      text-align: left;
      width: 100%;
      margin: 0;
      max-width: rem(322px);
    }

    .gfield_required {
      color: #3CF !important;
    }

    .gform_footer {
      padding: rem(10px 0 10px);
      margin: rem(10px 0 0);
      clear: both;
      width: 100%;
      text-align: center;
      max-width: rem(322px);

      input {
        width: 100%;
        max-width: rem(202px);
        min-width: rem(202px);
        font-size: rem(24px) !important;
        line-height: rem(29px) !important;
        color: #ffffff;
        font-weight: 500;
        border: 0 none;

        border-radius: 1.5625rem;
        margin-right: 0;
        margin-left: 0;
        padding: .625rem 1.5625rem;
        background: #3CF;
        text-transform: none;
        display: inline-block;
        vertical-align: top;
        font-family: Roboto, sans-serif;


        &:hover {
          background: #014F86 !important;
        }
      }


    }

    .top_label div.ginput_container {
      margin-top: rem(5px);
    }

    #input_7_16_3_container label {
      display: none;
    }

    label {
      font-size: rem(18px);
      line-height: rem(22px);
      font-weight: 500;
      color: #3CF;
      font-family: 'Roboto', sans-serif !important;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    input {
      border-radius: rem(10px);
      min-height: rem(48px);
      height: rem(48px);
      border: rem(1px solid #707070);
      width: 100%;
      max-width: rem(322px);
      padding: rem(0 10px) !important;
      font-family: 'Raleway', sans-serif !important;
      color: #b0b0b0;
      background: #ffffff;
      outline: none;
    }

    .top_label input.medium,
    .top_label select.medium {
      border-radius: rem(10px);
      min-height: rem(48px);
      height: rem(48px);
      border: rem(1px solid #707070);
      width: 100% !important;
      max-width: rem(322px);
      line-height: rem(48px);
      padding: rem(14px 10px 12px 10px);
      font-family: 'Raleway', sans-serif !important;
      color: #b0b0b0;
      background: #ffffff;
      outline: none;
    }

  }

}

/* mapeado */
/*------------------------------------------------------------------------------------------------------------------*/
.tab-pane {
  .mapeado {
    width: 100%;
    max-width: rem(1715px);
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 987;
    top: 0;
    left: 0;

    .point_style {
      img {
        width: rem(28px);
        height: rem(38px);
      }
    }

  }
}

/* nav-tabs-shipper */
/*------------------------------------------------------------------------------------------------------------------*/
.nav-tabs-shipper {
  padding: rem(5px);
  position: relative;
  z-index: 1050;

  .nav-item {
    border-radius: 0;
    margin: rem(0 35px);

    .nav-link {
      border-radius: 0;
      min-width: rem(300px);
      background-color: transparent;
      width: rem(100%);
      height: rem(56px);
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0 none;
      font-size: rem(28px);
      line-height: rem(39px);
      color: #818181;
      font-weight: 900;
      font-family: Raleway, sans-serif;
      text-transform: none;


      span {
        background: url('../img/shipper/icon-marker-gris.png') 50% 0 no-repeat;
        background-size: rem(28px 38px);
        width: rem(28px);
        height: rem(38px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: rem(0 15px);
      }

      &.active {
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
        color: #33CCFF;

        span {
          background: url('../img/shipper/icon-marker-sky.png') 50% 0 no-repeat;
          background-size: rem(28px 38px);
        }
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }

    }

  }

}

#footer {
  .menu-item-34 {
    display: none !important;
  }
}

.footer-logo {
  a {
    width: rem(265px);
    display: block;
  }
}

/* horizontal-box-right-container-2 */
/*------------------------------------------------------------------------------------------------------------------*/
.horizontal-box-right-container-2 {
  background-size: rem(1920px 830px) !important;
}

.horizontal-box-repeater-2 {
  max-width: rem(1450px);
  float: right;
}

.img-size-284x166px {
  img {
    width: rem(284px);
    height: auto;
  }
}

.img-size-203x203px {
  img {
    width: rem(203px);
    height: auto;
  }
}

.img-size-330x171px {
  img {
    width: rem(330px);
    height: auto;
  }
}

.img-size-350x240px {
  img {
    width: rem(350px);
    height: auto;
  }
}

.img-size-350x150px {
  img {
    width: rem(350px);
    height: auto;
  }
}

.img-size-300x150px {
  img {
    width: rem(300px);
    height: auto;
  }
}

.img-size-284x166px {
  img {
    width: rem(284px);
    height: auto;
  }
}

.img-fondo-size-1722x1610px {
  background-size: rem(1722px 1610px);
}

/* free-quote-form-box */
/*------------------------------------------------------------------------------------------------------------------*/
.jobs-template-default.single,
.page-template-page-request,
.single.single-post,
.page.page-id-30 {
  .free-quote-form-box {
    display: none !important;
  }
}

.page-id-463 {
  .free-quote-form-box {
    display: none !important;
  }

  .bg-animation-bot {
    display: none !important;
  }
}


/* divider-image */
.divider-image {
  width: 100%;
  display: block;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  z-index: 1;
  margin-top: rem(-380px);
}

.diagonal-sky-bg {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  overflow: hidden;

  .diagonal-sky-bg-top {
    background: url('../img/sky-bg.png') 50% 0 no-repeat !important;
    background-size: cover !important;
    display: block;
    width: 100%;
    overflow: hidden;
    height: rem(80px);
  }

  .diagonal-sky-bg-center {
    padding-bottom: rem(16px);
    background-color: #00ceff !important;
  }

  .diagonal-sky-bg-bot {
    background: url('../img/sky-bg-bot.png') 50% 100% no-repeat !important;
    background-size: cover !important;
    width: 100%;
    overflow: hidden;
    display: block;
    height: rem(140px);
  }

}

#powerTip {
  background-color: #33CCFF;
  text-align: center;
  padding-left: rem(40px);
  padding-right: rem(40px);
  font-family: Raleway, sans-serif;

  .box_view_html {
    color: #ffffff;
    font-weight: bold;
    font-size: rem(32px);
    line-height: rem(38px);
    max-width: rem(530px);
    white-space: normal;
    overflow: hidden;
    position: relative;

    p {
      margin-bottom: 0;
    }

  }

}

#powerTip.n {
  &::before {
    border-top: rem(10px solid #33CCFF) !important;
  }
}

#powerTip.s {
  &::before {
    border-bottom: rem(10px solid #33CCFF) !important;
  }
}

/* header-room */
/*-------------------------------------------------------------------------------------------------------------------*/
.header-room {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  height: auto;

  .site-header {
    padding-top: rem(133px);
  }

}

//slick-dots
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: rem(15px auto) !important;
  outline: none;

  li {
    &::marker {
      content: "";
      outline: none;
    }

    outline: none;
    border: 0 none;
    background: none;
    margin: rem(0 5px);

    button {
      border: 0 none;
      background: #3CF;
      width: rem(5px);
      height: rem(10px);
      text-indent: -8888878px;
      outline: none;
    }

    &.slick-active {
      button {
        border: 0 none;
        background: #1b1b1b;
      }
    }

  }

}

//jobs-list-mobile
.jobs-list-mobile {
  display: none;
}

/* animation */
/*------------------------------------------------------------------------------------------------------------------*/
.marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99999px;
  background-color: transparent;
  overflow: hidden;
  z-index: 1;
}

.markerTo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99999px;
  background-color: transparent;
  overflow: hidden;
  z-index: 5;
}

.marker .circler {
  visibility: hidden;
}

.container-for-animation {
  position: absolute;
  z-index: 9;
  top: rem(170px);
  left: 0;
  width: 100%;
  overflow: hidden;
  display: block;
}


/* page-template-page-blog */
.page-template-page-blog {

  #footer {
    position: relative;
    z-index: 987;
  }

  #footer {
    .bg-animation-bot {
      display: none;
    }
  }
}

/* gform_confirmation_wrapper */
.gform_confirmation_wrapper {
  max-width: rem(325px);
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: left;
  min-height: rem(410px);
}


/* page-template-page-shipper */
.page-template-page-shipper {

  .horizontal-box-right.horizontal-box-format-center .the-desc.the-desc-shipper {
    margin: rem(0 auto 65px);
    max-width: rem(550px);
    width: 100%;
    padding-top: 0;
  }

}


/* BLOQUE-FULL */
.BLOQUE-FULL {
  width: rem(1588px);
  margin: 0 auto;
  overflow: hidden;
  background-color: red;
  height: rem(1px);
}

/* BLOQUE-POST-LOGO */
.BLOQUE-POST-LOGO {
  width: rem(1180px);
  margin: 0 auto;
  overflow: hidden;
  background-color: green;
  height: rem(1px);
}

/* BLOQUE-TITLE */
.BLOQUE-TITLE {
  width: rem(100%);
  margin: 0 auto;
  overflow: hidden;
  background-color: blue;
  height: rem(1px);
}

/* mascaron */
.mascaronimg {
  background-position: 50% 50%;

  .mascaron {
    background: url(../img/mask.png) no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
  }

  .mascaron-big {
    background: url(../img/mask-big.png) no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
  }

}

/* fix title specialized service*/
body.postid-107 {
  .hero-part-text {
    h1 {
      font-size: rem(49px);
      margin-top: rem(65px);
    }
  }
}


/* area-manager-tabs */
.area-manager-tabs {
  height: rem(550px);
  overflow: hidden;
}

.testimonials-relationship-manager {
  height: rem(415px);
  overflow: hidden;
}

.tab-pane.fade {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

/* BLOQUE-SCW */
/*-------------------------------------------------------------------------------------------------------------------*/
.BLOQUE-SCW {


  .our-mode-compax {
    padding: rem(0 50px);
  }

  .services-box-container {
    padding: rem(50px 0 0 0);
  }

  .mask-modes-container {
    background-attachment: fixed !important;
    background-position: center !important;
    background-repeat: repeat !important;
    position: relative;
    z-index: 1;
    width: 100%;
    height: rem(593px);

    .mask-modes {
      background: url('../img/mask-modes.png') no-repeat !important;
      background-size: cover !important;
      background-repeat: repeat !important;
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      display: block;
      height: rem(593px);
    }

  }

  .services-box-container ul li {
    padding-top: 0;
  }

  .slides {
    padding: 0;
    margin: 0;

    .slide {
      list-style: none;
      justify-content: center;
      text-align: center;
      display: flex;

      a.scw_container {
        max-width: rem(575px);
        width: 100%;
        height: auto;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        text-decoration: none;

        .scw_data {
          position: absolute;
          z-index: 987;
          top: 0;
          left: 0;
          width: 100%;
          padding-top: rem(100px);
        }

        h1 {
          font-family: Raleway, sans-serif;
          color: #ffffff;
          text-transform: uppercase;
          font-weight: 900;
          font-size: rem(57px);
          line-height: rem(80px);
        }

        .button {
          background-color: #ffffff;
          font-size: rem(18px);
          font-weight: 500;
          line-height: rem(22px);
          border-radius: rem(25px);
          text-transform: none;
          padding: rem(6px 25px);
          color: #33CCFF;
          text-decoration: none;

          &:hover {
            background-color: #3C3C3C !important;
            text-decoration: none;
          }
        }

        &:hover {
          .button {
            background-color: #3C3C3C !important;
            text-decoration: none;
          }
        }

      }

    }

  }

}

/* content_header_box */
.content_header_box {
  animation-duration: 0.5s;
  transition: 0.5s;

  &.is-fixed,
  &.sticky {
    position: fixed;
    z-index: 98765432;
    animation-name: stickySlideDown;
    padding: 0;
    transition: none;
    top: 0;
    background-color: #ffffff !important;

    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16);

    .top-of-page {
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      display: none;
    }

    .menu_box_primary .navbar.navbar-expand-xl ul li a {
      color: #42C8F4 !important;

      &:hover {
        color: #3c3c3c !important;
      }
    }

    .menu_box_primary .dropdown-menu {
      background-color: rgba(255, 255, 255, 1) !important;
    }

  }

}

@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

