/* testimonials-box-area */
/*----------------------------------------------------------------------*/
.testimonials-box-area {
  padding: rem(0);
  background-color: transparent;
  width: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 987;
  overflow: hidden;

  h1 {
    font-size: rem(72px);
    line-height: rem(100px);
    color: #2A2A2A;
    font-weight: 900;
    font-family: 'Raleway', sans-serif;
    padding-bottom: rem(10px);
    max-width: rem(800px);
    margin: 0 auto;
    padding-top: rem(80px);
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #ffffff;
    font-size: rem(18px);
    line-height: rem(22px);
    margin: rem(0 auto 50px);
    max-width: rem(760px);
  }

  .testimonials-relationship {
    margin: 0;
    padding: 0;

    ul.responsive-mobile,
    ul.responsive {
      padding: rem(0 60px);
      margin: 0;

      li {
        list-style: none;
        background-color: transparent;
        margin-bottom: rem(25px);

        .t-zone {
          background-color: #fff;
          padding: rem(15px 15px);
          display: block;
          width: 100%;
          text-decoration: none;
          text-align: left;
          position: relative;
          z-index: 1;
          min-width: rem(310px);

          &:hover {
            text-decoration: none;
          }
        }

        .t-data {
          display: inline-block;
          vertical-align: top;
          box-shadow: rem(0 0 5px 0px rgba(0, 0, 0, 0.15));
          padding: rem(20px);
          width: 100%;
          min-height: rem(350px);

          h1 {
            font-family: Raleway, sans-serif;
            font-weight: 700;
            color: #2A2A2A;
            font-size: rem(34px);
            line-height: rem(38px);
            margin: 0;
            padding: rem(0 0 10px);
          }

          h2 {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            color: #33CCFF;
            font-size: rem(23px);
            line-height: rem(35px);
            margin: 0;
            padding: rem(0 0 10px);
            text-transform: none;
          }

          p {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            color: #2A2A2A;
            font-size: rem(20px);
            line-height: rem(35px);
            margin: 0;
            padding: rem(0 0 10px);
          }

        }

      }

      .slick-arrow {
        position: absolute;
        z-index: 987;
        top: 50%;
      }

      .slick-prev.slick-arrow {
        background: url(../img/about/arrow-slider-left.png) 0 0 no-repeat;
        background-size: rem(42px 42px);
        border: 0;
        width: rem(42px);
        height: rem(42px);
        display: inline-block;
        vertical-align: top;
        text-indent: -99999px;
        outline: none;
        left: 0;
        margin-left: 0;
        margin-top: rem(-21px);
      }

      .slick-next.slick-arrow {
        background: url(../img/about/arrow-slider-right.png) 0 0 no-repeat;
        background-size: rem(42px 42px);
        border: 0;
        width: rem(42px);
        height: rem(42px);
        display: inline-block;
        vertical-align: top;
        text-indent: -99999px;
        outline: none;
        right: 0;
        margin-right: 0;
        margin-top: rem(-21px);
      }

    }
  }

}