/* page-template-page-request */
/*-----------------------------------------------------------*/
body.page-template-page-request {
  h1 {
    font-size: rem(72px);
    line-height: rem(100px);
    color: #33CCFF;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    text-align: left;
    width: 100%;
    padding-top: rem(50px);
  }

  .gform_wrapper {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: rem(1450px);
    padding: rem(44px 0);
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #333333 !important;
    opacity: 1 !important;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: #333333 !important;
    opacity: 1 !important;
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: #333333 !important;
    opacity: 1 !important;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: #333333 !important;
    opacity: 1 !important;
  }


  .gform_wrapper .ginput_container input {
    background: #f7f7f7 !important;
    padding: rem(20px 30px) !important;
    font-size: rem(17px) !important;
    line-height: rem(24px) !important;
    font-family: Raleway, sans-serif !important;
    color: #333333 !important;
    width: 100% !important;
    height: rem(64px) !important;
    border: rem(1px solid #333333) !important;
  }

  .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield div.ginput_complex span.ginput_left select,
  .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield div.ginput_complex span.ginput_right select,
  .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
    margin-left: rem(0px) !important;
  }

  .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_first,
  .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_last,
  .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_middle {
    padding-top: rem(0px) !important;
  }

  .gform_wrapper .ginput_container select,
  .gform_wrapper .ginput_container .gfield_select {
    background: #f7f7f7;
    padding: rem(20px 30px);
    font-size: rem(17px);
    line-height: rem(24px);
    font-family: Raleway, sans-serif;
    color: #333333;
    height: rem(64px);
    width: 100% !important;
    outline: none;
    margin: 0;
    border: rem(1px solid #333333);
  }

  .gform_wrapper .ginput_container.ginput_container_select {
    border: 0;
    padding: 0;
    font-size: rem(17px);
    line-height: rem(24px);
    font-family: Raleway, sans-serif;
    color: #333333;
    width: 100% !important;
    overflow: hidden;
    outline: none;
    background: #f7f7f7;
  }

  body .gform_wrapper.gform_wrapper img.ui-datepicker-trigger {
    width: rem(17px) !important;
    height: rem(16px) !important;
    display: -moz-inline-stack;
    display: inline-block;
    margin-left: rem(-35px);
  }

  .gform_wrapper .ginput_container.ginput_container_textarea textarea {
    height: 160px;
    width: 100%;
    background: #f7f7f7;
    padding: rem(20px 30px);
    font-size: rem(17px);
    line-height: rem(24px);
    font-family: Raleway, sans-serif;
    color: #333333;
    border: rem(1px solid #333333);
  }

  .gform_wrapper li.gfield.gsection {
    width: 100% !important;
  }

  .gform_wrapper .gform_footer .gform_button.button {
    border-radius: rem(25px);
    margin-right: 0;
    margin-left: 0;
    padding: rem(10px 25px);
    background: #42c8f4;
    color: #fff;
    font-size: rem(15px);
    line-height: rem(27px);
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    display: inline-block;
    vertical-align: top;
    font-family: Raleway, sans-serif;
    margin-bottom: rem(45px);
  }

  .gform_wrapper .gform_footer .gform_button.button:hover {
    background: #1a1a1a;
    color: #fff;
  }

  .gform_wrapper .ginput_container_radio .gfield_radio li {
    display: inline-block;
    vertical-align: top;
    width: 40%;
  }

  .gform_wrapper .gform_heading .gform_title {
    font-size: rem(45px);
    line-height: rem(55px);
    color: #2a2a2a;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    padding-bottom: rem(10px);
    width: 100%;
    text-align: center;
  }

  .gform_wrapper .gform_heading .gform_description {
    font-size: rem(25px);
    line-height: rem(35px);
    color: #2a2a2a;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    padding-bottom: rem(10px);
    width: 100%;
    text-align: center;
  }

  .gform_wrapper ul.gform_fields li.gfield {
    padding-right: rem(16px);
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin: rem(0 -2px);
    margin-bottom: rem(25px);
  }


  .ginput_container.ginput_container_date {
    position: relative;
    z-index: 9;
  }

  .ginput_container.ginput_container_date .ui-datepicker-trigger {
    position: absolute;
    z-index: 987;
    top: 25px;
    right: 20px;
  }

  .gform_wrapper.gform_wrapper .gform_footer {
    text-align: center !important;
  }

  .full_container_for_logic {
    width: 100% !important;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    min-height: 110px;
  }

  .full_container_for_logic li.gfield {
    width: 100% !important;
    position: absolute;
    z-index: 987;
    top: 0;
  }

  .gform_wrapper.gform_wrapper .full_container_for_logic {
    min-height: 110px;
  }

  .gform_wrapper.gform_wrapper .full_container_for_logic li.gfield,
  .gform_wrapper.gform_wrapper .full_container_for_logic li.gfield label {
    background-color: #ffffff;
  }

  @media (max-width: 767px) {
    .gform_wrapper ul.gform_fields li.gfield {
      padding-right: 16px;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin: 0 -2px;
      margin-bottom: 25px;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute !important;
    left: -9999px !important;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative !important;
    padding-left: 42px !important;
    cursor: pointer !important;
    line-height: 30px !important;
    display: inline-block !important;
    color: #666 !important;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '' !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 28px !important;
    height: 28px !important;
    border: 1px solid #42c8f4 !important;
    border-radius: 100% !important;
    background: #fff !important;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '' !important;
    width: 22px !important;
    height: 22px !important;
    background: #42c8f4 !important;
    position: absolute !important;
    top: 3px !important;
    left: 3px !important;
    border-radius: 100% !important;
    -webkit-transition: all 0.2s ease !important;
    transition: all 0.2s ease !important;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0 !important;
    -webkit-transform: scale(0) !important;
    transform: scale(0) !important;
  }

  [type="radio"]:checked + label:after {
    opacity: 1 !important;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
  }
}