/* horizontal-box-right */
/*-------------------------------------------------------------------------------------------------------------------*/
.horizontal-box-right {
  width: 100%;
  margin-bottom: rem(50px);

  &.horizontal-temperature {
    position: relative;
    z-index: 987;
  }

  &.horizontal-dryvan {
    position: relative;
    z-index: 987;
  }

  h1 {
    font-size: rem(72px);
    line-height: rem(100px);
    color: #2a2a2a;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    padding-bottom: rem(10px);
    text-align: right;
    max-width: rem(980px);
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  p {
    color: #818181;
    font-size: rem(18px);
    line-height: rem(22px);
    margin: rem(0 auto);
    width: 100%;
    text-align: right;
    max-width: rem(900px);
    display: inline-block;
    vertical-align: top;
    padding-left: rem(40px);
  }

  .horizontal-box-repeater {
    text-align: right;
    padding: rem(100px 80px);
    display: flex;
    justify-content: end;

    .horizontal-box-slides {
      display: flex;

      li {
        list-style: none;
        text-align: center;

        h2 {
          color: #818181;
          font-size: rem(24px);
          line-height: rem(29px);
          font-weight: 500;
          font-family: Roboto, sans-serif;
        }

      }

    }

  }

  &.horizontal-box-format-center {
    text-align: center;
    margin-top: rem(-60px);

    h1 {
      text-align: center;
    }

    .horizontal-box-repeater {
      text-align: center;
      min-height: rem(1px);
    }

    .horizontal-box-slides {
      width: 100%;
    }

    .the-desc {
      max-width: rem(900px);
      padding: 0;
      width: 100%;
      text-align: center;
      margin: 0 auto;

      p {
        max-width: rem(900px);
        text-align: center;
        padding: 0;
      }
    }

    .horizontal-box-repeater {
      padding: rem(70px 50px);
    }

    li {
      list-style: none;
      text-align: center;
    }

  }

}

/* the-opendeck */
/*-------------------------------------------------------------------------------------------------------------------*/
.the-opendeck-area {
  width: 100%;
  overflow: hidden;

  h1 {
    padding-top: rem(50px) !important;
  }

  &.experience-box .experience-box-container {
    min-height: rem(890px);
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
    max-width: rem(1715px);
    padding: 0;
  }

  &.equipment-box .equipment-box-container h1 {
    color: #ffffff;
  }

  &.equipment-box .equipment-box-container .the-desc {
    color: #ffffff;

    p {
      color: #ffffff;
    }
  }

  h2 {
    color: #ffffff;
    font-size: rem(24px);
    line-height: rem(29px);
    font-weight: 500;
    font-family: Roboto, sans-serif;
    max-width: rem(204px);
    margin: rem(24px auto);
  }

}

/* the-other-area */
/*-------------------------------------------------------------------------------------------------------------------*/
.the-other-area {

  h1 {
    font-size: rem(72px);
    line-height: rem(100px);
    color: #2a2a2a;
    font-weight: 900;
    font-family: Raleway, sans-serif;
    padding-bottom: rem(10px);
    text-align: center;
    padding-top: rem(30px);
  }

  li.the-other-area-slide {
    list-style: none;
    padding-top: rem(80px);

    .the-other-area-slide-width {
      max-width: rem(680px);
      margin: 0 auto;
    }

    h2 {
      color: #33CCFF;
      font-size: rem(24px);
      line-height: rem(29px);
      font-weight: 500;
      font-family: Roboto, sans-serif;
      max-width: rem(100%);
      margin: rem(32px auto 0);
      text-align: center;
    }

    .the-other-area-slideitem {
      padding: rem(0 40px);
    }

    .the-desc {
      color: #818181;
      text-align: center;

      p {
        color: #818181;
        text-align: center;
      }
    }

  }

  &.the-other-area-format {
    margin-top: rem(80px);

    li.the-other-area-slide {
      padding-top: rem(60px);
    }

    h1 {
      color: #ffffff !important;
      padding-top: rem(40px) !important;
    }

    .the-desc {
      max-width: rem(400px);
      margin: 0 auto;

      p {
        color: #ffffff !important;
      }
    }

    .the-other-area-box-container {
      width: 100%;
      max-width: rem(1722px);
      margin: 0 auto;
      overflow: hidden;
    }

  }

}

/* vertical-list-area */
.vertical-list-area {
  li {
    list-style: none;
  }

  .vertical-list-area-img {
    padding: rem(100px 20px);

    img {
      width: rem(754px);
      height: auto;
    }
  }

  h3 {
    color: #33CCFF;
    font-size: rem(24px);
    line-height: rem(29px);
    font-weight: bold;
    font-family: Roboto, sans-serif;
    max-width: rem(100%);
    margin: rem(42px auto 0);
    text-align: left;
  }

  h4 {
    color: #818181;
    font-size: rem(18px);
    line-height: rem(22px);
    font-weight: 500;
    font-family: Roboto, sans-serif;
    max-width: rem(100%);
    margin: rem(22px auto 0);
    text-align: left;
  }
}

/* horizontal-x4 */
.horizontal-box-right.horizontal-box-format-center.horizontal-x4 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;

  .horizontal-contenedor {
    max-width: rem(555px);
    margin: 0 auto;
  }

  .horizontal-box-repeater {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .horizontal-box-repeater .horizontal-box-slides li {
    padding-top: rem(60px);
    padding-bottom: rem(60px);

    h2 {
      color: #33CCFF;
      font-size: rem(24px);
      line-height: rem(29px);
      font-weight: 500;
      font-family: Roboto, sans-serif;
      max-width: rem(100%);
      margin: rem(32px auto 0);
      text-align: center;
    }

    .the-desc {
      color: #818181;
      text-align: center;

      p {
        color: #818181;
        text-align: center;
      }
    }

  }

}