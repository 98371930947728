.desktop-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.desktop-view-block {
  display: block;
  width: 100%;
}

.mobile-view,
.mobile-view-block {
  display: none;
}

.visibility-mobile-view {
  visibility: hidden;
}

.img_for_mobile {
  display: none;
}

/* IE11 */
/*------------------------------------------------------------------------------------------------------------------*/

_:-ms-fullscreen, :root body .desktop-view-block,
_:-ms-fullscreen, :root body .desktop-view {
  display: block;
}

@media (max-width: 1023px) {
  _:-ms-fullscreen, :root body .mobile-view,
  _:-ms-fullscreen, :root body .mobile-view-block {
    display: block;
  }
}

div.lineaLateralDerecha {
  background: url(../img/bg-line-right.png) 0 0 no-repeat transparent;
  display: block;
  width: 100%;
  height: 34px;
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: 987;
  margin-top: -16px;
}

/* 1740 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1740px) {

  /* divider-image */
  .divider-image {
    margin-top: rem(-160px);
  }


  //content
  .background-sky-network {
    margin: 0 auto;
    max-width: rem(1520px);
    padding-left: rem(30px);
    padding-right: rem(30px);
  }

  .BLOQUE-FULL {
    width: rem(1460px);
  }

  .BLOQUE-POST-LOGO {
    width: rem(1300px);
  }

  .free-quote-form-box {
    right: rem(200px);
  }

  .container-small {
    max-width: rem(1350px);
    margin: 0 auto;
  }

  .services-box-container ul li a.services-box-container-a {
    width: rem(250px);
    margin: 0 auto;
  }

}

/* 1600 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1600px) {

  //content
  .background-sky-network {
    margin: 0 auto;
    max-width: rem(1320px);
    padding-left: rem(30px);
    padding-right: rem(30px);
  }

  .BLOQUE-FULL {
    width: rem(1260px);
  }

  .BLOQUE-POST-LOGO {
    width: rem(1000px);
  }

  .free-quote-form-box {
    right: rem(180px);
  }

  .container-small {
    max-width: rem(1150px);
    margin: 0 auto;
  }

  .services-box-container ul li a.services-box-container-a {
    width: rem(250px);
    margin: 0 auto;
  }

  .services-box-container ul li a.services-box-container-a h1.services-box-container-text {
    font-size: rem(14px);
  }

  .jobs-list-desktop .responsive-x4 li {
    min-width: 23.25rem;
  }

  .opportunities-list-box ul li .t-zone-hover .t-data-hover {
    max-width: 9.5rem;
    left: -2.875rem;
  }

  .BLOQUE-SCW .our-modes-container.our-modes-container-mask {
    margin-top: rem(-340px);
  }

  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: rem(37px);
    line-height: rem(44px);
  }

  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: rem(85px);
  }

}

/* 1365 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1365px) {

  //content
  .background-sky-network {
    margin: 0 auto;
    max-width: rem(1220px);
    padding-left: rem(30px);
    padding-right: rem(30px);
  }


  .BLOQUE-FULL {
    width: rem(1160px);
  }

  .BLOQUE-POST-LOGO {
    width: rem(900px);
  }

  .free-quote-form-box {
    right: rem(140px);
  }

  .container-small {
    max-width: rem(1050px);
    margin: 0 auto;
  }

  .services-box-container ul li a.services-box-container-a {
    width: rem(250px);
    margin: 0 auto;
  }

  .services-box-container ul li a.services-box-container-a h1.services-box-container-text {
    font-size: rem(14px);
  }

  .jobs-list-desktop .responsive-x4 li,
  .opportunities-list-box ul li .t-zone,
  .opportunities-list-box ul li .t-zone-hover {
    min-width: 20.25rem;
  }

  .menu_box_primary .navbar.navbar-expand-xl ul li a {
    padding: .3125rem 0.538rem;
  }

  .error404 header#masthead, .home.page-template header#masthead {
    max-height: 46.25rem;
    min-height: 46.25rem;
  }

  .opportunities-list-box ul li:hover .t-zone-hover {
    left: -2.99rem;
  }

  .opportunities-list-box ul li .t-zone-hover .t-data-hover {
    padding-top: 2.875rem;
    left: -1.875rem;
  }

  .opportunities-list-box ul li:hover .t-zone-hover {
    left: -3.27rem;
  }

  .opportunities-list-box ul li .t-zone-hover::before {
    margin-top: -21.125rem;
    margin-left: 11rem;
    min-height: 50em;
  }

}

/* 1280 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1279px) {

  html {
    font-size: 13px;
  }

  .BLOQUE-SCW {
    .our-modes-container.our-modes-container-mask {
      margin-top: rem(-360px);
    }
  }

  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: rem(37px);
    line-height: rem(44px);
  }

  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: rem(85px);
  }


  //jobs-list-desktop
  .jobs-list-desktop {
    display: none !important;
  }
  .jobs-list-mobile {
    display: block !important;
  }

  .blog-the-container .entry-date,
  div.marker,
  div.markerTo {
    display: none !important;
  }

  .blog-the-container .blog-post {
    margin: 0 auto 6.25rem;
  }

  .free-quote-form-box {
    width: 28.125rem;
    height: auto;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 40rem;
    right: rem(120px);
    z-index: 987;
    text-align: left;
  }

  .footer-logo a {
    margin: 0 auto;
  }

  .jobs-list-desktop .responsive-x4 li {
    min-width: 24.25rem;
  }

  .opportunities-list-box ul li .t-zone-hover .t-data-hover {
    max-width: 8.5rem;
  }

  #main-nav.collapse:not(.show) {
    display: block;
  }

  //opportunities-list-box
  .opportunities-list-box {

    .cos-area {
      margin: 0;
    }

    #nav-tabContent {
      margin: 0;
      min-height: rem(1px);
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      margin-top: 0;
      border: 0 none;
      min-height: auto;
      max-height: 100%;
    }

    .slick-list.draggable {
      margin: 0;
      padding: 0;
    }

    ul.responsive-x4-mobile li.slick-slide .t-data {
      display: none !important;
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone-hover::before {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      margin-top: 0;
      margin-left: 0;
    }

    ul.responsive-x4-mobile .slick-prev.slick-arrow {
      left: rem(10px);
    }

    ul.responsive-x4-mobile .slick-next.slick-arrow {
      right: rem(10px);
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone-hover p,
    ul.responsive-x4-mobile li.slick-slide .t-zone-hover h1 {
      text-align: center;
    }

    .btn-default.btn-apply {
      float: none;
    }

    ul.responsive-x4-mobile li.slick-slide:hover .t-zone-hover {
      top: 0;
      left: 0;
    }

  }

  .sidenav .navbar-brand {
    width: 100%;

    a {
      max-width: rem(200px);
    }
  }

  //responsive-x4
  ul.responsive-x4-mobile li.responsive-x4-li {
    padding: 0;
    height: auto;
    min-width: auto;
    margin-right: 0;
    list-style: none;
  }

  //markerTo
  .markerTo {
    display: none;
  }

  //jobs-list-mobile
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    min-height: rem(1px);
    height: 100%;
  }

  .opportunities-list-box ul.responsive-x4-mobile li:hover .t-zone-hover {
    min-height: rem(1px);
    height: 100vh;
    left: 0;
  }

  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide img {
    display: block;
    width: 100%;
  }

  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover .t-data-hover {
    padding-top: 0;
  }

  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover {
    max-height: 100%;
  }

  .opportunities-list-box ul {
    padding: 0;
  }

  .testimonials-box-area .testimonials-relationship ul.responsive-mobile {
    padding: 0;
    margin: 0;
  }

  /* area-manager-tabs */
  .area-manager-tabs,
  .testimonials-relationship-manager {
    height: rem(100%);
  }

}

/* 1200 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1200px) {
  .our-modes-container.our-modes-container-mask {
    margin-top: -48rem;
  }

  .BLOQUE-SCW {
    .our-modes-container.our-modes-container-mask {
      margin-top: rem(-340px);
    }
  }

  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: rem(37px);
    line-height: rem(44px);
  }

  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: rem(85px);
  }

}

/* 1199 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1199px) {
  .BLOQUE-SCW {
    .our-modes-container.our-modes-container-mask {
      margin-top: rem(-640px);
    }
  }

  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: rem(57px);
    line-height: rem(80px);
  }

  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: rem(100px);
  }

}

/* 1170 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1170px) {

  .error404 header#masthead,
  .home.page-template header#masthead {
    max-height: 50.25rem;
    min-height: 50.25rem;
  }

  .horizontal-box-repeater-2 {
    max-width: 70.625rem;
  }

  .BLOQUE-SCW {
    .our-modes-container.our-modes-container-mask {
      margin-top: rem(-750px);
    }
  }

  .BLOQUE-ABOUT {
    margin-top: rem(250px);
  }

}

/* 1025 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (min-width: 1025px) {

  .mascaronimg {
    background-attachment: fixed !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  header#masthead {
    background-attachment: fixed;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

}

/* 1024 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1024px) {

  .our-modes-container-mask {
    display: none !important;
  }

  .BLOQUE-OURMODES-stan .container-small-our-modes-bg {
    background: url(../img/home/modes-home-bg-mb.png) 50% 50% no-repeat;
    padding-bottom: 0;
  }

  .our-modes-container.our-modes-container-mask {
    margin-top: -48rem;
  }

  .container-small-our-modes-bg {
    padding-bottom: 0;
    width: 100%;
    max-width: 100%;
  }

  .BLOQUE-SCW .our-mode-compax {
    padding: 0;
  }

  .BLOQUE-ABOUT {
    margin-top: rem(0px);
  }

  //BLOQUE-SCW
  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: rem(57px);
    line-height: rem(80px);
  }

  .BLOQUE-SCW .slides .slide a.scw_container .scw_data {
    padding-top: rem(100px);
  }
}

/* 996 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 996px) {

  html {
    font-size: 14px;
  }

  //blog-almacen-content
  .blog-the-container .blog-almacen .blog-almacen-content {
    padding: rem(40px 40px);
  }

  //entry-date
  .blog-the-container .entry-date {
    display: none !important;
  }

  body .free-quote-form-box {
    right: rem(0px) !important;
  }

  .get-in-touch-box-form .gform_wrapper form {
    max-width: rem(700px);
  }

  .get-in-touch-box .gform_body li#field_1_5,
  .get-in-touch-box .gform_body li#field_1_6 {
    width: 50%;
  }

  .get-in-touch-box .gform_body li#field_1_7 {
    width: 100%;
  }

  .footer-logo a {
    display: block;
    margin: 0 auto;
  }

  .menu_box_primary .navbar.navbar-expand-xl ul li {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-brand a {
    width: 11.563rem;
    display: block;
  }

  .jobs-list-desktop .responsive-x4 li {
    min-width: 19.25rem;
  }

  body .divider-image {
    margin-top: 0;
  }

  .get-in-touch-box .gform_body #field_1_6 .ginput_container_email {
    padding-right: 0;
    margin-right: 0;
  }

  .services-box-container ul li a.services-box-container-a {
    margin: 0 auto;
  }

  .our-modes-full {
    background-position: 50% 50% !important;
    padding-bottom: 0;
  }

  .get-in-touch-box {
    padding-top: 0 !important;
  }

  /* home */
  .error404 header#masthead,
  .home.page-template header#masthead {
    max-height: 54.25rem;
    min-height: 54.25rem;
  }

  video.fullscreen-bg__video {
    width: auto;
    height: auto;
  }

  //opportunities-list-box
  .opportunities-list-box {

    .cos-area {
      margin: 0;
    }

    #nav-tabContent {
      margin: 0;
      min-height: rem(1px);
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      margin-top: 0;
      border: 0 none;
      min-height: auto;
      max-height: 100%;
    }

    .slick-list.draggable {
      margin: 0;
      padding: 0;
    }

    ul.responsive-x4-mobile li.slick-slide .t-data {
      display: none !important;
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone-hover::before {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      margin-top: 0;
      margin-left: 0;
    }

    ul.responsive-x4-mobile .slick-prev.slick-arrow {
      left: rem(10px);
    }

    ul.responsive-x4-mobile .slick-next.slick-arrow {
      right: rem(10px);
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone-hover p,
    ul.responsive-x4-mobile li.slick-slide .t-zone-hover h1 {
      text-align: center;
    }

    .btn-default.btn-apply {
      float: none;
    }

    ul.responsive-x4-mobile li.slick-slide:hover .t-zone-hover {
      top: 0;
      left: 0;
    }

  }

  .sidenav .navbar-brand {
    width: 100%;

    a {
      max-width: rem(200px);
    }
  }

  //responsive-x4
  ul.responsive-x4-mobile li.responsive-x4-li {
    padding: 0;
    height: auto;
    min-width: auto;
    margin-right: 0;
    list-style: none;
  }

  //markerTo
  .markerTo {
    display: none;
  }

  //jobs-list-mobile
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    min-height: rem(1px);
    height: 100%;
  }

  .opportunities-list-box ul.responsive-x4-mobile li:hover .t-zone-hover {
    min-height: rem(1px);
    height: 100vh;
    left: 0;
  }

  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide img {
    display: block;
    width: 100%;
  }

  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover .t-data-hover {
    padding-top: 0;
  }

  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover {
    max-height: 100%;
  }

  .opportunities-list-box ul {
    padding: 0;
  }

  .testimonials-box-area .testimonials-relationship ul.responsive-mobile {
    padding: 0;
    margin: 0;
  }

  /* area-manager-tabs */
  .area-manager-tabs,
  .testimonials-relationship-manager {
    height: rem(100%);
  }

  .about-zone .about-slides .about-slide .about-text {
    max-width: rem(400px);
    margin: 0 auto;
  }

  .get-in-touch-box-form .gform_wrapper form {
    max-width: rem(400px);
  }

  .services-box-container ul li {
    margin-bottom: rem(40px);
  }

  .equipment-box .equipment-box-container h1 {
    font-size: rem(60px);
  }

  .equipment-box.industry-box .equipment-box-container {
    margin: 0;
  }

  .blog-the-container .the-title-a h1 {
    font-size: 3rem;
    line-height: 3.4rem;
  }

  .get-in-touch-box-form .gform_wrapper form {
    max-width: 44rem;
  }

  /* service */
  .mobile-hcenter {
    margin: 0 auto;
    text-align: center;

    h3 {
      text-align: center !important;
    }

    h4 {
      text-align: center !important;
    }

  }
  .mobile-imager,
  .mobile-breaker {
    display: none !important;
  }


}

/* 992 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 900px) {
  html {
    font-size: 12px;
  }

  .error404 header#masthead,
  .home.page-template header#masthead {
    max-height: 42.25rem;
    min-height: 42.25rem;
  }

}


/* 767 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
  html {
    font-size: 16px;
  }

  body #footer .social-network-top {
    text-align: center;
  }

  //modes
  .container-small-our-modes-bg {
    background: url(../img/home/modes-home-bg-mobile.png) 50% 50% no-repeat;
    padding-bottom: 0;
  }

  //general
  .mobile-view {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mobile-view-block {
    display: block;
    width: 100%;
  }

  .desktop-view,
  .desktop-view-block {
    display: none;
  }

  //mobile
  .top-of-page ul.top_nav,
  .top-of-page .social-network-top {
    text-align: center;
  }

  .mobile-view-block {
    margin: 0;
    padding: 0;
  }

  //free-quote-form-box
  .free-quote-form-box {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    width: 100%;
    padding: rem(25px);
  }

  .get-in-touch-box .gform_body li#field_1_10 #input_1_10 li {
    margin: rem(0 24px) !important;
  }

  .free-quote-form-box .free-quote-form-box-bg-2,
  .free-quote-form-box .free-quote-form-box-bg-1 {
    margin: 0 auto;
    background: none;
  }

  .free-quote-form-box .gform_wrapper input,
  .free-quote-form-box h1 {
    max-width: 100%;
  }

  .free-quote-form-box .gform_wrapper form {
    width: 100%;
    max-width: 100%;
  }

  .free-quote-form-box .gform_wrapper .top_label input.medium,
  .free-quote-form-box .gform_wrapper .top_label select.medium {
    width: 100% !important;
    max-width: 100%;
  }

  .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type="submit"],
  .gform_wrapper .gform_page_footer input.button,
  .gform_wrapper .gform_page_footer input[type="submit"] {
    margin: 0;
  }

  .free-quote-form-box .gform_wrapper .gform_footer {
    width: 100%;
    max-width: 100%;
  }

  .opportunities-list-box ul li .t-zone-hover .t-data-hover {
    padding-top: 0;
  }

  //intro
  .background-sky-network {
    padding-top: rem(50px);
    margin-bottom: rem(90px);
    top: 0;
  }

  .why-us-box h1 {
    text-align: center;
    width: 100%;
  }

  .why-us-box .the-desc p {
    text-align: center;
  }

  .the-other-area li.the-other-area-slide {
    padding-top: 0;
  }

  /* divider-image */
  .divider-image {
    margin-top: rem(-180px);
  }

  .horizontal-box-right.horizontal-box-format-center {
    margin-top: rem(0);
  }

  //modes
  .our-modes-full {
    padding-bottom: 0;
  }

  .services-box-container ul li a.services-box-container-a {
    margin-top: rem(45px);
  }

  //horizontal
  .horizontal-box-right h1 {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .horizontal-box-right p {
    padding-left: 0;
    text-align: center;
  }

  //footer
  #footer h1 {
    text-align: center;
  }

  .footer-logo a {
    margin: 0 auto;
  }

  #footer .navbar li, #footer ul li,
  #footer .navbar li a, #footer ul li a {
    text-align: center;
  }

  .services-box-container ul li a.services-box-container-a {
    margin: 0 auto;
  }

  //footer
  .footer1 {
    display: none !important;
  }

  #footer.footer2 {
    background: #2B2B2B !important;
    min-height: 82rem;
    margin-top: 0;
  }

  //nav-tabs-shipper
  .nav-tabs-shipper {
    .nav-item {
      margin: 0;

      .nav-link {
        min-width: rem(1px);
      }
    }
  }

  .tab-pane {
    .mapeado {
      .point_style {
        img {
          width: rem(14px);
          height: rem(18px);
        }
      }
    }
  }

  //fullscreen-bg__video
  .fullscreen-bg__video {
    width: auto;
    height: auto;
  }

  //about
  .about-zone h1 {
    text-align: center;
  }

  .about-zone h2 {
    text-align: center;
    float: none;
    width: 100%;
    max-width: 100%;
  }

  //gform_wrapper
  .gform_wrapper .gform_footer input.button,
  .gform_wrapper .gform_footer input[type="submit"],
  .gform_wrapper .gform_page_footer input.button,
  .gform_wrapper .gform_page_footer input[type="submit"] {
    margin: rem(0 auto 16px) !important;
    min-height: 3rem !important;
  }

  .gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="image"]):not([type="file"]) {
    min-height: 3rem !important;
  }

  //contact
  .get-in-touch-box .gform_body li#field_1_5,
  .get-in-touch-box .gform_body li#field_1_6,
  .get-in-touch-box .gform_body li#field_1_7 {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .get-in-touch-box .gform_body #field_1_6 .ginput_container_email,
  .get-in-touch-box .gform_body #input_1_5 span {
    width: 100%;
    padding-right: 0;
    margin-right: 0;
  }

  //blog-almacen
  .blog-the-container {

    .blog-almacen-content {
      .the-title-a h1 {
        margin-top: 0;
      }
    }

    .the-title-a h1 {
      font-size: rem(34px);
      line-height: rem(38px);
    }

    .blog-almacen {
      margin-top: 0 !important;
    }

    .blog-image {
      -webkit-box-shadow: 0 0 0 transparent;
      box-shadow: 0 0 0 transparent;
    }

    .blog-image::after,
    .blog-post::after {
      visibility: hidden;
    }

    .blog-almacen .blog-almacen-content {
      text-align: center;
    }

    .entry-date {
      top: 0;
      margin-top: 0;
      right: inherit;
      left: 0;
    }
  }

  .our-team-relationship {
    .cos-area-2 {
      padding: rem(0 15px);
    }
  }

  //background-sky-network
  .background-sky-network.less-padding {
    padding-top: 0;
    padding-bottom: 3.25rem;
  }

  //testimonials-box-area
  .testimonials-box-area .testimonials-relationship ul.responsive {
    padding: 0;
    margin: 0;
  }


  //bg-animation
  .bg-animation,
  .bg-animation-right,
  .bg-animation-bot {
    display: none;
  }

  .why-us-box .the-desc p {
    text-align: center;
    padding-left: 0;
    max-width: 100%;
    float: none;
  }

  //opportunities-list-box
  .opportunities-list-box {

    .cos-area {
      margin: 0;
    }

    #nav-tabContent {
      margin: 0;
      min-height: rem(1px);
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      margin-top: 0;
      border: 0 none;
      min-height: auto;
      max-height: 100%;
    }

    .slick-list.draggable {
      margin: 0;
      padding: 0;
    }

    ul.responsive-x4-mobile li.slick-slide .t-data {
      display: none !important;
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone-hover::before {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      margin-top: 0;
      margin-left: 0;
    }

    ul.responsive-x4-mobile .slick-prev.slick-arrow {
      left: rem(10px);
    }

    ul.responsive-x4-mobile .slick-next.slick-arrow {
      right: rem(10px);
    }

    ul.responsive-x4-mobile li.slick-slide .t-zone-hover p,
    ul.responsive-x4-mobile li.slick-slide .t-zone-hover h1 {
      text-align: center;
    }

    .btn-default.btn-apply {
      float: none;
    }

    ul.responsive-x4-mobile li.slick-slide:hover .t-zone-hover {
      top: 0;
      left: 0;
    }

  }

  .sidenav .navbar-brand {
    width: 100%;

    a {
      max-width: rem(200px);
    }
  }

  //responsive-x4
  ul.responsive-x4-mobile li.responsive-x4-li {
    padding: 0;
    height: auto;
    min-width: auto;
    margin-right: 0;
    list-style: none;
  }

  //markerTo
  .markerTo {
    display: none;
  }

  //jobs-list-mobile
  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide .t-zone {
    min-height: rem(1px);
    height: 100%;
  }

  .opportunities-list-box ul.responsive-x4-mobile li:hover .t-zone-hover {
    min-height: rem(1px);
    height: 100vh;
    left: 0;
  }

  .opportunities-list-box ul.responsive-x4-mobile li.slick-slide img {
    display: block;
    width: 100%;
  }

  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover .t-data-hover {
    padding-top: 0;
  }

  .opportunities-list-box ul.responsive-x4-mobile li .t-zone-hover {
    max-height: 100%;
  }

  .opportunities-list-box ul {
    padding: 0;
  }

  .testimonials-box-area .testimonials-relationship ul.responsive-mobile {
    padding: 0;
    margin: 0;
  }

  /* area-manager-tabs */
  .area-manager-tabs,
  .testimonials-relationship-manager {
    height: rem(100%);
  }

  .about-zone .about-slides .about-slide .about-text {
    max-width: rem(400px);
    margin: 0 auto;
  }

  .get-in-touch-box-form .gform_wrapper form {
    max-width: rem(400px);
  }

  .services-box-container ul li {
    margin-bottom: rem(40px);
  }

  .equipment-box .equipment-box-container h1 {
    font-size: rem(60px);
  }

  .equipment-box.industry-box .equipment-box-container {
    margin: 0;
  }

  .about-zone h2 {
    padding-left: 0;
  }

  /* new design */
  .BLOQUE-SCW .our-mode-compax {
    padding: 0;
  }

  .header-mobile .navbar-toggler {
    margin-top: 0px;
  }

  .top-of-page ul.top_nav li {
    list-style: none;
    padding: 5px 0 0 0;
    width: 48%;
  }

}

/* 576 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 576px) {
  html {
    font-size: 14px;
  }

  .get-in-touch-box-form .gform_wrapper form {
    max-width: 28.5rem;
  }

  .BLOQUE-SCW .slides .slide a.scw_container h1 {
    font-size: rem(34px);
    line-height: rem(42px);
  }

  .BLOQUE-SCW .slides .slide a.scw_container {
    height: rem(254px);
  }

  .BLOQUE-SCW .our-mode-compax {
    padding: 0;
  }

}

/* 479 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 479px) {
  html {
    font-size: 12px;
  }

  .BLOQUE-SCW .slides .slide a.scw_container {
    width: rem(424px);
    height: rem(250px);
  }

}

/* 374 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 374px) {
  html {
    font-size: 11px;
  }

}

/* 359 */
/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 359px) {
  html {
    font-size: 9px;
  }

}