/* FOOTER */
/*---------------------------------------------------------------------*/
#footer {
  background: #2B2B2B !important;
  padding-top: rem(170px);
  padding-bottom: rem(130px);
  position: relative;
  z-index: 1;
  min-height: rem(750px);

  &.footer2 {
    position: relative;
    background: none !important;
    z-index: 98765;
    margin-top: rem(-750px);
    min-height: rem(750px);
  }

  h1 {
    color: #fff;
    font-size: rem(24px);
    font-family: Roboto, sans-serif;
    font-weight: 500;
    text-decoration: none;
    line-height: rem(29px);
    text-transform: uppercase;
    text-align: left;
    margin-top: rem(25px);
    padding-top: 0;
  }

  .dropdown-toggle::after,
  .caret,
  .dropdown-menu {
    display: none !important;
  }

  .navbar,
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
    vertical-align: top;

    li {
      list-style: none;
      margin: 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #a8a8a8;
      font-size: rem(18px);
      line-height: rem(22px);
      padding: rem(0 0 15px);
      text-decoration: none;

      a {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #a8a8a8;
        font-size: rem(18px);
        line-height: rem(22px);
        padding: rem(0 0 15px);
        text-decoration: none;

        &:hover {
          text-decoration: none;
          color: #42c8f4;
        }
      }
    }
  }

}

.scrollup {
  width: rem(38px);
  height: rem(38px);
  position: fixed;
  bottom: rem(10px);
  right: rem(10px);
  display: none;
  text-indent: -9999px;
  background: url('../img/arrow-orange-hi.png') no-repeat transparent;
  background-size: rem(38px 38px);
  opacity: 1;
  outline: 0;
  z-index: 98765432;
  border-radius: 0;
}
